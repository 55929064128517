import React, { useState } from "react";
import { Modal, message } from "antd";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Container, TextField, Button } from "@mui/material";
import axios from "../../axiosInstance";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const EditPermission = ({ refreshData, permissions, permissionName, permissionDefault, permissionDescription }) => {
  const [permission_name, setPermission_Name] = useState(permissionName);
  const [permission_default, setPermission_Default] =
    useState(permissionDefault);

  const [description, setDescription] = useState(permissionDescription);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    // window.location.reload();
  };

  // Edit Permission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to Edit this Permission ?")) {
      const editPermission = {
        permission_name,
        default: permission_default,
        id: permissions,
        description,
        // instituteId: user?.instituteId, // todo
        // vendorId:user?._id
      };

      try {
        const { data } = await axios.put(
          "/api/v1/admin/admin-update-permission",
          editPermission
        );
        if (data.success === true) {
          message.success(data.message);
          refreshData()
          setIsModalOpen(false);
        } else {
          message.error(data?.message);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <>
      <IconButton aria-label="edit" onClick={showModal}>
        <EditIcon sx={{ color: "#1976d2" }} />
      </IconButton>
      <Modal
        title="Edit Permission"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <Container
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
          maxWidth
        >
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="permission_name"
            label="Permission Name"
            name="permission_name"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Permission Name"
            value={permission_name}
            onChange={(e) => setPermission_Name(e.target.value)}
          />

          <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="description"
              label="Permission Description *"
              name="description"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Permission Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />


          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {" "}
                Default Permission{" "}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={permission_default}
                label="Default Permission"
                onChange={(e) => setPermission_Default(e.target.value)}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Button variant="contained" type="submit" sx={{ mt: 3 }}>
            Edit Permission
          </Button>
        </Container>
      </Modal>
    </>
  );
};

export default EditPermission;
