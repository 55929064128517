import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Box,
  Paper,
  TextField,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { message, Modal } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import PageTitle from "../../Components/PageTitle/PageTitle";
import axios from "../../axiosInstance";
import AddStudentBatch from "./AddStudentBatch";
import { Link, useLocation } from "react-router-dom";
import EditStudentBatch from "./EditStudentBatch";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StudentBatchList() {
  const location = useLocation();
  const { batchId, instituteId } = location.state || {};
  const [value, setValue] = useState(0);
  const [batchStudent, setBatchStudent] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredStudents, setFilteredStudents] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Get All Batch Student Data
  const getAllBatchStudent = async () => {
    try {
      const response = await axios.post(
        `/api/v1/batch/admin-get-institute-user-batch`,
        {
          batchId,
          instituteId,
        }
      );
      if (response?.data?.success === true) {
        setBatchStudent(response?.data?.data);
        setFilteredStudents(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getAllBatchStudent();
  }, []);

  // Delete Batch Student
  const { confirm } = Modal;
  const deleteBatchStudent = async (
    e,
    studentCourseId,
    instituteId,
    batchId
  ) => {
    confirm({
      title: "Are you sure you want to delete this assigned Student Course?",
      content:
        "This action will remove the student from the course in this batch.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/batch/admin-delete-institute-user-batch",
            {
              data: { studentCourseId, instituteId, batchId },
            }
          );
          if (data.success === true) {
            message.success(data?.message);
            getAllBatchStudent();
          } else {
            message.error(data?.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to delete student"
          );
        }
      },
      onCancel() {
        console.log("Delete operation canceled");
      },
    });
  };

  // Toggle user's active status
  const toggleUserActiveStatus = async (
    id,
    isActive,
    studentName,
    studentCourseId,
    instituteId,
    batchId
  ) => {
    const action = isActive ? "activate" : "deactivate";
    confirm({
      title: `Are you sure you want to ${action} this "${
        studentName || "Student"
      }" account?`,
      content: `Please confirm if you want to ${action} this student's account with the given details.`,
      okText: "Yes, Confirm",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.put(
            "/api/v1/batch/admin-update-status-user-batch",
            {
              studentId: id,
              isActive: isActive,
              studentCourseId,
              instituteId,
              batchId,
            }
          );
          if (data?.success === true) {
            message.success(data?.message);
            await getAllBatchStudent();
          } else {
            message.error(data?.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Something went wrong."
          );
        }
      },
      onCancel() {
        console.log("User account activation/deactivation canceled");
      },
    });
  };

  // Handle search input change
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = batchStudent.filter(
      (student) =>
        student?.studentName?.toLowerCase().includes(query) ||
        student?.studentEmail?.toLowerCase().includes(query)
    );
    setFilteredStudents(filtered);
  };

  const columns = [
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) =>
              deleteBatchStudent(
                e,
                value?.row?.studentCourse[0]?.studentCourseId,
                value?.row?.studentCourse[0]?.instituteId,
                value?.row?.studentCourse[0]?.batchId
              )
            }
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <IconButton>
            <EditStudentBatch
              studentId={value?.row?._id}
              refreshData={getAllBatchStudent}
              batchId={value?.row?.studentCourse[0]?.batchId}
              studentName={value?.row?.studentName}
              studentEmail={value?.row?.studentEmail}
              studentPhone={value?.row?.studentPhone}
              studentCourseName={
                value?.row?.studentCourse[0]?.studentCourseName
              }
              courseFees={value?.row?.studentCourse[0]?.courseFees}
              studentFees={value?.row?.studentCourse[0]?.studentFees}
              teachersNotes={value?.row?.studentCourse[0]?.teachersNotes}
              parentName={value?.row?.parentName}
              parentEmail={value?.row?.parentEmail}
              parentPhone={value?.row?.parentPhone}
              vendorId={value?.row?.studentCourse[0]?.vendorId}
              instituteId={value?.row?.studentCourse[0]?.instituteId}
              studentCourse_Id={value?.row?.studentCourse[0]?.studentCourseId}
            />
          </IconButton>
        </Box>
      ),
    },

    {
      field: "isActive",
      headerName: "Active Status",
      width: 170,
      renderCell: (params) => {
        const studentCourses = params?.row?.studentCourse || [];
        const studentCourseId =
          params?.row?.studentCourse[0]?.studentCourseId || "";
        const activeCourse = studentCourses.find(
          (course) => course.studentCourseId === studentCourseId
        );
        const isActive = activeCourse?.isActive === true;
        return (
          <select
            value={isActive.toString()}
            onChange={(e) =>
              toggleUserActiveStatus(
                params.row._id,
                e.target.value === "true",
                params?.row?.studentName,
                params?.row?.studentCourse[0]?.studentCourseId,
                params?.row?.studentCourse[0]?.instituteId,
                params?.row?.studentCourse[0]?.batchId
              )
            }
            style={{
              padding: "6px 12px",
              fontSize: "14px",
              borderRadius: "8px",
              border: `1px solid ${isActive ? "green" : "red"}`,
              backgroundColor: isActive ? "#e0ffe0" : "#ffe0e0",
              color: isActive ? "green" : "red",
              cursor: "pointer",
              outline: "none",
              transition:
                "border-color 0.2s, background-color 0.2s, color 0.2s",
            }}
            onFocus={(e) => (e.target.style.borderColor = "#007BFF")}
            onBlur={(e) =>
              (e.target.style.borderColor = isActive ? "green" : "red")
            }
          >
            <option value="" disabled>
              Select Status
            </option>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        );
      },
    },
    {
      field: "studentName",
      headerName: "Student Name",
      width: 150,
      editable: true,
    },
    {
      field: "studentEmail",
      headerName: "Student Email",
      width: 150,
      editable: true,
    },
    {
      field: "studentPhone",
      headerName: "Student Phone",
      width: 150,
      editable: true,
    },
    {
      field: "studentCourseName",
      headerName: "Course Name",
      width: 150,
      renderCell: (params) =>
        params.row?.studentCourse[0]?.studentCourseName || "-",
    },
    {
      field: "courseFees",
      headerName: "Course Fees",
      width: 150,
      renderCell: (params) => params.row?.studentCourse[0]?.courseFees || "-",
    },
    {
      field: "studentFees",
      headerName: "Student Fees",
      width: 150,
      renderCell: (params) => params.row?.studentCourse[0]?.studentFees || "-",
    },
    {
      field: "parentPhone",
      headerName: "Parent Phone",
      width: 150,
    },
  ];

  return (
    <>
      <PageTitle title="Admin Student Batch List | Test Series" />
      <Box>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            pb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Batch All Students List
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="/admin/batch"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Batch
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>Students</Typography>
        </Breadcrumbs>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Students List" {...TabPanel(0)} />
            <Tab label="Add Student" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/* Batch List */}
        <CustomTabPanel value={value} index={0}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Students Batch List
          </Typography>

          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by Student Name And Email"
            value={searchQuery}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon sx={{ color: "#1976d2" }} />
                </IconButton>
              ),
            }}
            sx={{
              mb: 3,
              bgcolor: "white",
              borderRadius: "20px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
              },
            }}
          />

          <Paper sx={{ bgcolor: "white" }}>
            {/* <Box sx={{ height: 400, width: "100%" }}> */}
            <Box sx={{ width: "100%", height: 500, overflow: "auto" }}>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  height: 500,
                  overflowX: "scroll",
                }}
              >
                <DataGrid
                  getRowId={(row) => row?._id}
                  sx={{
                    "& .MuiTablePagination-displayedRows": {
                      color: "black",
                    },
                    color: "black",
                    [`& .${gridClasses?.row}`]: {
                      bgcolor: "white",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: "rgb(25, 118, 210)",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      color: "white",
                      fontWeight: "bold",
                    },
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                      color: "#FFF",
                    },
                    "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                      color: "white",
                    },
                    "& .MuiDataGrid-sortIcon": {
                      color: "white",
                    },
                  }}
                  rows={filteredStudents}
                  columns={columns}
                  pageSize={3}
                  rowsPerPageOptions={[3]}
                  // checkboxSelection
                />
              </Box>
            </Box>
          </Paper>
        </CustomTabPanel>

        {/* Add Student Batch */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <AddStudentBatch />
        </CustomTabPanel>
      </Box>
    </>
  );
}
