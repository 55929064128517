import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Container, TextField, Button, Box } from "@mui/material";
import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import axios from "../../../axiosInstance";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Ensure CSS is imported

const EditAssignVendorPlan = ({
  refreshData,
  userId,
  planId,
  startDate,
  endDate,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState(planId?._id || "");

  // Initialize with moment objects
  const [planActiveStartDate, setPlanActiveStartDate] = useState(startDate);
  const [planActiveEndDate, setPlanActiveEndDate] = useState(endDate);

  // Format dates for display
  const formattedStartDate = moment(planActiveStartDate).format("MM/DD/YYYY");
  const formattedEndDate = moment(planActiveEndDate).format("MM/DD/YYYY");

  const handleStartDateChange = (date) => {
    setPlanActiveStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setPlanActiveEndDate(date);
  };

  useEffect(() => {
    const getAllPlan = async () => {
      try {
        const response = await axios.get(`/api/v1/plan/admin-get-plan`);
        if (response?.data?.success === true) {
          setPlans(response?.data?.plan);
          if (planId?._id) {
            setSelectedPlanId(planId._id);
          }
        } else {
          message.error(response?.data?.message);
        }
      } catch (error) {
        message.error(
          error?.response?.data?.message || "Failed to fetch plans"
        );
      }
    };

    getAllPlan();
  }, [planId]);

  const handleChange = (event) => {
    setSelectedPlanId(event.target.value);
  };

  // Edit Assign Plans
  const { confirm } = Modal;
  const handleSubmit = async (e) => {
    e.preventDefault();
    const AssignPlanData = {
      userId: userId?._id,
      planId: selectedPlanId,
      startDate: moment(planActiveStartDate).format("MM/DD/YYYY"),
      endDate: moment(planActiveEndDate).format("MM/DD/YYYY"),
    };
    confirm({
      title: "Are you sure you want to update this Plan Assignment?",
      content: "Please confirm if you want to assign this plan to the user.",
      okText: "Yes, Update Plan",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const response = await axios.put(
            "/api/v1/plan/admin-update-assign-plan-to-user",
            AssignPlanData
          );
          if (response?.data?.success === true) {
            message.success(response?.data?.message);
            setIsModalOpen(false);
            refreshData();
          } else {
            message.error(response?.data?.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to assign plan"
          );
        }
      },
      onCancel() {
        console.log("Plan assignment canceled");
      },
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={showModal}>
        <EditIcon sx={{ color: "#1976d2" }} />
      </IconButton>
      <Modal
        title="Edit Assign Plan"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <Container
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
          maxWidth
        >
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel shrink id="plan-label">
              Plan Name *
            </InputLabel>
            <Select
              label="plan-label"
              id="plan-select"
              value={selectedPlanId}
              onChange={handleChange}
              displayEmpty
              sx={{
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
            >
              <MenuItem value="" disabled>
                Select a Plan
              </MenuItem>
              {plans.map((plan) => (
                <MenuItem key={plan._id} value={plan._id}>
                  {plan.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="vendorName"
            label="Vendor Name"
            name="vendorName"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Vendor Name"
            value={userId?.name}
            disabled
          />
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="vendorEmail"
            label="Vendor Email"
            name="vendorEmail"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Vendor Email"
            value={userId?.email || ""}
            disabled
          />
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="vendorPhone"
            label="Vendor Phone"
            name="vendorPhone"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="12345XXXXX"
            value={userId?.phone || ""}
            disabled
          />

          <h4 style={{ marginTop: "-12px" }}>
            Vendor Plan Activate Start Date
          </h4>
          <Box sx={{ mb: 1 }} style={{ marginRight: "2%" }} className="Date">
            <DatePicker
              showIcon
              toggleCalendarOnIconClick
              isClearable
              placeholderText="Start Date"
              selected={planActiveStartDate}
              onChange={handleStartDateChange}
              minDate={new Date()}
              maxDate={planActiveEndDate}
            />
          </Box>
          <h4>Vendor Plan Deactivate End Date</h4>
          <Box className="Date" sx={{ mb: 3 }}>
            <DatePicker
              showIcon
              toggleCalendarOnIconClick
              isClearable
              placeholderText="End Date"
              selected={planActiveEndDate}
              onChange={handleEndDateChange}
              minDate={(new Date() && planActiveStartDate) || new Date()}
              maxDate={planActiveEndDate}
            />
          </Box>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="courses_desc"
            sx={{
              mt: 3,
              borderRadius: "5px",
              textTransform: "none",
              fontFamily: "Poppins, sans-serif",
              letterSpacing: ".1rem",
            }}
          >
            Edit Assign Plan
          </Button>
        </Container>
      </Modal>
    </div>
  );
};

export default EditAssignVendorPlan;
