import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Modal, message } from "antd";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import axios from "../../axiosInstance";
import { Container, TextField, Box, Button } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditCourse = ({
  refreshData,
  course,
  courseName,
  courseFees,
  courseDescription,
  courseStartDate,
  courseEndDate,
}) => {
  const [course_Name, setCourse_Name] = useState(courseName);
  const [course_Fees, setCourse_Fees] = useState(courseFees);
  const [course_Description, setCourse_Description] =
    useState(courseDescription);
  const [course_startDate, setCourse_startDate] = useState(courseStartDate);
  const [course_endDate, setCourse_EndDate] = useState(courseEndDate);

  const courseStartingDate = moment(course_startDate).format("MM/DD/YYYY");
  const courseEndingDate = moment(course_endDate).format("MM/DD/YYYY");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleStartDateChange = (date) => {
    setCourse_startDate(date);
  };
  const handleEndDateChange = (date) => {
    setCourse_EndDate(date);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Edit Institute Course
  const { confirm } = Modal;
  const handleSubmit = async (e) => {
    e.preventDefault();
    confirm({
      title: "Are you sure you want to edit this course?",
      content: "Please confirm the changes before proceeding.",
      okText: "Yes, Edit",
      cancelText: "No, Cancel",
      onOk: async () => {
        const updatedCourse = {
          courseId: course,
          courseName: course_Name,
          courseFees: course_Fees,
          courseDescription: course_Description,
          startDate: courseStartingDate,
          endDate: courseEndingDate,
        };
        try {
          // API call to update the course
          const { data } = await axios.put(
            "/api/v1/vendor/admin-update-course-institute",
            updatedCourse
          );
          if (data.success === true) {
            message.success(data.message);
            refreshData();
            setIsModalOpen(false);
          } else {
            message.error(data?.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Error updating course."
          );
        }
      },
      onCancel() {
        console.log("Edit action canceled");
      },
    });
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={showModal}>
        <EditIcon sx={{ color: "#1976d2" }} />
      </IconButton>
      <Modal
        title="Edit Course"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        width={1000}
      >
        <Container
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
          maxWidth
        >
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="name"
            label="Course Name *"
            name="name"
            placeholder="Course Name"
            value={course_Name}
            onChange={(e) => setCourse_Name(e.target.value)}
          />
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              "& input[type='number']": {
                MozAppearance: "textfield",
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  display: "none",
                },
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="fees"
            label="Course Fees *"
            name="fees"
            type="Number"
            placeholder="Course Fees"
            value={course_Fees}
            onChange={(e) => setCourse_Fees(e.target.value)}
          />
          {/* <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="description"
            label="Course Description *"
            name="description"
            placeholder="Course Description"
            value={course_Description}
            multiline
            onChange={(e) => setCourse_Description(e.target.value)}
          /> */}

          <ReactQuill
            value={course_Description}
            onChange={setCourse_Description}
            placeholder="Course Description"
            style={{
              // height: "200px",
              marginBottom: "30px",
              border: "1px solid rgba(0,0,0,0.1)",
              borderRadius: "10px",
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "13px",
            }}
            className="Date"
          >
            <Box
              sx={{
                mb: 3,
              }}
            >
              <DatePicker
                showIcon
                toggleCalendarOnIconClick
                isClearable
                placeholderText="Starting Date"
                selected={course_startDate}
                value={course_startDate}
                onChange={handleStartDateChange}
                minDate={new Date()}
                maxDate={course_endDate}
                required
              />
            </Box>
            <Box
              sx={{
                mb: 3,
              }}
            >
              <DatePicker
                required
                showIcon
                toggleCalendarOnIconClick
                isClearable
                placeholderText="Ending Date"
                selected={course_endDate}
                onChange={handleEndDateChange}
                value={course_endDate}
                // eslint-disable-next-line no-mixed-operators
                minDate={(new Date() && course_startDate) || new Date()}
                maxDate={course_endDate}
              />
            </Box>
          </Box>

          <Button
            variant="contained"
            type="submit"
            color="primary"
            className="courses_desc"
            sx={{
              mt: 3,
              borderRadius: "5px",
              textTransform: "none",
              fontFamily: "Poppins, sans-serif",
              letterSpacing: ".1rem",
            }}
          >
            Edit Course
          </Button>
        </Container>
      </Modal>
    </div>
  );
};

export default EditCourse;
