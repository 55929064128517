import React from "react";
import {
  Box,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Breadcrumbs, Link as MUILink,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const ShareReportPreview = () => {
  const { state } = useLocation();
  const reportData = state?.reportData;
  const instituteInfo = state?.instituteInfo;
  const instituteUserInfo = state?.instituteUserInfo
  
  const totalMarks = reportData?.result?.totalMarks;
  const marksObtained = reportData?.result?.obtainedMarks;
  const percentage = (marksObtained / totalMarks) * 100;

  // Function to determine the total grade based on the percentage
  const getTotalGrade = (percentage) => {
    if (percentage >= 90) return "A+";
    if (percentage >= 80) return "A";
    if (percentage >= 70) return "B+";
    if (percentage >= 60) return "B";
    return "C";
  };

  // Function to determine the rank based on the grade (customize as needed)
  const rank = percentage >= 85 ? "1st" : percentage >= 75 ? "2nd" : "3rd";

  return (
    <>
    <Typography variant="h5" sx={{ mb: 3}} align="center" gutterBottom>
    Share Report Preview
  </Typography>
  <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        mb: 5,
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "12px",
        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
      }}
    >
      <MUILink
        component={Link}
        to="/"
        sx={{ color: "inherit", textDecoration: "none" }}
      >
        Dashboard
      </MUILink>
      <MUILink
        component={Link}
        to="/admin/share/report"
        sx={{ color: "inherit", textDecoration: "none" }}
      >
        Share Report
      </MUILink>
      <Typography sx={{ color: "primary.main" }}>Share Report Preview</Typography>
    </Breadcrumbs>
    <Container
      maxWidth="sm"
      sx={{
        bgcolor: "#f9f9f9",
        p: 3,
        borderRadius: 2,
        border: "2px solid #ccc",
      }}
    >
      {/* Header */}
      <Box textAlign="center" mb={3} mt={2}>
        <img
          src={instituteInfo[0]?.instituteLogo}
          alt="Institute Logo"
          style={{ maxWidth: 150, marginRight: 20 ,backgroundColor: "#FFFFFF" }}
        />
        <Box flexGrow={1} textAlign="center" mt={3}>
          <Typography
            variant="h5"
            fontWeight="bold"
            style={{
              letterSpacing: ".1rem",
              lineHeight: "1.8rem",
              fontFamily: "Poppins, sans-serif",
              marginBottom: "12px",
            }}
            gutterBottom
          >
           {instituteInfo[0]?.instituteName}
          </Typography>
          <Typography
            variant="body1"
            style={{
              letterSpacing: ".1rem",
              fontFamily: "Poppins, sans-serif",
              lineHeight: "1.5rem",
            }}
          >
            Address:  {instituteInfo[0]?.instituteAddress}
          </Typography>
          <Typography
            variant="body1"
            style={{
              letterSpacing: ".1rem",
              fontFamily: "Poppins, sans-serif",
              lineHeight: "1.5rem",
            }}
          >
            Phone:  {instituteInfo[0]?.institutePhone}, Email:  {instituteInfo[0]?.instituteEmail}
          </Typography>
        </Box>
      </Box>

      {/* Report Card Details */}
      <Box textAlign="center" mb={3}>
        <Typography variant="h6" style={{ letterSpacing: ".1rem" }}>
          Report Card
        </Typography>
        <Typography style={{ letterSpacing: ".1rem" }}>
          {reportData.courseClass}: {reportData?.courseName}
        </Typography>
        <Typography style={{ letterSpacing: ".1rem" }}>
          Academic Session: {reportData?.academicSession}
        </Typography>
      </Box>

      {/* Student Info Section */}
      <Box
        display="flex"
        justifyContent="space-between"
        mb={3}
        p={2}
        borderRadius={1}
      >
        <Box>
          <Typography variant="body1">
            <strong>Student Name:</strong> {reportData?.userName}
          </Typography>
          <Typography variant="body1">
            <strong>Parent Name:</strong> {instituteUserInfo?.parentName}
          </Typography>
        </Box>
      </Box>

      {/* Scores Section */}
      <Box>
        <Typography variant="h6" mb={2} style={{ letterSpacing: ".1rem" }}>
          Scores
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: 3, borderRadius: 3 }}
        >
          <Table>
            <TableHead>
              <TableRow bgcolor={"#d5d5d5"}>
                <TableCell style={{ fontWeight: "bold" }}>Subject</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Total Marks
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Marks Obtained
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Grade</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                  <TableCell>{reportData.examName}</TableCell>
                  <TableCell>{totalMarks}</TableCell>
                  <TableCell>{marksObtained.toFixed(2)}</TableCell>
                  <TableCell>{getTotalGrade(percentage)}</TableCell>
                </TableRow>
              {/* Overall Marks Row */}
              <TableRow bgcolor={"#f2f2f2"}>
                <TableCell>Total</TableCell>
                <TableCell>{totalMarks}</TableCell>
                <TableCell>{marksObtained.toFixed(2)}</TableCell>
                <TableCell>{getTotalGrade(percentage)}</TableCell>
              </TableRow>
              <TableRow bgcolor={"#d5d5d5"}>
                <TableCell>Overall Marks</TableCell>
                <TableCell>
                  {marksObtained.toFixed(2)} / {totalMarks}
                </TableCell>
                <TableCell>Total Grade</TableCell>
                <TableCell>{getTotalGrade(percentage)}</TableCell>
              </TableRow>
              {/* Total Grade and Rank Row */}
              <TableRow bgcolor={"#f2f2f2"}>
                <TableCell>Percentage</TableCell>
                <TableCell>{percentage.toFixed(2)}%</TableCell>
                <TableCell>Rank</TableCell>
                <TableCell>{rank}</TableCell>
              </TableRow>

              {/* bgcolor= {"#a2a2a2"} */}
              <TableRow bgcolor={"#e2e2e2"}>
                <TableCell>Result</TableCell>
                <TableCell>{reportData?.result?.verdict}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Comments Section */}
      <Box mt={5}>
        <Typography mb={3}>
          {reportData?.addComments}
        </Typography>
      </Box>

      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1" mt={2}>
          <strong
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 1,
            }}
          >
            Date
          </strong>{" "}
          {reportData?.date}
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          <strong
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 1,
            }}
          >
            Class Teacher
          </strong>{" "}
        {reportData?.classSignature}
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          <strong
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 1,
            }}
          >
            Principal
          </strong>
          {reportData?.principalSignature}
        </Typography>
      </Box>
    </Container>
    </>
  );
};

export default ShareReportPreview;
