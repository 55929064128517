import React, { useState, useEffect } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { Typography, Box, Grid } from "@mui/material/";
import { message } from "antd";
import axios from "../axiosInstance";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { BarChart } from "@mui/x-charts/BarChart";
import Paper from "@mui/material/Paper";
import PageTitle from "../Components/PageTitle/PageTitle";

const Dashboard = () => {
  const [exams, setExams] = useState([]);
  const [question, setQuestion] = useState([]);
  const [reports, setReports] = useState([]);
  const [users, setUsers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [contact, setContact] = useState([]);
  const [subjectiveExams, setSubjectiveExams] = useState([]);
  const [subjectiveQuestions, setSubjectiveQuestions] = useState([]);
  const [subjectiveReports, setSubjectiveReports] = useState([]);

  const displayExams = async () => {
    try {
      const data = await axios.post("/api/v1/exams/admin-get-all-exams");
      setExams(data?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const displaySubjectiveExams = async () => {
    try {
      const data = await axios.post(
        "/api/v1/subjective/admin-get-all-exams-subjective"
      );
      setSubjectiveExams(data?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const displayGetAllSubjectiveQuestion = async () => {
    try {
      const response = await axios.get(
        `/api/v1/question/subjective/admin-get-all-subjectiveQuestions`
      );
      setSubjectiveQuestions(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const displayQuestions = async () => {
    try {
      const data = await axios.post(
        "/api/v1/question/admin-get-dashboard-questions"
      );
      setQuestion(data?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const displayReports = async () => {
    try {
      const response = await axios.post(
        `/api/v1/reports/admin-get-all-reports`
      );
      setReports(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const displaySubjectiveReports = async () => {
    try {
      const response = await axios.post(
        `/api/v1/reports/subjective/admin-get-all-Reports-subjectiveReport`
      );
      setSubjectiveReports(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const displayUsers = async () => {
    try {
      const response = await axios.get(
        `/api/v1/batch/admin-get-institute-all-user-batch`
      );
      if (response?.data?.success === true) {
        setUsers(response?.data?.user);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const displayVendor = async () => {
    try {
      const { data } = await axios.get("/api/v1/common/admin-get-vendor");
      setVendors(data?.vendors);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const displayContact = async () => {
    try {
      const { data } = await axios.get("/api/v1/contact/admin-get-contact");
      setContact(data?.contactInfo);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    displayExams();
    displayQuestions();
    displayReports();
    displayUsers();
    displayVendor();
    displayContact();
    displaySubjectiveExams();
    displayGetAllSubjectiveQuestion();
    displaySubjectiveReports();
  }, []);

  return (
    <>
      <PageTitle title="Admin Dashboard | Test Series" />
      <Box>
        <Box sx={{ mt:1, ml: 2, mr: 2 }}>
          <Grid container spacing={4}>
            {[
              //  {
              //   title: "Accounts",
              //   value: vendors.length,
              //   color: "rgb(102, 12, 104)",
              // },
              // {
              //   title: "Batch",
              //   value: vendors.length,
              //   color: "rgb(203, 102, 14)",
              // },
              // {
              //   title: "Student",
              //   value: contact.length,
              //   color: "rgb(198, 107, 205)",
              //   fontWeight: "bold",
              // },
              {
                title: "Exams",
                value: exams.length + subjectiveExams.length,
                // color: "rgb(0, 123, 255)",
                // color: "#2d42a8",
                color: "rgb(2, 178, 175)"
              },
              {
                title: "Questions",
                value: question.length + subjectiveQuestions.length,
                // color: "rgb(184, 193, 7)",
                color: "rgb(46, 150, 255)",
              },
              {
                title: "Reports",
                value: reports.length + subjectiveReports.length,
                // color: "rgb(40, 167, 69)",
                color: "rgb(184, 0, 216)",
              },
              {
                title: "Students",
                value: users.length,
                color: "rgb(96, 0, 155)",
                // color: "rgb(184, 0, 69)",
              },
              {
                title: "Accounts",
                value: vendors.length,
                // color: "rgb(23, 162, 184)",
                color: "rgb(3, 0, 141)",
              },
              {
                title: "Contacts",
                value: contact.length,
                fontWeight: "bold",
                // color: "rgb(108, 117, 125)",
                color: "rgb(3, 0, 141)",
              },
             
            ].map((card, idx) => (
              <Grid item xs={12} sm={6} md={4} key={idx}>
                <Box
                  variant="h5"
                  // sx={{
                  //   backgroundColor: card.color,
                  //   borderRadius: "15px !important",
                  //   padding: "20px",
                  // }}
                  //  color:"#000"
                >
                  <Card
                    sx={{
                      transition: "0.3s !important",
                      "&:hover": { transform: "scale(1.05)" },
                      borderRadius: "15px",
                      boxShadow:
                        "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                      padding: "15px",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      // backgroundColor: "#FFF",
                      backgroundColor: card.color,
                      
                    }}
                  >
                    {" "}
                    <CardContent>
                      <Typography variant="h5" component="div" color="#fff">
                        {" "}
                        {card.title}
                      </Typography>
                      <Typography
                        variant="h4"
                        component="div"
                        color="#fff"
                        sx={{ fontWeight: "bold", pt: 1 }}
                      >
                        {card.value}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Typography
            variant="h5"
            className="courses_title"
            gutterBottom
            sx={{
              mt: 5,
              mb: 5,
              letterSpacing: "2px",
              lineHeight: 1.8,
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
              color: "#2C3E50",
            }}
          >
            Data Visualization
          </Typography>

          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Paper elevation={5} sx={{ p: 2 }}>
                <PieChart
                  series={[
                    {
                      data: [
                        { id: 0, value: exams.length, label: "Exams" },
                        { id: 1, value: question.length, label: "Questions" },
                        { id: 2, value: reports.length, label: "Reports" },
                        { id: 3, value: users.length, label: "Students" },
                        { id: 4, value: vendors.length, label: "Accounts" },
                        { id: 5, value: contact.length, label: "Contacts" },
                      ],
                    },
                  ]}
                  height={250}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={5} sx={{ p: 2 }}>
                <BarChart
                  series={[
                    { data: [exams.length] },
                    { data: [question.length] },
                    { data: [reports.length] },
                    { data: [users.length] },
                    { data: [vendors.length] },
                    { data: [contact.length] },
                  ]}
                  height={250}
                  xAxis={[{ scaleType: "band", data: ["Data"] }]}
                  margin={{ top: 10, bottom: 40, left: 50, right: 10 }}
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
