import React from "react";
import { Typography, Paper, Box, TextField } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import axios from "../../../axiosInstance";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { message, Modal } from "antd";
import { useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import SubViewCategoryPermissions from "./SubViewCategoryPermissions";
import SubEditAssignCategoryPermissions from "./SubEditAssignCategoryPermissions";
import SearchIcon from "@mui/icons-material/Search";

const SubAssignCategoryPermissionsList = () => {
  const [assignCategory, setAssignCategory] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Get Assign Category Permission
  const getCategoryPermissions = async () => {
    try {
      const response = await axios.get(
        "/api/v1/common/get-assign-category-permission"
      );
      if (response?.data?.success === true) {
        setAssignCategory(response?.data?.assignCategory);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getCategoryPermissions();
    return () => clearInterval(intervalId);
  }, []);

  // Delete Assign Category Permission
  const { confirm } = Modal;
  const deleteCategoryPermissions = async (e, id) => {
    confirm({
      title: "Are you sure you want to delete this Assign Category Permission?",
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/common/admin-delete-category-permission",
            {
              data: { id },
            }
          );
          if (data.success === true) {
            message.success(data.message);
            getCategoryPermissions();
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to delete permission."
          );
        }
      },
      onCancel() {
        console.log("Deletion canceled.");
      },
    });
  };

  const columns = [
    {
      field: "Actions",
      width: 190,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteCategoryPermissions(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <SubEditAssignCategoryPermissions
            permissionName={value?.row?.permissions}
            institute={value?.row?.instituteId}
            userId={value?.row?.user_id?._id}
            permissionID={value?.row?._id}
          />
          <SubViewCategoryPermissions
            permissionName={value?.row?.permissions}
            institute={value?.row?.instituteId}
            userId={value?.row?.user_id?._id}
            permissionID={value?.row?._id}
          />
        </Box>
      ),
    },
    // {
    //   field: "instituteId",
    //   headerName: "Institute Id",
    //   width: 220,
    //   editable: true,
    // },
    {
      field: "user_id",
      headerName: "Full Name",
      width: 250,
      // editable: true,
      renderCell: (row) => row?.row?.user_id?.name,
    },

    {
      field: "email",
      headerName: "E-mail",
      width: 280,
      // editable: true,
      renderCell: (row) => row?.row?.user_id?.email,
    },
    {
      field: "role_name",
      headerName: "Role",
      width: 260,
      // editable: true,
      renderCell: (row) => row?.row?.user_id?.role_name,
    },
    {
      field: "permissions",
      headerName: "Permissions",
      width: 240,
      renderCell: (row) => {
        // Render permissions as comma-separated string
        return row.row.permissions
          .map((permission) => permission.permission_name)
          .join(", ");
      },
    },
  ];

  // Filter users based on search term
  const filteredUsers = assignCategory.filter((user) => {
    // Get the user's name and email from the user_id object
    const userName = user?.user_id?.name?.toLowerCase() || "";
    const userEmail = user?.user_id?.email?.toLowerCase() || "";

    // Get all permission names as a single string (if multiple permissions exist)
    const permissionNames = user.permissions
      .map((permission) => permission.permission_name.toLowerCase())
      .join(", ");

    // Perform the search based on the searchTerm
    return (
      userName.includes(searchTerm.toLowerCase()) ||
      userEmail.includes(searchTerm.toLowerCase()) ||
      permissionNames.includes(searchTerm.toLowerCase())
    );
  });

  return (
    <div>
      <Typography
        variant="h6"
        sx={{
          pb: 2,
          pt: 3,
          fontFamily: "Poppins, sans-serif",
          color: "#555",
          letterSpacing: "2.5px",
          lineHeight: 1.8,
        }}
      >
        Assign Permissions List
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search by Full Name, Email, or Permission Name"
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <IconButton>
              <SearchIcon sx={{ color: "#1976d2" }} />
            </IconButton>
          ),
        }}
        sx={{
          mb: 3,
          bgcolor: "white",
          borderRadius: "20px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
            "&:hover fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
          },
        }}
      />
      <Paper sx={{ bgcolor: "white" }}>
        <Box sx={{ height: 400, width: "auto" }}>
          <DataGrid
            getRowId={(row) => row?._id}
            sx={{
              "& .MuiTablePagination-displayedRows": {
                color: "black",
              },
              color: "black",
              [`& .${gridClasses?.row}`]: {
                bgcolor: "white",
              },
              // Style for header cells
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgb(25, 118, 210)",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
                fontWeight: "bold",
                // fontSize: "13px",
              },
              // Style for checkbox in header
              "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                color: "#FFF",
              },
              "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                color: "white",
              },
            }}
            rows={filteredUsers}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[3]}
            // checkboxSelection
          />
        </Box>
      </Paper>
    </div>
  );
};

export default SubAssignCategoryPermissionsList;
