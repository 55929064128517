import React, { useState, useEffect } from "react";
import {
  Typography,
  Avatar,
  IconButton,
  Badge,
  Menu,
  Box,
  MenuItem,
  List,
  ListItemText,
  Divider,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/actions/userAction";
import axios from "../axiosInstance";

const StudentDashboardNavbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationEl, setNotificationEl] = useState(null);
  const [studentEnrollCourses, setStudentEnrollCourses] = useState([]);
  const [requestCanceled, setRequestCanceled] = useState([]);
  const [seenRequests, setSeenRequests] = useState(new Set());

  // Fetch Enrolled Courses
  const fetchEnrolledCourses = async () => {
    try {
      const response = await axios.post(
        `/api/v1/public/user/get-institute-student-request-courses`,
        {
          userId: user._id,
          PublicUserId:user?.PublicUserId,
        }
      );
      const { courses, requestCanceled } = response.data;
      setStudentEnrollCourses(courses);
      setRequestCanceled(requestCanceled || []);
    } catch (error) {
      message.error(error.response?.data?.message);
    }
  };
  useEffect(() => {
    fetchEnrolledCourses();
  }, []);

  //  Call the Notifications Mark Seen Api
  const handleNotificationItemClick = async (requestId) => {
    setSeenRequests((prev) => new Set(prev).add(requestId));
    try {
      await axios.post(
        "/api/v1/public/user/get-institute-student-request-courses-notifications-mark-seen",
        {
          userId: user._id,
          requestId: requestId,
          PublicUserId:user?.PublicUserId,
        }
      );
    } catch (error) {
      message.error("Failed to mark notification as seen");
    }
    setNotificationEl(null);
  };
  const unseenRequestCount = requestCanceled.filter(
    (request) => !seenRequests.has(request._id) && !request.seen
  ).length;

  const handleNotificationClick = (event) => {
    setNotificationEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationEl(null);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleLogout = () => {
  const { confirm } = Modal;
  const handleLogout = () => {
    confirm({
      title: "Are you sure you want to Log out?",
      icon: <ExclamationCircleOutlined />,
      content: "Your current session will be terminated.",
      onOk() {
        dispatch(logout());
        localStorage.clear();  // Clearing all necessary data
        message.success("Logout Successfully");
        window.location.reload(); // Reload the page
        navigate("/");
      },
      // onCancel() {
      //   message.info("Logout cancelled");
      // }
    });
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          backgroundColor: "#f0f4f8",
          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* User Profile Section */}
        <Box display="flex" alignItems="center">
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
            color="success"
          >
            <Avatar
              sx={{ width: 50, height: 50, cursor: "pointer" }}
              alt={user?.studentName}
              src={user?.profileImage}
              onClick={handleAvatarClick}
            />
          </Badge>
          <Box ml={2}>
            <Link
              style={{
                textDecoration: "none",
                color: "inherit",
                marginTop: "12px",
              }}
              to="/student/dashboard"
            >
              <Typography variant="subtitle2">Welcome</Typography>
              <Typography variant="h6" fontWeight="bold">
                {user?.studentName}
              </Typography>
            </Link>
          </Box>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={handleClose}>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
                to="/student/dashboard"
              >
                Dashboard
              </Link>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
                to="#"
              >
                Logout
              </Link>
            </MenuItem>
          </Menu>
        </Box>

        {/* Notifications and Logout */}
        <Box display="flex" alignItems="center">
          {/* Notification Icon */}
          <IconButton onClick={handleNotificationClick}>
            <Badge badgeContent={unseenRequestCount} color="secondary">
              <NotificationsIcon
                sx={{
                  backgroundColor: "#1976d2",
                  color: "white",
                  fontSize: "35px",
                  padding: "8px",
                  borderRadius: "10px",
                }}
              />
            </Badge>
          </IconButton>

          <Box display="flex" alignItems="center">
            <IconButton>
              <LogoutIcon
                sx={{
                  backgroundColor: "#1976d2",
                  color: "white",
                  fontSize: "35px",
                  padding: "8px",
                  borderRadius: "10px",
                }}
                onClick={handleLogout}
              />
            </IconButton>
          </Box>

          {/* Notification Dropdown */}
          <Menu
            anchorEl={notificationEl}
            open={Boolean(notificationEl)}
            onClose={handleNotificationClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {requestCanceled.length > 0 ? (
              <List sx={{ width: "250px" }}>
                <Typography
                  color="text.secondary"
                  sx={{
                    ml: 2,
                    mr: 2,
                    pb: 2,
                    fontSize: "14px",
                    letterSpacing: ".1rem",
                    fontFamily: "Poppins, sans-serif",
                    wordWrap: "break-word",
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Canceled Courses
                </Typography>
                <Divider />
                {requestCanceled.map((request) => (
                  <div key={request._id}>
                    <MenuItem
                      onClick={() => handleNotificationItemClick(request._id)}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="#" // Or redirect to a specific route
                      >
                        <ListItemText
                          primary={request.courseName}
                          secondary={`Start on: ${request.startDate}`}
                          sx={{
                            fontWeight:
                              seenRequests.has(request._id) || request.seen
                                ? "normal"
                                : "bold",
                            color:
                              seenRequests.has(request._id) || request.seen
                                ? "inherit"
                                : "#55679C",
                          }}
                        />
                      </Link>
                    </MenuItem>
                    <Divider />
                  </div>
                ))}
              </List>
            ) : (
              <MenuItem onClick={handleNotificationClose}>
                <ListItemText primary="No new notifications" />
              </MenuItem>
            )}
          </Menu>
        </Box>
      </Box>
    </>
  );
};

export default StudentDashboardNavbar;
