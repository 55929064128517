import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  TextField,
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Button,
  Paper
} from "@mui/material";
import { useSelector } from "react-redux";
import { message } from "antd";
import axios from "../../../axiosInstance";
import PageTitle from "../../../Components/PageTitle/PageTitle";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import  IconButton  from '@mui/material/IconButton';
import  DeleteIcon  from '@mui/icons-material/Delete';
import SubViewRoutesPermissionView from './SubViewRoutesPermissionView';
import SubAssignRoutesPermission from './SubAssignRoutesPermission';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SubRoutesPermissions = () => {
  const [value, setValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  // const [users, setUsers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [addPermissionId, setAddPermissionId] = useState("");

  const [editorRole, setEditorRole] = useState([]);
  const [editorId, setEditorId] = useState("");
  const [role, setRole] = useState([]);
  const [addRolePermission, setAddRolePermission] = useState("");
  const [addRouterPermission, setAddRouterPermission] = useState([]);
  const [getRouterPermission, setGetRouterPermission] = useState([]);
  const [addPermission, setAddPermission] = useState([]);

  const [selectRoutes, setSelectRoutes] = useState("")

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Get Router Permission Req User Data
  const getRouterPermissionReqUser = async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/admin-get-router-permission-req-user`
      );
      if (response?.data?.success === true) {
        setGetRouterPermission(response?.data?.routerPermissions);
        setSelectRoutes(response?.data?.routerPermissions);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getRouterPermissionReqUser();
  }, []);

  // Get Role Data
  const getAllRole = async () => {
    try {
      const response = await axios.get(`/api/v1/admin/admin-get-role`);
      if (response?.data?.success === true) {
        setRole(response?.data?.role);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllRole();
    return () => clearInterval(intervalId);
  }, []);

  // Routes Handle Function
  const AddRouterPermissionHandler = (e) => {
    const selectedValue = e.target.value; // Get the selected value
    setAddRouterPermission(selectedValue);
    setAddPermissionId(selectedValue?.permission_id?._id);
    setSelectRoutes(selectedValue)
  };

  // get-all-vendor
  const displayVendor = async () => {
    try {
      const { data } = await axios.get("/api/v1/common/admin-get-vendor");
      setVendors(data?.vendors);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    displayVendor();
    return () => clearInterval(intervalId);
  }, []);

  const AddVendorInfo = (e) => {
    const selectedValue = e.target.value; // Get the selected value
    setAddRolePermission(selectedValue?.role);
    setEditorRole(selectedValue?.role);
    setEditorId(selectedValue?._id);
  };

  // Editors and others Roles Assign a new Routes Permissions
  const assignRoutesPermissions = async (e) => {
    e.preventDefault();
    const addRouterData = {
      router_endpoint: addRouterPermission?.router_endpoint,
      role: editorRole,
      permission: addRouterPermission?.permission,
      permission_id: addPermissionId,
      instituteId: user?.instituteId,
      user_id: editorId,
      assignRoutesPermissions: user?._id,
    };

    try {
      const { data } = await axios.post(
        "/api/v1/admin/admin-add-router-permission",
        addRouterData
      );
      if (data.success === true) {
        message.success(data.message);
        // Clear form fields
        setAddRouterPermission("");
        setAddRolePermission("");
        setAddPermission("");
        setAddPermissionId("");
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

    const columns = [
      {
        field: "instituteId",
        headerName: "Institute Id",
        width: 220,
        editable: true,
      },
  
      {
        field: "router_endpoint",
        headerName: "router_endpoint",
        width: 220,
        editable: true,
      },
  
      {
        field: "permission_id",
        headerName: "Permission",
        width: 220,
        editable: true,
        renderCell: (row) => row?.row?.permission_id?.permission_name,
      },
  
      {
        field: "Permission Info",
        width: 210,
        renderCell: (row) => (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <SubViewRoutesPermissionView
              permission_Value={row?.row?.permission}
            />
            
          </Box>
        ),
      },
    ];
  
  return (
    <div>
      <PageTitle title="Admin Router Permissions | Test Series" />
      <Box>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          Routes Permissions
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Routes Permission" {...TabPanel(0)} />
            <Tab label="Add Routes Permission" {...TabPanel(1)} />
          </Tabs>
        </Box>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
          All Routes Permissions
        </Typography>
        
        <Box
              style={{
                width: "auto",
                overflowY: "hidden",
                overflowX: "scroll",
              }}
             >
              <Paper sx={{ bgcolor: "white" }}>
                <Box sx={{ height: 400, width: "100%" }}>
                  <DataGrid
                    getRowId={(row) => row?._id}
                    sx={{
                      "& .MuiTablePagination-displayedRows": {
                        color: "black",
                      },
                      color: "black",
                      [`& .${gridClasses?.row}`]: {
                        bgcolor: "white",
                      },
                       // Style for header cells
                       "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "rgb(25, 118, 210)",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        color: "white",
                        fontWeight: "bold",
                        // fontSize: "13px",
                      },
                      // Style for checkbox in header
                      "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                        color: "#FFF",
                      },
                      "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                        color: "white",
                      },
                      "& .MuiDataGrid-sortIcon": {
                        color: "white",
                      },
                    }}
                    rows={getRouterPermission}
                    columns={columns}
                    pageSize={3}
                    rowsPerPageOptions={[3]}
                    // checkboxSelection
                  />
                </Box>
              </Paper>
            </Box>


            <SubAssignRoutesPermission />


      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
          Assign a new Routes Permissions
        </Typography>
        <Container
          onSubmit={assignRoutesPermissions}
          component="form"
          className="form_style border-style"
          maxWidth
        >
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="instituteId"
            label="Editor Institute Id"
            name="instituteId"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Editor Institute Id for example GatikAi-01"
            value={user?.instituteId}
          />

          {/* All User  */}
          <Box sx={{ minWidth: "100%", mb: 3 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Vendors Email
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Vendors Email"
                onChange={AddVendorInfo}
                sx={{ minWidth: "50%" }}
              >
                {vendors && vendors.length > 0 ? (
                  vendors.map((vendor, index) => (
                    <MenuItem key={index} value={vendor}>
                      {vendor.email}
                    </MenuItem>
                  ))
                ) : (
                  <Box
                    style={{
                      color: "#878787",
                      margin: "10px",
                      fontSize: 18,
                    }}
                  >
                    Create a new Vendor
                  </Box>
                )}
              </Select>
            </FormControl>
          </Box>

          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="editorRole"
            label="Editor Role"
            name="editorRole"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Editor Role"
            value={editorRole}
          />

          <Box sx={{ minWidth: "100%", mb: 3 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Routes Permission
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Default Permission"
                onChange={AddRouterPermissionHandler}
                sx={{ minWidth: "50%" }}
              >
                {getRouterPermission && getRouterPermission.length > 0 ? (
                  getRouterPermission.map((route, index) => (
                    <MenuItem key={index} value={route}>
                      {route.router_endpoint}
                    </MenuItem>
                  ))
                ) : (
                  <Box
                    style={{
                      color: "#878787",
                      margin: "10px",
                      fontSize: 18,
                    }}
                  >
                    No available Routes
                  </Box>
                )}
              </Select>
            </FormControl>
          </Box>

          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="addPermission"
            label="Add Permission"
            name="addPermission"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Add Permission"
            value={addRouterPermission?.permission}
          />

          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="permissionName"
            label="Permission Name"
            name="permissionName"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Permission Name"
            value={addRouterPermission?.permission_id?.permission_name}
            onChange={(e) =>
              setAddPermissionId(addRouterPermission?.permission_id?._id)
            }
          />
          <Button sx={{ mt: 3 }} variant="contained" type="submit">
            Add Routes Permissions
          </Button>
        </Container>
      </CustomTabPanel>
    </div>
  );
};

export default SubRoutesPermissions;
