import React, { useEffect, useState } from "react";
import axios from "../../axiosInstance";
import { message, Modal } from "antd";
import jsPDF from "jspdf";
import { Box, Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

const QuestionPaperPDF = ({ id }) => {
  const [questions, setQuestions] = useState([]);
  const [examName, setExamName] = useState("");

  // Fetch exam data by ID
  const fetchExamById = async () => {
    try {
      const response = await axios.get(
        `/api/v1/subjective/get-exam-subjective-by-id/${id}`
      );
      setQuestions(response.data.data.subjectiveQuestions);
      setExamName(response.data.data.name);
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Failed to fetch question paper"
      );
    }
  };

  // Function to generate Question Paper PDF
  const generateQuestionPaperPDF = () => {
    Modal.confirm({
      title: "Confirm Download",
      content: "Would you like to download the question paper PDF file?",
      okText: "Yes, Download",
      cancelText: "No, Cancel",
      onOk: () => {
        try {
          const doc = new jsPDF({
            orientation: "p",
            unit: "mm",
            format: "a4",
          });
  
          let yPos = 10;
          const pageWidth = doc.internal.pageSize.width;
  
          // Center exam title
          doc.setFontSize(16);
          doc.text(stripHtmlTags(examName), pageWidth / 2, yPos, { align: "center" });
          yPos += 15;
  
          // Add questions
          questions.forEach((question, index) => {
            // Strip HTML tags and format question content
            const questionText = `${index + 1}. ${stripHtmlTags(question.name)}`;
            const questionNumber = `Question Number: ${question.questionNumber}`;
            const content = doc.splitTextToSize(
              `${questionText}\n\n${questionNumber}`,
              pageWidth - 20
            );
  
            // Check if content fits on the page
            if (
              yPos + doc.getTextDimensions(content).h >
              doc.internal.pageSize.height - 10
            ) {
              doc.addPage();
              yPos = 10;
            }
  
            doc.setFontSize(12);
            doc.text(content, 10, yPos);
            yPos += doc.getTextDimensions(content).h + 10;
          });
  
          doc.save("question_paper.pdf");
          message.success("Question Paper PDF downloaded successfully!");
        } catch (error) {
          message.error("There was an error generating the PDF.");
        }
      },
      onCancel() {
        console.log("Download canceled");
      },
    });
  };
  
  // Function to generate Answer Key PDF
  const generateAnswerKeyPDF = () => {
    Modal.confirm({
      title: "Confirm Download",
      content: "Would you like to download the answer key PDF file?",
      okText: "Yes, Download",
      cancelText: "No, Cancel",
      onOk: () => {
        try {
          const doc = new jsPDF({
            orientation: "p",
            unit: "mm",
            format: "a4",
          });
  
          let yPos = 10;
          const pageWidth = doc.internal.pageSize.width;
  
          // Add exam title centered, stripping any HTML tags
          doc.setFontSize(16);
          doc.text(`Answer Key - ${stripHtmlTags(examName)}`, pageWidth / 2, yPos, { align: "center" });
          yPos += 15;
  
          // Add questions and reference answers
          questions.forEach((question, index) => {
            const questionText = `${index + 1}. ${stripHtmlTags(question.name)}`;
            const questionNumber = `Question Number: ${question.questionNumber}`;
            const referenceAnswer = `Reference Answer: ${stripHtmlTags(question.referenceAnswer)}`;
  
            const content = doc.splitTextToSize(
              `${questionText}\n\n${questionNumber}\n\n${referenceAnswer}`,
              pageWidth - 20
            );
  
            // Handle page break
            if (
              yPos + doc.getTextDimensions(content).h >
              doc.internal.pageSize.height - 10
            ) {
              doc.addPage();
              yPos = 10;
            }
  
            doc.setFontSize(12);
            doc.text(content, 10, yPos);
            yPos += doc.getTextDimensions(content).h + 10;
          });
  
          doc.save("answer_key.pdf");
          message.success("Answer Key PDF downloaded successfully!");
        } catch (error) {
          message.error("There was an error generating the answer key PDF.");
        }
      },
      onCancel() {
        console.log("Download canceled");
      },
    });
  };
  
   // Utility function to strip HTML tags
 const stripHtmlTags = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};

  useEffect(() => {
    if (id) {
      fetchExamById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Box className="question-paper">
      <Button
        variant="contained"
        color="primary"
        onClick={generateQuestionPaperPDF}
        startIcon={<DownloadIcon />}
        className="courses_desc"
        sx={{
          mb: 2,
          justifyContent: "space-between",
          borderRadius: "5px",
          textTransform: "none",
          fontFamily: "Poppins, sans-serif",
          letterSpacing: ".1rem",
        }}
      >
        Question Paper
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={generateAnswerKeyPDF}
        startIcon={<DownloadIcon />}
        style={{ marginLeft: "10px" }}
        className="courses_desc"
        sx={{
          mb: 2,
          justifyContent: "space-between",
          borderRadius: "5px",
          textTransform: "none",
          fontFamily: "Poppins, sans-serif",
          letterSpacing: ".1rem",
        }}
      >
        Answer Key
      </Button>
    </Box>
  );
};

export default QuestionPaperPDF;
