import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  INSTITUTE_LOGIN_REQUEST,
  INSTITUTE_LOGIN_SUCCESS,
  INSTITUTE_LOGIN_FAIL,
  INSTITUTE_RELOAD_USER_REQUEST,
  INSTITUTE_RELOAD_USER_SUCCESS,
  INSTITUTE_RELOAD_USER_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  RELOAD_USER_REQUEST,
  RELOAD_USER_SUCCESS,
  RELOAD_USER_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  INSTITUTE_UPDATE_PROFILE_REQUEST,
  INSTITUTE_UPDATE_PROFILE_SUCCESS,
  INSTITUTE_UPDATE_PROFILE_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  INSTITUTE_UPDATE_PASSWORD_REQUEST,
  INSTITUTE_UPDATE_PASSWORD_SUCCESS,
  INSTITUTE_UPDATE_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  CONTACT_USER_REQUEST,
  CONTACT_USER_SUCCESS,
  CONTACT_USER_FAIL,
  ADMIN_USER_REQUEST,
  ADMIN_USER_SUCCESS,
  ADMIN_USER_FAIL,
  ADMIN_COURSES_REQUEST,
  ADMIN_COURSES_SUCCESS,
  ADMIN_COURSES_FAIL,
  GET_INSTITUTE_INFO_REQUEST,
  GET_INSTITUTE_INFO_SUCCESS,
  GET_INSTITUTE_INFO_FAILURE,
  CLEAR_ERRORS,
} from "../constants/userConstants";
import axios from "../../axiosInstance";

// login
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    const { data } = await axios.post(`/api/v1/auth/login`, {
      email,
      password,
    });
    localStorage.setItem("token", data.token);
    dispatch({ type: LOGIN_SUCCESS, payload: data.user });
  } catch (error) {
    dispatch({ type: LOGIN_FAIL, payload: error?.response?.data?.message });
  }
};

// institute Student Login
export const instituteStudentLogin =
  (studentEmail, password) => async (dispatch) => {
    try {
      dispatch({ type: INSTITUTE_LOGIN_REQUEST });
      const { data } = await axios.post(
        `/api/v1/batch/institute-student-login`,
        { studentEmail, password }
      );
      localStorage.setItem("studentToken", data.token);
      dispatch({ type: INSTITUTE_LOGIN_SUCCESS, payload: data.user });
    } catch (error) {
      dispatch({
        type: INSTITUTE_LOGIN_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

// User reload action
export const reloadUser = () => async (dispatch) => {
  try {
    dispatch({ type: RELOAD_USER_REQUEST });
    const { data } = await axios.get(`/api/v1/auth/profile`);
    dispatch({ type: RELOAD_USER_SUCCESS, payload: data.user });
    return { type: RELOAD_USER_SUCCESS, payload: data.user }; 
  } catch (error) {
    dispatch({
      type: RELOAD_USER_FAIL,
      payload: error?.response?.data?.message,
    });
    return { type: RELOAD_USER_FAIL, payload: error?.response?.data?.message };
  }
};

// Institute User reload action
export const instituteReloadUser = () => async (dispatch) => {
  try {
    dispatch({ type: INSTITUTE_RELOAD_USER_REQUEST });
    const { data } = await axios.get(`/api/v1/batch/institute-student-profile`);
    dispatch({ type: INSTITUTE_RELOAD_USER_SUCCESS, payload: data.user });
    return { type: INSTITUTE_RELOAD_USER_SUCCESS, payload: data.user };
  } 
  catch (error) {
    dispatch({
      type: INSTITUTE_RELOAD_USER_FAIL,
      payload: error?.response?.data?.message,
    });
    return {
      type: INSTITUTE_RELOAD_USER_FAIL,
      payload: error?.response?.data?.message,
    };
  }
};

// Register 
// export const userRegister = (userData) => async (dispatch) => {
//   try {
//     dispatch({ type: REGISTER_USER_REQUEST });
//     const { data } = await axios.post(`/api/v1/auth/register`, userData);
//     // Save the user data in localStorage as a string
//     localStorage.setItem("user", JSON.stringify(data.user));
//     localStorage.setItem("token", data.token);
//     dispatch({ type: REGISTER_USER_SUCCESS, payload: data.user });
//   } catch (error) {
//     dispatch({
//       type: REGISTER_USER_FAIL,
//       payload: error?.response?.data?.message,
//     });
//   }
// };

// Logout User
export const logout = () => async (dispatch) => {
  try {
    await axios.get("/api/v1/auth/logout");
    localStorage.removeItem("token");
    dispatch({ type: LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({ type: LOGOUT_FAIL, payload: error?.response?.data?.message });
  }
};

// Profile Update
export const updateProfile = (userData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST });
    const { data } = await axios.put(`/api/v1/auth/profile/update`, userData);
    dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// Institute User Profile Update
export const instituteUserUpdateProfile = (userData) => async (dispatch) => {
  try {
    dispatch({ type: INSTITUTE_UPDATE_PROFILE_REQUEST });
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
    },
    };
    const { data } = await axios.put(`/api/v1/batch/institute-student-profile-update`, userData, config);
    dispatch({ type: INSTITUTE_UPDATE_PROFILE_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: INSTITUTE_UPDATE_PROFILE_FAIL,
      payload: error?.response?.data?.message || 'Something went wrong',
    });
  }
};


// Profile Update Password (change password)
export const updatePassword = (passwords) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });
    const { data } = await axios.put(
      `/api/v1/auth/profile/password/update`,
      passwords
    );
    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// Institute User Profile Update Password (change password)
export const instituteUserUpdatePassword = (passwords) => async (dispatch) => {
  try {
    dispatch({ type: INSTITUTE_UPDATE_PASSWORD_REQUEST });
    const { data } = await axios.put(
      `/api/v1/batch/institute-student-profile-update-password`,
      passwords
    );
    dispatch({ type: INSTITUTE_UPDATE_PASSWORD_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: INSTITUTE_UPDATE_PASSWORD_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// Forgot Password
export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    const { data } = await axios.post("/api/v1/auth/password/forgot", email);
    dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data?.message });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// Reset Password
export const resetPassword = (token, passwords) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    const { data } = await axios.put(
      `/api/v1/auth/password/reset/${token}`,
      passwords
    );

    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// Contact Form
export const contactForm = (messageData) => async (dispatch) => {
  try {
    dispatch({ type: CONTACT_USER_REQUEST });
    const { data } = await axios.post(
      `/api/v1/contact/add-contact`,
      messageData
    );
    dispatch({ type: CONTACT_USER_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: CONTACT_USER_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// Admin Action
export const allUsers = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_USER_REQUEST });
    const { data } = await axios.get(`/api/v1/auth/admin-get-all-user`);
    dispatch({ type: ADMIN_USER_SUCCESS, payload: data.user });
  } catch (error) {
    dispatch({
      type: ADMIN_USER_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// // Get Course data Admin
// export const getAllCourse = async (dispatch) => {
//     try {
//         dispatch({ type: ADMIN_COURSES_REQUEST });
//         const response = await axios.get(`/api/v1/vendor/admin-get-course-institute`);
//         dispatch({ type: ADMIN_COURSES_SUCCESS, payload: response.data.data });
//     } catch (error) {
//         dispatch({
//             type: ADMIN_COURSES_FAIL,
//             payload: error?.response?.data?.message,
//         });
//     }
// };

// Get Course data Admin
export const getAllCourse = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADMIN_COURSES_REQUEST });
      const response = await axios.get(
        `/api/v1/vendor/admin-get-course-institute`
      );
      dispatch({ type: ADMIN_COURSES_SUCCESS, payload: response.data.data });
    } catch (error) {
      dispatch({
        type: ADMIN_COURSES_FAIL,
        payload: error?.response?.data?.message || "Failed to fetch courses.",
      });
    }
  };
};

// Get Institute Information
export const getInstituteInfo = (studentCourse) => async (dispatch) => {
  try {
    dispatch({ type: GET_INSTITUTE_INFO_REQUEST });
    const response = await axios.post(`/api/v1/vendor/get-institute-info`, {
      studentCourse,
    });

    dispatch({
      type: GET_INSTITUTE_INFO_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_INSTITUTE_INFO_FAILURE,
      payload: error.response.data.message ||  "Failed to fetch courses.", 
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
