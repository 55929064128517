import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, message } from "antd";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Container, TextField, Box } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "../axiosInstance";
import { Link } from "react-router-dom";

const VendorProfileViewPlan = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vendorPlan, setVendorPlan] = useState(null);
  const planStartDate = moment(vendorPlan?.startDate).format("MM/DD/YYYY");
  const planEndDate = moment(vendorPlan?.endDate).format("MM/DD/YYYY");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const userId = user._id;
  const fetchVendorPlan = async () => {
    try {
      const { data } = await axios.post(
        "/api/v1/plan/get-assign-plan-to-vendor-id",
        { userId }
      );
      setVendorPlan(data?.userPlan);
    } catch (error) {
      // message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchVendorPlan();
  }, [userId]);

  return (
    <div>
      <>
      <IconButton aria-label="view" onClick={showModal}>
        <VisibilityIcon sx={{ color: "#1976d2" }} />
      </IconButton>
        <Modal
          title={`View Plan`}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={false}
        >
        
          <h4>
            Your Email-ID : - <b>{vendorPlan?.email}</b>
          </h4>

          <Container
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <Box>
              <TextField
                label="Plan Name"
                value={vendorPlan?.name}
                fullWidth
                // margin="normal"
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="MCQ Exam"
                value={vendorPlan?.examLimit}
                fullWidth
                // margin="normal"
                sx={{ mt: 2 }}
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Subjective Exam"
                value={vendorPlan?.subjectiveExamLimit}
                fullWidth
                sx={{ mt: 2 }}
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="PDF Notes"
                value={vendorPlan?.PDFNotesLimit}
                fullWidth
                sx={{ mt: 2 }}
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Course Video"
                value={vendorPlan?.courseVideoLimit}
                fullWidth
                sx={{ mt: 2 }}
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="User Reports"
                value={vendorPlan?.reportsLimit}
                fullWidth
                sx={{ mt: 2 }}
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Primary Account"
                value={vendorPlan?.primaryAccountLimit}
                fullWidth
                sx={{ mt: 2 }}
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Teacher Account"
                value={vendorPlan?.teacherAccountLimit}
                fullWidth
                sx={{ mt: 2 }}
                InputProps={{ readOnly: true }}
              />
              <h4 style={{ marginTop: "8px" }}>
                Vendor Plan Activate Start Date
              </h4>
              <Box className="Date">
                <DatePicker required showIcon value={planStartDate} />
              </Box>
              <h4 style={{ marginTop: "8px" }}>
                Vendor Plan Deactivate End Date
              </h4>
              <Box className="Date">
                <DatePicker required showIcon value={planEndDate} />
              </Box>
            </Box>
          </Container>
        </Modal>
      </>
    </div>
  );
};

export default VendorProfileViewPlan;
