import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import axios from "../../axiosInstance";
import { message, Modal } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import EditFile from "./EditFile";
import { useSelector } from "react-redux";

const GetFiles = () => {
  const { user } = useSelector((state) => state.user);
  const [notes, setNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Get Notes PDF Data
  const getAllNotes = async () => {
    try {
      const response = await axios.get(`/api/v1/common/admin-get-all-notes`);
      if (response?.data?.success === true) {
        const dataWithRowNumbers = response?.data?.data.map((note, index) => ({
          ...note,
          rowNumber: index + 1,
        }));
        setNotes(dataWithRowNumbers);
        setFilteredNotes(dataWithRowNumbers);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllNotes();
  }, []);

  // Delete Notes PDF
  const { confirm } = Modal;
  const deleteNote = async (e, cloudinaryId) => {
    confirm({
      title: "Are you sure you want to delete this PDF Notes File?",
      content:
        "This action is irreversible. Please confirm if you want to proceed.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/common/admin-delete-pdf-notes",
            {
              data: { cloudinaryId },
            }
          );
          if (data?.success === true) {
            message.success(data.message);
            getAllNotes();
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to delete PDF note"
          );
        }
      },
      onCancel() {
        console.log("Delete operation canceled");
      },
    });
  };

  // Handle Search Input
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredData = notes.filter((note) => {
      return (
        note.subject_name.toLowerCase().includes(query) ||
        note.subject_type.toLowerCase().includes(query) ||
        note.instituteId.toLowerCase().includes(query)
      );
    });
    setFilteredNotes(filteredData);
  };

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 50,
      sortable: false,
    },
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteNote(e, value?.row?.cloudinaryId)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditFile
            refreshData={getAllNotes}
            nodeId={value?.row?._id}
            subject_name={value?.row?.subject_name}
            subject_type={value?.row?.subject_type}
            startDate={value?.row?.startDate}
            endDate={value?.row?.endDate}
            instituteId={value?.row?.instituteId}
            instituteCourseId={value?.row?.instituteCourseId}
          />
        </Box>
      ),
    },   
    {
      field: "subject_name",
      headerName: "Subject Name",
      width: 220,
      editable: true,
    },
    {
      field: "subject_type",
      headerName: "Subject Type",
      width: 210,
      editable: true,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 210,
      editable: true,
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 210,
      editable: true,
    },
  ];
// Conditionally add the instituteId column if user.role === 1
if (user && user.role === 1) {
  columns.splice(2, 0, {
    field: "instituteId",
    headerName: "Institute Id",
    width: 180,
    editable: true,
  });
}
  return (
    <>
      {/* Search Input */}
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search by Subject Name And Type"
        value={searchQuery}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <IconButton>
              <SearchIcon sx={{ color: "#1976d2" }} />
            </IconButton>
          ),
        }}
        sx={{
          mb: 3,
          bgcolor: "white",
          borderRadius: "20px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
            "&:hover fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
          },
        }}
      />
      {/* DataGrid Table */}
      <Box sx={{ height: 450, width: "100%" }}>
        <DataGrid
          getRowId={(row) => row?._id}
          sx={{
            "& .MuiTablePagination-displayedRows": {
              color: "black",
            },
            color: "black",
            [`& .${gridClasses?.row}`]: {
              bgcolor: "white",
            },
            // Style for header cells
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "rgb(25, 118, 210)",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "white",
              fontWeight: "bold",
            },
            // Style for checkbox in header
            "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
              color: "#FFF",
            },
            "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
              color: "white",
            },
            "& .MuiDataGrid-sortIcon": {
              color: "white",
            },
          }}
          rows={filteredNotes}
          columns={columns}
          pageSize={3}
          rowsPerPageOptions={[3]}
        />
      </Box>
    </>
  );
};

export default GetFiles;
