import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  CircularProgress,
  Chip,
  Breadcrumbs, Link as MUILink, 
} from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { message } from "antd";
import { useSelector } from "react-redux";
import axios from "../../axiosInstance";
import PageTitle from "../../Components/PageTitle/PageTitle";
import SubEditorList from "./SubEditorList";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const SubEditorVendor = () => {
  const [value, setValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  // eslint-disable-next-line no-unused-vars
  const [users, setUsers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [vendors, setVendors] = useState([]);
  const [vendorName, setVendorName] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [vendorPhone, setVendorPhone] = useState("");
  const [addPermissionId, setAddPermissionId] = useState("");
  const [addPermission, setAddPermission] = useState([]);
  const [role, setRole] = useState([]);
  const [instituteAccounts, setInstituteAccounts] =
    useState("Teacher Accounts");
  const [addRolePermission, setAddRolePermission] = useState("");
  const [role_name, setRole_name] = useState("");
  const [addRouterPermission, setAddRouterPermission] = useState([]);
  const [getRouterPermission, setGetRouterPermission] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imageBase64, setImageBase64] = useState("");
  const [plans, setPlans] = useState("");
  // const [vendorId, setVendorId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [vendorPlanId, setVendorPlanId] = useState("");
  const [vendorPlanName, setVendorPlanName] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // Upload button functionality
  const VisuallyHiddenInput = styled("input")({
    display: "none",
  });
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Create a URL for preview
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
      // Convert image to base64
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageBase64(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const planStartDate = moment(startDate).format("MM/DD/YYYY");
  const planEndDate = moment(endDate).format("MM/DD/YYYY");

  // Check Sub-Admin Plan
  const userId = user._id;
  const fetchVendorPlan = async () => {
    try {
      const { data } = await axios.post(
        "/api/v1/plan/get-assign-plan-to-vendor-id",
        { userId }
      );
      setPlans(data);
      // setVendorId(data?.userPlan?.userId);
      setStartDate(data?.userPlan?.startDate);
      setEndDate(data?.userPlan?.endDate);
      setVendorPlanId(data?.userPlan?.planId);
      setVendorPlanName(data?.userPlan?.name);
    } catch (error) {
      // message.error(error?.response?.data?.message);
      // console.log(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchVendorPlan();
  }, [userId]);

  // Create a New Sub-Admin and Assign Plan
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const formData = new FormData();
    formData.append("name", vendorName);
    formData.append("email", vendorEmail);
    formData.append("phone", vendorPhone);
    formData.append("instituteId", user?.instituteId);
    formData.append(
      "permissions",
      JSON.stringify([
        {
          id: addPermissionId,
          value: addPermission,
        },
      ])
    );
    formData.append("role", addRolePermission);
    formData.append("instituteAccounts", instituteAccounts);
    formData.append("assignVendorId", user?._id);
    formData.append("permission_id", addPermissionId);
    formData.append("assignCategoryPermissions", user?._id);
    formData.append("logo", imageBase64);
    formData.append("role_name", role_name);

    try {
      // First API call to create a new sub-admin
      const { data: createData } = await axios.post(
        "/api/v1/common/admin-create-editor-vendor",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (createData.success === true) {
        message.success(createData.message);

        const vendorId = createData.vendorId;

        const AssignPlanData = {
          startDate: planStartDate,
          endDate: planEndDate,
          userId: vendorId,
          planId: vendorPlanId,
        };

        try {
          const { data: assignPlanData } = await axios.post(
            "/api/v1/plan/admin-assign-plan-to-user",
            AssignPlanData
          );
          if (assignPlanData.success === true) {
            // message.success(assignPlanData.message);
          } else {
            message.error(assignPlanData?.message);
          }
        } catch (error) {
          message.error(error?.response?.data?.message);
        }
        // Clear form fields after both calls are successful
        setVendorName("");
        setVendorEmail("");
        setVendorPhone("");
        setAddPermissionId(null);
        setAddPermission([]);
        setPermissions(null);
        setRole_name(null);
        setAddRolePermission(null);
      } else {
        message.error(createData?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Get Permission Data
  const getAllPermission = async () => {
    try {
      const response = await axios.get(
        `/api/v1/common/admin-get-category-permission`
      );
      if (response?.data?.success === true) {
        setPermissions(response?.data?.allPermissions);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllPermission();
    return () => clearInterval(intervalId);
  }, []);

  const AddPermissionHandler = (e) => {
    const selectedValue = e.target.value;
    setAddPermissionId(selectedValue);
  };

  const AddRoleHandler = (e) => {
    const selectedValue = e.target.value;
    setAddRolePermission(selectedValue?.value);
    setRole_name(selectedValue?.role_name);
  };

  // Get Role Data
  const getAllRole = async () => {
    try {
      const response = await axios.get(`/api/v1/admin/admin-get-role`);
      if (response?.data?.success === true) {
        setRole(response?.data?.role);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllRole();
    return () => clearInterval(intervalId);
  }, []);

  // get-all-users
  const displayUsers = async () => {
    try {
      const { data } = await axios.get("/api/v1/auth/admin-get-all-user");
      setUsers(data?.user);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    displayUsers();
    return () => clearInterval(intervalId);
  }, []);

  // get-all-vendor
  const displayVendor = async () => {
    try {
      const { data } = await axios.get("/api/v1/common/admin-get-vendor");
      setVendors(data?.vendors);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    displayVendor();
    return () => clearInterval(intervalId);
  }, []);

  // Define a function to get the label for each MenuItem value in Add Permissions
  const getMenuItemLabel = (value) => {
    switch (value) {
      case 0:
        return "Create";
      case 1:
        return "Read";
      case 2:
        return "Update";
      case 3:
        return "Delete";
      default:
        return "";
    }
  };

  return (
    <>
      <PageTitle title="Admin Editors | Test Series" />
      <Box>
        <Typography  variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}>
          Institute Accounts
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="/admin/institute/info"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Institute Information
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>Institute Accounts</Typography>
        </Breadcrumbs>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Institute Accounts List" {...TabPanel(0)} />
            <Tab label="Add Institute Accounts" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/* Editor Vendor List */}
        <CustomTabPanel value={value} index={0}>
          {/* <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}> */}
            <SubEditorList />
          {/* </Typography> */}
        </CustomTabPanel>

        {/* Add Roles */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}>
            Create a new Institute Account
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            {/* <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="instituteId"
              label="Institute Id *"
              name="instituteId"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Institute Id for example GatikAI-01"
              value={user?.instituteId}
            /> */}

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendorName"
              label="Full Name *"
              name="vendorName"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Full Name"
              value={vendorName}
              onChange={(e) => setVendorName(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendorEmail"
              label="Email *"
              name="vendorEmail"
              InputLabelProps={{
                shrink: true,
              }}
              type="email"
              placeholder="Email"
              value={vendorEmail}
              onChange={(e) => setVendorEmail(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendorPhone"
              label="Phone Number *"
              name="vendorPhone"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Phone Number"
              type="number"
              value={vendorPhone}
              onChange={(e) => setVendorPhone(e.target.value)}
            />

            {/* Roles */}
            <Box sx={{ width: "100%", mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Role"
                  required
                  onChange={AddRoleHandler}
                >
                  {role && role.length > 0 ? (
                    role.map((role, index) => (
                      <MenuItem key={index} value={role}>
                        {role?.role_name}
                      </MenuItem>
                    ))
                  ) : (
                    <Box
                      style={{
                        color: "#878787",
                        margin: "10px",
                        fontSize: 18,
                      }}
                    >
                      No available Roles
                    </Box>
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "100%", mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                 Select Permission Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Permission Name"
                  onChange={AddPermissionHandler}
                  required
                >
                  {permissions && permissions.length > 0 ? (
                    permissions.map((permission, index) => (
                      <MenuItem key={index} value={permission.permission_id}>
                        {permission.permissions &&
                          permission.permissions.length > 0 &&
                          permission.permissions[0].permission_name}
                      </MenuItem>
                    ))
                  ) : (
                    <Box
                      style={{
                        color: "#878787",
                        margin: "10px",
                        fontSize: 18,
                      }}
                    >
                      No available Permission
                    </Box>
                  )}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ width: "100%", mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {" "}
                  Select Permission{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={addPermission}
                  label="Select Permission"
                  multiple
                  required
                  onChange={(e) => setAddPermission(e.target.value)}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip
                          sizes="medium"
                          key={value}
                          label={getMenuItemLabel(value)}
                        />
                      ))}
                    </div>
                  )}
                >
                  <MenuItem value={0}>Create</MenuItem>
                  <MenuItem value={1}>Read</MenuItem>
                  <MenuItem value={2}>Update</MenuItem>
                  <MenuItem value={3}>Delete</MenuItem>
                </Select>
              </FormControl>

              <TextField
                sx={{
                  mb: 3,
                  mt: 3,
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
                fullWidth
                id="planName"
                label="Plan Name *"
                name="planName"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Plan Name"
                value={vendorPlanName}
              />

              <Box style={{ display: "flex" }} className="Date">
                <Box
                  sx={{
                    mb: 3,
                  }}
                  style={{ marginRight: "2%" }}
                >
                  <DatePicker
                    showIcon
                    toggleCalendarOnIconClick
                    isClearable
                    placeholderText="Assign Plan Start Date"
                    selected={planStartDate}
                    value={planStartDate}
                    // onChange={handleStartDateChange}
                    minDate={new Date()}
                    maxDate={endDate}
                    required
                  />
                </Box>
                <Box>
                  <DatePicker
                    required
                    showIcon
                    toggleCalendarOnIconClick
                    isClearable
                    placeholderText="Assign Plan End Date"
                    selected={planEndDate}
                    // onChange={handleEndDateChange}
                    value={planEndDate}
                    // // eslint-disable-next-line no-mixed-operators
                    minDate={(new Date() && startDate) || new Date()}
                    maxDate={endDate}
                  />
                </Box>
              </Box>

              <Box sx={{ mt: 3 }}>
                <VisuallyHiddenInput
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={handleImageUpload}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="outlined"
                    color="primary"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Institute logo
                  </Button>
                </label>
                {image && (
                  <Box mt={3} display="flex" alignItems="center">
                    <img
                      src={image}
                      alt="avatar"
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>

            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              color="primary"
              className="courses_desc"
              sx={{
                mt: 3,
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                "Create Institute Account"
              )}
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default SubEditorVendor;
