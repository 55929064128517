import React, { useEffect, useState } from "react";
import { Box, Paper, TextField, Button } from "@mui/material";
import axios from "../../axiosInstance";
import { message, Modal } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import EditVideo from "./EditVideo";
import { useSelector } from "react-redux";

const GetVideo = () => {
  const { user } = useSelector((state) => state.user);
  const [notes, setNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Get Video Data
  const getAllVideo = async () => {
    try {
      const response = await axios.get(`/api/v1/admin/admin-get-video`);
      if (response?.data?.success === true) {
        const dataWithRowNumber = response.data.data.map((note, index) => ({
          ...note,
          rowNumber: index + 1,
        }));
        setNotes(dataWithRowNumber);
        setFilteredNotes(dataWithRowNumber);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllVideo();
    return () => clearInterval(intervalId);
  }, []);

  // Delete Video
  const { confirm } = Modal;
  const deleteVideo = async (e, cloudinaryId, id) => {
    confirm({
      title: "Are you sure you want to delete this Video?",
      content:
        "This action cannot be undone. Please confirm if you want to proceed.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/admin/admin-delete-video",
            {
              data: { cloudinaryId, id },
            }
          );
          if (data?.success === true) {
            message.success(data.message);
            getAllVideo();
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to delete video"
          );
        }
      },
      onCancel() {
        console.log("Video deletion canceled");
      },
    });
  };

  // Search Functionality
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchQuery(value);

    const filtered = notes.filter(
      (note) =>
        note.title?.toLowerCase().includes(value) ||
        note.description?.toLowerCase().includes(value)
    );
    setFilteredNotes(filtered);
  };

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 50,
      sortable: false,
    },
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) =>
              deleteVideo(e, value?.row?.cloudinaryId, value?.row?._id)
            }
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditVideo
            refreshData={getAllVideo}
            videoId={value?.row?._id}
            title={value?.row?.title}
            description={value?.row?.description}
            startDate={value?.row?.startDate}
            endDate={value?.row?.endDate}
            instituteId={value?.row?.instituteId}
            instituteCourseId={value?.row?.instituteCourseId}
          />
        </Box>
      ),
    },
    // {
    //   field: "instituteId",
    //   headerName: "Institute Id",
    //   width: 210,
    //   editable: true,
    // },
    {
      field: "title",
      headerName: "Course Title",
      width: 210,
      editable: true,
    },
    // {
    //   field: "description",
    //   headerName: "Course Description",
    //   width: 290,
    //   editable: true,
    // },
    {
      field: "description",
      headerName: "Course Description",
      width: 290,
      renderCell: (params) => (
        <div
          style={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
          }}
          dangerouslySetInnerHTML={{ __html: params.row.description }}
        />
      ),
    },
    {
      field: "url",
      headerName: "Video Url",
      width: 160,
      editable: true,
      renderCell: (params) => {
        const url = params.value;
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <Button
              variant="outlined"
              color="primary"
              className="courses_desc"
              sx={{
                borderRadius: "50px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              {" "}
              Watch Video{" "}
            </Button>
          </a>
        );
      },
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 180,
      editable: true,
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 180,
      editable: true,
    },
  ];

  // Conditionally add the instituteId column if user.role === 1
  if (user && user.role === 1) {
    columns.splice(2, 0, {
      field: "instituteId",
      headerName: "Institute Id",
      width: 180,
      editable: true,
    });
  }

  return (
    <>
      {/* Search Input */}
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search by Course Title And Description"
        value={searchQuery}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <IconButton>
              <SearchIcon sx={{ color: "#1976d2" }} />
            </IconButton>
          ),
        }}
        sx={{
          mb: 3,
          bgcolor: "white",
          borderRadius: "20px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
            "&:hover fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
          },
        }}
      />
      <Paper sx={{ bgcolor: "white" }}>
        <Box sx={{ height: 500, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row?._id}
            sx={{
              "& .MuiTablePagination-displayedRows": {
                color: "black",
              },
              color: "black",
              [`& .${gridClasses?.row}`]: {
                bgcolor: "white",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgb(25, 118, 210)",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                color: "#FFF",
              },
              "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                color: "white",
              },
            }}
            rows={filteredNotes} // Use filtered notes for display
            columns={columns}
            pageSize={3}
            rowsPerPageOptions={[3]}
          />
        </Box>
      </Paper>
    </>
  );
};

export default GetVideo;
