import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Paper,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "../../axiosInstance";
import { message, Modal } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditRole from "./EditRole";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSelector } from "react-redux";
import imageCompression from "browser-image-compression";
import EditTeacher from "./EditTeacher";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddTeachers() {
  const [value, setValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  const [instituteTeachers, setInstituteTeachers] = useState([]);
  const [teacherName, setTeacherName] = useState("");
  const [teacherSubject, setTeacherSubject] = useState("");
  const [teacherExperience, setTeacherExperience] = useState("");
  const [teacherEmail, setTeacherEmail] = useState("");
  const [teacherPhone, setTeacherPhone] = useState("");
  const [teacherPublic, setTeacherPublic] = useState("");
  const [image, setImage] = useState(null);
  const [imageBase64, setImageBase64] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Upload button functionality
  const VisuallyHiddenInput = styled("input")({
    display: "none",
  });

  // Compress the image
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const maxSizeInMB = 25;

    if (file) {
      if (file.size / (1024 * 1024) > maxSizeInMB) {
        message.error(`Image size should not exceed ${maxSizeInMB} MB.`);
        return;
      }
      // Compress the image before conversion to base64
      const options = {
        maxSizeMB: 25,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onloadend = async () => {
          const imageBase64 = reader.result;
          setImage(imageBase64);
          setImageBase64(imageBase64);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
  };

  // Get Institute Teacher Data
  const getAllInstituteTeacher = async () => {
    try {
      const response = await axios.get(
        `/api/v1/vendor/admin-get-all-teacher-details-institute`
      );
      if (response?.data?.success === true) {
        setInstituteTeachers(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllInstituteTeacher();
    return () => clearInterval(intervalId);
  }, []);

  // Delete Institute Teacher
  const { confirm } = Modal;
  const deleteInstituteTeacher = async (e, id) => {
    confirm({
      title: "Are you sure you want to delete this Institute Teacher?",
      content:
        "This action cannot be undone and will permanently remove the institute teacher.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      okType: "danger",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/vendor/admin-delete-teacher-institute",
            {
              data: { id },
            }
          );
          if (data.success === true) {
            message.success(data?.message);
            getAllInstituteTeacher();
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Error deleting the role."
          );
        }
      },
      onCancel() {
        console.log("Delete role action canceled");
      },
    });
  };

  const columns = [
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteInstituteTeacher(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditTeacher
            refreshData={getAllInstituteTeacher}
            teacherId={value?.row?._id}
            instituteTeacher_Name={value?.row?.teacherName}
            instituteTeacher_Subject={value?.row?.teacherSubject}
            instituteTeacher_Experience={value?.row?.teacherExperience}
            instituteTeacher_Image={value?.row?.url}
            instituteTeacher_Email={value?.row?.teacherEmail}
            instituteTeacher_Phone={value?.row?.teacherPhone}
            instituteTeacher_Public={value?.row?.teacherPublic}
            instituteId={value?.row?.instituteId}
            vendorId={value?.row?.vendorId}
          />
        </Box>
      ),
    },
    {
      field: "teacherPublic",
      headerName: "Public",
      width: 110,
      editable: true,
    },
    {
      field: "url",
      headerName: "Image",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.row.url}
          alt={params.row.teacherName}
          style={{ width: "100px", height: "auto", borderRadius: "5px" }}
        />
      ),
    },
   
    {
      field: "teacherName",
      headerName: "Full Name",
      width: 150,
      editable: true,
    },
    {
      field: "teacherSubject",
      headerName: " Subject",
      width: 180,
      editable: true,
    },
    {
      field: "teacherExperience",
      headerName: " Experience",
      width: 150,
      editable: true,
    },
    {
      field: "teacherEmail",
      headerName: " Email",
      width: 150,
      editable: true,
    },
    {
      field: "teacherPhone",
      headerName: " Phone",
      width: 150,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 120,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY"),
    },
  ];
  // Conditionally add the instituteId column if user.role === 1
  if (user && user.role === 1) {
    columns.splice(1, 0, {
      field: "instituteId",
      headerName: "Institute Id",
      width: 120,
      editable: true,
    });
  }

  // Add Institute Teacher
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    const formData = new FormData();
    formData.append("teacherName", teacherName);
    formData.append("teacherSubject", teacherSubject);
    formData.append("teacherExperience", teacherExperience);
    formData.append("teacherPhone", teacherPhone);
    formData.append("teacherEmail", teacherEmail);
    formData.append("teacherPublic", teacherPublic);
    formData.append("instituteId", user?.instituteId);
    formData.append("vendorId", user?._id);
    formData.append("teacherImage", imageBase64);
    try {
      const response = await axios.post(
        "/api/v1/vendor/admin-teacher-institute",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success === true) {
        message.success(response.data.message);
        setTeacherName("");
        setTeacherSubject("");
        setTeacherExperience("");
        setImageBase64("");
        setImage(null);
        setTeacherEmail("");
        setTeacherPhone("");
        setTeacherPublic(null)
      } else {
        message.error("An error occurred. Please try again.");
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "An error occurred.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <>
      <PageTitle title="Admin Add Teacher | Test Series" />
      <Box>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Institute All Teachers
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="/admin/institute/info"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Institute Information
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>Teachers</Typography>
        </Breadcrumbs>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Teachers List" {...TabPanel(0)} />
            <Tab label="Add Teacher" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/* Teachers List */}
        <CustomTabPanel value={value} index={0}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Teachers List
          </Typography>
          <Paper sx={{ bgcolor: "white" }}>
            <Box sx={{ height: 450, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row?._id}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                  // Style for header cells
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                    // fontSize: "13px",
                  },
                  // Style for checkbox in header
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                rows={instituteTeachers}
                columns={columns}
                pageSize={3}
                rowsPerPageOptions={[3]}
                // checkboxSelection
              />
            </Box>
          </Paper>
        </CustomTabPanel>

        {/* Add a New Teacher */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Create a new Teacher
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="teacherName"
              label="Teacher Name *"
              name="teacherName"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Teacher Name"
              value={teacherName}
              onChange={(e) => setTeacherName(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="teacherSubject"
              label="Teacher Subject *"
              name="teacherSubject"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Teacher Subject"
              value={teacherSubject}
              onChange={(e) => setTeacherSubject(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="teacherExperience"
              label="Teacher Experience (Years)*"
              name="teacherExperience"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="Teacher Experience (Years)"
              value={teacherExperience}
              onChange={(e) => setTeacherExperience(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="teacherEmail"
              label="Teacher Email"
              name="teacherEmail"
              type="email"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Teacher Email"
              value={teacherEmail}
              onChange={(e) => setTeacherEmail(e.target.value)}
            />

            <TextField
               sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="teacherPhone"
              label="Teacher Phone"
              name="teacherPhone"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Teacher Phone"
              value={teacherPhone}
              onChange={(e) => setTeacherPhone(e.target.value)}
            />

            <Box sx={{ mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="teacher-public-label">
                  Teacher Public
                </InputLabel>
                <Select
                  labelId="teacher-public-label"
                  id="teacher-public"
                  value={teacherPublic}
                  label="Teacher Public"
                  onChange={(e) => setTeacherPublic(e.target.value)}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box>
              <VisuallyHiddenInput
                accept="image/*"
                id="contained-button-file"
                type="file"
                onChange={handleImageUpload}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="outlined"
                  color="primary"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                >
                  Teacher Image
                </Button>
              </label>
              {image && (
                <Box mt={3} display="flex" alignItems="center">
                  <img
                    src={image}
                    alt="avatar"
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50%",
                    }}
                  />
                </Box>
              )}
            </Box>

            <Button
              sx={{ mt: 3 }}
              variant="contained"
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Add Teacher"}
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
}
