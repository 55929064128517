import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Container, TextField, Button, Box, MenuItem } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import axios from "../../axiosInstance";

const EditVideo = ({
  refreshData,
  videoId,
  title,
  description,
  startDate,
  endDate,
  instituteId,
  instituteCourseId,
}) => {
  const { courses } = useSelector((state) => state.courseData);
  const [video_Title, setVideo_Title] = useState(title);
  const [video_Description, setVideo_Description] = useState(description);
  const [start_Date, setStart_Date] = useState(startDate);
  const [end_Date, setEnd_Date] = useState(endDate);
  const [studentCourseId, setStudentCourseId] = useState(instituteCourseId);
  const [studentCourse, setStudentCourse] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    // window.location.reload();
  };

  const handleStartDateChange = (date) => {
    setStart_Date(date);
  };
  const handleEndDateChange = (date) => {
    setEnd_Date(date);
  };
  const videoStartDate = moment(start_Date).format("MM/DD/YYYY");
  const videoEndDate = moment(end_Date).format("MM/DD/YYYY");

  // Get Course by instituteCourseId
  useEffect(() => {
    if (instituteCourseId && courses.length > 0) {
      // Find the course that matches the `instituteCourseId`
      const selectedCourse = courses.find(
        (course) => course._id === instituteCourseId
      );
      if (selectedCourse) {
        setStudentCourse(selectedCourse.courseName);
      } else if (instituteCourseId === "81af3e57846a42aab058dc4d") {
        setStudentCourse("General");
        setStudentCourseId("81af3e57846a42aab058dc4d");
      }
    }
  }, [instituteCourseId, courses]);

  useEffect(() => {
    setStudentCourseId(instituteCourseId);
  }, [instituteCourseId]);

  // Handle course selection change
  const handleChangeStudentCourse = (event) => {
    const selectedCourseName = event.target.value;
    setStudentCourse(selectedCourseName);

    // Find the selected course from the courses array
    const selectedCourse = courses.find(
      (course) => course.courseName === selectedCourseName
    );

    if (selectedCourse) {
      setStudentCourseId(selectedCourse._id);
    } else if (selectedCourseName === "General") {
      // Assign a fixed ID for "General"
      setStudentCourseId("81af3e57846a42aab058dc4d");
    }
  };

  // Edit Video Course
  const { confirm } = Modal;
  const handleSubmit = async (e) => {
    e.preventDefault();
    confirm({
      title: "Are you sure you want to edit this video information?",
      content:
        "Please confirm that you want to proceed with editing the video details.",
      okText: "Yes, Edit",
      cancelText: "No, Cancel",
      onOk: async () => {
        const editVideoCourse = {
          id: videoId,
          title: video_Title,
          description: video_Description,
          startDate: videoStartDate,
          endDate: videoEndDate,
          instituteId: instituteId,
          instituteCourseId: studentCourseId,
        };

        try {
          const { data } = await axios.put(
            "/api/v1/admin/admin-update-video",
            editVideoCourse
          );

          if (data.success === true) {
            message.success(data.message);
            refreshData();
            setIsModalOpen(false);
          } else {
            message.error(data?.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to update video"
          );
        }
      },
      onCancel() {
        console.log("Video edit canceled");
      },
    });
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={showModal}>
        <EditIcon sx={{ color: "#1976d2" }} />
      </IconButton>
      <Modal
        title="Edit Video Course"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        width={1000}
      >
        <Container
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
          maxWidth
        >
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            select
            name="studentCourse"
            label="Institute Course"
            placeholder="Institute Course"
            value={studentCourse || ""}
            onChange={handleChangeStudentCourse}
            fullWidth
            variant="outlined"
          >
            <MenuItem disabled>Select Course</MenuItem>
            <MenuItem key="generalCourse" value="General">
              General
            </MenuItem>
            {courses &&
              courses.map((course) => (
                <MenuItem key={course._id} value={course.courseName}>
                  {course.courseName}
                </MenuItem>
              ))}
          </TextField>

          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="video_Title"
            label="Title "
            name="video_Title"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Title"
            value={video_Title}
            onChange={(e) => setVideo_Title(e.target.value)}
          />

          {/* <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="video_Title"
            label="Description"
            name="video_Title"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Description"
            multiline
            value={video_Description}
            onChange={(e) => setVideo_Description(e.target.value)}
          /> */}
          <h4 style={{ color: "#2C3E50" }}>Short Description</h4>

<ReactQuill
  value={video_Description}
  onChange={setVideo_Description}
  placeholder="Short Description"
  style={{
    // height: "200px",
    marginBottom: "30px",
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: "10px",
  }}
/>

          <Box style={{ display: "flex" }} className="Date">
            <Box
              sx={{
                mb: 3,
              }}
              style={{ marginRight: "2%" }}
            >
              <DatePicker
                showIcon
                toggleCalendarOnIconClick
                isClearable
                placeholderText="Start Date"
                selected={start_Date}
                value={start_Date}
                onChange={handleStartDateChange}
                minDate={new Date()}
                maxDate={end_Date}
                required
              />
            </Box>
            <Box>
              <DatePicker
                showIcon
                toggleCalendarOnIconClick
                isClearable
                placeholderText="End Date"
                selected={end_Date}
                onChange={handleEndDateChange}
                value={end_Date}
                // eslint-disable-next-line no-mixed-operators
                minDate={(new Date() && start_Date) || new Date()}
                maxDate={end_Date}
                required
              />
            </Box>
          </Box>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            className="courses_desc"
            sx={{
              borderRadius: "5px",
              textTransform: "none",
              fontFamily: "Poppins, sans-serif",
              letterSpacing: ".1rem",
            }}
          >
            Edit Video Course
          </Button>
        </Container>
      </Modal>
    </div>
  );
};

export default EditVideo;
