import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Box,
  Breadcrumbs,
  Link as MUILink,
  Typography,
  MenuItem,
  Button,
} from "@mui/material";
import { message } from "antd";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import axios from "../axiosInstance";

const NewCourseRequestTableAddBatch = () => {
  const location = useLocation();
  const { id, users, filteredVendors } = location.state || {};
  const student = filteredVendors?.filter((vendor) => vendor._id === id)[0] || [];
  const { user } = useSelector((state) => state.user);
  const [studentCourseId, setStudentCourseId] = useState("");
  const [studentCourse, setStudentCourse] = useState("");
  const [studentCourseFees, setStudentCourseFees] = useState("");
  const [loading, setLoading] = useState(false);
  const [teachersNotes, setTeachersNotes] = useState("");
  const [studentFees, setStudentFees] = useState("");
  const [batches, setBatches] = useState([]);
  const [batchId, setBatchId] = useState(""); 
  const [batchName, setBatchName] = useState(""); 
  const [email, setEmail] = useState(student.email);
  const [studentName, setStudentName] = useState(student.name);
  const [studentEmail, setStudentEmail] = useState(student.email);
  const [studentPhone, setStudentPhone] = useState(student.phone);
  const [userData, setUserData] = useState(null);

  // Handle Student Course
  const handleChangeStudentCourse = (event) => {
    const selectedCourseName = event.target.value;
    setStudentCourse(selectedCourseName);

    // Find the selected course from the requestUser array
    const selectedCourse = student.requestUser.find(
      (course) => course.courseId?.courseName === selectedCourseName
    );
   
    // If the selected course is found, update the state with course details
    if (selectedCourse) {
      setStudentCourseFees(selectedCourse.courseId?.courseFees || "0");
      setStudentCourseId(selectedCourse.courseId?._id);
    }
  };

  // Handle Batch Selection
  const handleBatchChange = (event) => {
    const selectedBatchName = event.target.value;
    setBatchName(selectedBatchName);
    // Find the selected batch by name and update batchId
    const selectedBatch = batches.find(
      (batch) => batch.batchName === selectedBatchName
    );
    if (selectedBatch) {
      setBatchId(selectedBatch._id);
    }
    handleEmailChange(email)
  };

  useEffect(() => {
    handleEmailChange(email)
  }, [])

  const handleStudentFeesChange = (e) => {
    const fees = e.target.value;
    // Allow empty input or a number less than or equal to course fees
    if (fees === "" || parseFloat(fees) <= studentCourseFees) {
      setStudentFees(fees);
    } else {
      message.warning("Student Fees cannot exceed Course Fees.");
    }
  };

  // Get All Batch Data
  const getAllBatch = async () => {
    try {
      const response = await axios.get(`/api/v1/batch/admin-get-batch`);
      if (response?.data?.success === true) {
        setBatches(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllBatch();
  }, []);

  // Add the Public User in Batch 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (batchId.length === 0) {
        message.error("Please select an batch")
        return;
    }

    if (studentCourseId.length === 0) {
        message.error("Please select an student request course")
        return;
    }

    if (studentFees.length === 0) {
        message.error("Please enter the student fess")
        return;
    }

    // Define the "General" course
    const generalCourse = {
      studentCourseName: "General",
      courseFees: "0",
      studentCourseId: "81af3e57846a42aab058dc4d",
      batchId, // Include batchId
      instituteId: user?.instituteId,
      vendorId: user?._id,
      studentFees: "0",
      teachersNotes: "",
    };

    // Define the selected course
    const selectedCourse = {
      studentCourseName: studentCourse,
      courseFees: studentCourseFees,
      studentCourseId,
      batchId, // Include batchId
      instituteId: user?.instituteId,
      vendorId: user?._id,
      studentFees,
      teachersNotes,
    };

    try {
      // Check if the "General" course has been assigned for the specific instituteId
      const { data: existingData } = await axios.get(
        `/api/v1/batch/admin-check-user-general-course-batch=${user?.instituteId}`
      );

      // Initialize the courses array with the selected course
      let studentCourses = [selectedCourse];

      // If the "General" course does not already exist for this instituteId, add it
      if (!existingData?.hasGeneralCourse) {
        studentCourses.push(generalCourse);
      }

      const addInstituteStudent = {
        studentName: student.name,
        studentEmail: student.email,
        studentPhone: student.phone,
        studentCourse: studentCourses,
        // parentName,
        // parentEmail,
        // parentPhone,
        userId: student._id,
      };

      const { data } = await axios.post(
        "/api/v1/batch/admin-add-institute-user-batch",
        addInstituteStudent
      );

      if (data.success === true) {
        message.success(data.message);
        // Clear form fields
        setStudentFees("");
        setStudentCourse("");
        // setParentName("");
        // setParentEmail("");
        // setParentPhone("");
        setTeachersNotes("");
        setBatchName("");
        setBatchId("");
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Handle Email Change (Get Data Student Details by email)
  const handleEmailChange = async (email) => {
    setEmail(email);
    setStudentEmail(email);
    if (email) {
      setLoading(true);
      //   setError("");
      try {
        const response = await axios.post(
          "/api/v1/batch/admin-get-email-user-batch",
          { email }
        );
        if (response.data.data) {
          //   setUserData(response.data.data);
          setStudentName(response.data.data.studentName || "");
          setStudentPhone(response.data.data.studentPhone || "");
          setStudentCourse(response.data.data.studentCourse || "");
          setStudentCourseFees(response.data.data.studentCourseFees || "");
          setStudentFees(response.data.data.studentFees || "");
          // setParentName(response.data.data.parentName || "");
          // setParentEmail(response.data.data.parentEmail || "");
          // setParentPhone(response.data.data.parentPhone || "");
          setTeachersNotes(response.data.data.teachersNotes || "");
        } else {
          setUserData(null);
          setStudentName("");
          setStudentPhone("");
          setStudentCourse("");
          setStudentCourseFees("");
          setStudentFees("");
          // setParentName("");
          // setParentEmail("");
          // setParentPhone("");
          setTeachersNotes("");
        }
      } catch (err) {
        // setError("Error fetching user data");
      } finally {
        setLoading(false);
      }
    } else {
      setUserData(null);
      setStudentName("");
      setStudentPhone("");
      setStudentCourse("");
      setStudentCourseFees("");
      setStudentFees("");
      // setParentName("");
      // setParentEmail("");
      // setParentPhone("");
      setTeachersNotes("");
    }
  };

  return (
    <div>
      <>
      <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
        Add course request student
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="/admin/new/course/request"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
           Course Request
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>Batch</Typography>
        </Breadcrumbs>

        <Container
          component="form"
          className="form_style border-style"
          maxWidth
          onSubmit={handleSubmit}
        >
          <Box>
            {/* Select Batch */}
            <TextField
            //   sx={{  mt: 3 }}
              select
              name="batch"
              label="Batch"
              placeholder="Select Batch"
              value={batchName}
              onChange={handleBatchChange}
              fullWidth
              variant="outlined"
            >
              <MenuItem disabled>Select Batch</MenuItem>
              {batches.map((batch) => (
                <MenuItem key={batch._id} value={batch.batchName}>
                  {batch.batchName}
                </MenuItem>
              ))}
            </TextField>
            {/* Select Student Course */}
            <TextField
              sx={{ mb: 3, mt: 3 }}
              select
              name="studentCourse"
              label="Student Course"
              placeholder="Student Course"
              value={studentCourse}
              onChange={handleChangeStudentCourse}
              fullWidth
              variant="outlined"
            >
              <MenuItem disabled>Select Course</MenuItem>
              {student?.requestUser &&
                student.requestUser.map((course) => (
                  <MenuItem
                    key={course._id}
                    value={course.courseId?.courseName}
                  >
                    {course.courseId?.courseName}
                  </MenuItem>
                ))}
            </TextField>
            {/* Displaying Course Fees */}
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="studentCourseFees"
              label="Student Course Fees *"
              name="studentCourseFees"
              placeholder="Student Course Fees"
              value={studentCourseFees}
              onChange={(e) => setStudentCourseFees(e.target.value)}
              disabled
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="studentFees"
              label="Student Fees"
              name="studentFees"
              type="number"
              placeholder="Student Fees"
              value={studentFees}
              onChange={handleStudentFeesChange}
            //   required
            />
            {/* Student Info */}
            <TextField
              label="Student Name"
              value={studentName}
              fullWidth
              InputProps={{ readOnly: true }}
              disabled
            />
            <TextField
              label="Student Phone Number"
              value={studentPhone}
              fullWidth
              sx={{ mt: 3 }}
              InputProps={{ readOnly: true }}
              disabled
            />
            <TextField
              label="Student Email"
              value={studentEmail}
              onChange={handleEmailChange}
              fullWidth
              sx={{ mt: 3 }}
              InputProps={{ readOnly: true }}
              disabled
            />

            {/* <TextField
              sx={{
                mb: 3,
                mt: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="parentName"
              label="Parent Name"
              name="parentName"
              placeholder="Parent Name"
              value={parentName}
              onChange={(e) => setParentName(e.target.value)}
            /> */}

            {/* <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="parentEmail"
              label="Parent Email"
              name="parentEmail"
              placeholder="Parent Email"
              value={parentEmail}
              onChange={(e) => setParentEmail(e.target.value)}
            /> */}

            {/* <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="parentPhone"
              label="Parent Phone"
              name="parentPhone"
              placeholder="Parent Phone"
              value={parentPhone}
              onChange={(e) => setParentPhone(e.target.value)}
            /> */}

            <TextField
              sx={{
                mb: 3,
                mt:2,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="teachersNotes"
              label="Teacher's Notes"
              name="teachersNotes"
              placeholder="Teacher's Notes"
              value={teachersNotes}
              onChange={(e) => setTeachersNotes(e.target.value)}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="courses_desc"
              sx={{
                mt: 3,
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
               Add Student
            </Button>
          </Box>
        </Container>
      </>
    </div>
  );
};

export default NewCourseRequestTableAddBatch;
