import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { message } from "antd";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../axiosInstance";
import { getAllCourse } from "../../redux/actions/userAction";

const AddStudentBatch = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { batchId, batchName } = location.state || {};
  const { user } = useSelector((state) => state.user);
  const { courses } = useSelector((state) => state.courseData);
  const [studentCourseId, setStudentCourseId] = useState("");
  const [studentName, setStudentName] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [studentPhone, setStudentPhone] = useState("");
  const [studentCourse, setStudentCourse] = useState("");
  const [studentCourseFees, setStudentCourseFees] = useState("");
  const [studentFees, setStudentFees] = useState("");
  const [teachersNotes, setTeachersNotes] = useState("");
  const [parentName, setParentName] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [parentPhone, setParentPhone] = useState("");
  const [email, setEmail] = useState("");
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Handle Student Course
  const handleChangeStudentCourse = (event) => {
    const selectedCourseName = event.target.value;
    setStudentCourse(selectedCourseName);
    const selectedCourse = courses.find(
      (course) => course.courseName === selectedCourseName
    );
    if (selectedCourse) {
      setStudentCourseFees(selectedCourse.courseFees);
      setStudentCourseId(selectedCourse._id);
    }
  };

  // Fetch all courses
  useEffect(() => {
    dispatch(getAllCourse());
  }, [dispatch]);

  useEffect(() => {
    if (courses) {
      setStudentCourse(courses);
    }
  }, [courses]);

  // Add Institute Student
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

  if (studentCourseId.length === 0) {
      message.error("Please select an student course")
      setLoading(false);
      return;
  }

  if (studentFees.length === 0) {
      message.error("Please enter the student fess")
      setLoading(false);
      return;
  }
  
    // Define the "General" course
    const generalCourse = {
      studentCourseName: "General",
      courseFees: "0",
      studentCourseId: "81af3e57846a42aab058dc4d",
      batchId,
      instituteId: user?.instituteId,
      vendorId: user?._id,
      studentFees: "0",
      teachersNotes: "",
    };
  
    // Define the selected course
    const selectedCourse = {
      studentCourseName: studentCourse,
      courseFees: studentCourseFees,
      studentCourseId,
      batchId,
      instituteId: user?.instituteId,
      vendorId: user?._id,
      studentFees,
      teachersNotes,
    };
  
    try {
      // Check if the "General" course has been assigned for the specific instituteId
      const { data: existingData } = await axios.get(
        `/api/v1/batch/admin-check-user-general-course-batch=${user?.instituteId}`
      );
  
      // Initialize the courses array with the selected course
      let studentCourses = [selectedCourse];
  
      // If the "General" course does not already exist for this instituteId, add it
      if (!existingData?.hasGeneralCourse) {
        studentCourses.push(generalCourse);
      }
  
      const addInstituteStudent = {
        studentName,
        studentEmail,
        studentPhone,
        studentCourse: studentCourses,
        parentName,
        parentEmail,
        parentPhone,
      };
  
      const { data } = await axios.post(
        "/api/v1/batch/admin-add-institute-user-batch",
        addInstituteStudent
      );
  
      if (data.success === true) {
        message.success(data.message);
        // Clear form fields
        setStudentName("");
        setStudentEmail("");
        setStudentPhone("");
        setStudentFees("");
        setStudentCourse("");
        setParentName("");
        setParentEmail("");
        setParentPhone("");
        setTeachersNotes("");
        setEmail("");
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  
  // Handle Email Change (Get Data Student Details by email)
  const handleEmailChange = async (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setStudentEmail(newEmail);
    if (newEmail) {
      setLoading(true);
      setError("");
      try {
        const response = await axios.post(
          "/api/v1/batch/admin-get-email-user-batch",
          { email: newEmail }
        );
        if (response.data.data) {
          setUserData(response.data.data);
          setStudentName(response.data.data.studentName || "");
          setStudentPhone(response.data.data.studentPhone || "");
          setStudentCourse(response.data.data.studentCourse || "");
          setStudentCourseFees(response.data.data.studentCourseFees || "");
          setStudentFees(response.data.data.studentFees || "");
          setParentName(response.data.data.parentName || "");
          setParentEmail(response.data.data.parentEmail || "");
          setParentPhone(response.data.data.parentPhone || "");
          setTeachersNotes(response.data.data.teachersNotes || "");
        } else {
          setUserData(null);
          setStudentName("");
          setStudentPhone("");
          setStudentCourse("");
          setStudentCourseFees("");
          setStudentFees("");
          setParentName("");
          setParentEmail("");
          setParentPhone("");
          setTeachersNotes("");
        }
      } catch (err) {
        setError("Error fetching user data");
      } finally {
        setLoading(false);
      }
    } else {
      setUserData(null);
      setStudentName("");
      setStudentPhone("");
      setStudentCourse("");
      setStudentCourseFees("");
      setStudentFees("");
      setParentName("");
      setParentEmail("");
      setParentPhone("");
      setTeachersNotes("");
    }
  };

  const handleStudentFeesChange = (e) => {
    const fees = e.target.value;
    // Allow empty input or a number less than or equal to course fees
    if (fees === "" || parseFloat(fees) <= studentCourseFees) {
      setStudentFees(fees);
    } else {
      message.warning("Student Fees cannot exceed Course Fees.");
    }
  };

  return (
    <div>
      <Typography
        variant="h6"
        sx={{
          pb: 2,
          pt: 3,
          fontFamily: "Poppins, sans-serif",
          color: "#555",
          letterSpacing: "2.5px",
          lineHeight: 1.8,
        }}
      >
        Add a new Student
      </Typography>
      <Container
        onSubmit={handleSubmit}
        component="form"
        className="form_style border-style"
        maxWidth
      >
        <TextField
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="studentBatch"
          label="Batch Name *"
          name="studentBatch"
          placeholder="Batch Name"
          value={batchName}
          aria-readonly="true"
          disabled
        />

        <TextField
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="studentEmail"
          label="Student Email *"
          name="studentEmail"
          placeholder="Student Email"
          value={email}
          onChange={handleEmailChange}
        />
        {/* Text fields are now always displayed regardless of email data */}
        <TextField
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="studentName"
          label="Student Name *"
          name="studentName"
          placeholder="Student Name"
          value={studentName}
          onChange={(e) => setStudentName(e.target.value)}
        />
        <TextField
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="studentPhone"
          label="Phone Number *"
          name="studentPhone"
          placeholder="Phone Number"
          value={studentPhone}
          onChange={(e) => setStudentPhone(e.target.value)}
        />

        <TextField
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          select
          name="studentCourse"
          label="Student Course"
          placeholder="Student Course"
          value={studentCourse}
          onChange={handleChangeStudentCourse}
          fullWidth
          variant="outlined"
        >
          {/* Dropdown options */}
          <MenuItem disabled>Select Course</MenuItem>
          {courses &&
            courses.map((course) => (
              <MenuItem key={course._id} value={course.courseName}>
                {course.courseName}
              </MenuItem>
            ))}
        </TextField>

        {/* Displaying Course Fees */}
        <TextField
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="studentCourseFees"
          label="Student Course Fees *"
          name="studentCourseFees"
          placeholder="Student Course Fees"
          value={studentCourseFees}
          onChange={(e) => setStudentCourseFees(e.target.value)}
        />

        <TextField
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            "& input[type='number']": {
              MozAppearance: "textfield",
              "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                display: "none",
              },
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="studentFees"
          label="Student Fees"
          name="studentFees"
          type="number"
          placeholder="Student Fees"
          value={studentFees}
          onChange={handleStudentFeesChange}
        />
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          color="text.secondary"
          sx={{
            fontFamily: "Poppins, sans-serif",
            lineHeight: 1.8,
            letterSpacing: "1.8px",
          }}
        >
          Parents Information{" "}
        </Typography>

        <TextField
          sx={{
            mb: 3,
            mt: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="parentName"
          label="Parent Name"
          name="parentName"
          placeholder="Parent Name"
          value={parentName}
          onChange={(e) => setParentName(e.target.value)}
        />

        <TextField
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="parentEmail"
          label="Parent Email"
          name="parentEmail"
          placeholder="Parent Email"
          value={parentEmail}
          onChange={(e) => setParentEmail(e.target.value)}
        />

        <TextField
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="parentPhone"
          label="Parent Phone"
          name="parentPhone"
          placeholder="Parent Phone"
          value={parentPhone}
          onChange={(e) => setParentPhone(e.target.value)}
        />

        <TextField
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="teachersNotes"
          label="Teacher's Notes"
          name="teachersNotes"
          placeholder="Teacher's Notes"
          value={teachersNotes}
          onChange={(e) => setTeachersNotes(e.target.value)}
        />

        {loading && <p>Loading...</p>}
        {error && <p style={{ display: "none" }}>{error}</p>}
        <Button
          type="submit"
          disabled={loading}
          variant="contained"
          color="primary"
          className="courses_desc"
          sx={{
            mt: 3,
            borderRadius: "5px",
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            letterSpacing: ".1rem",
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Add Student"}
        </Button>
      </Container>
    </div>
  );
};

export default AddStudentBatch;
