import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Paper,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "../../axiosInstance";
import { message, Modal } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRole from "./EditRole";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Roles() {
  const [value, setValue] = useState(0);
  const [roleName, setRoleName] = useState("");
  const [roleValue, setRoleValue] = useState("");
  const [roles, setRoles] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Get Role Data
  const getAllRole = async () => {
    try {
      const response = await axios.get(`/api/v1/admin/admin-get-role`);
      if (response?.data?.success === true) {
        setRoles(response?.data?.role);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllRole();
    return () => clearInterval(intervalId);
  }, []);

  // Delete Role
  const { confirm } = Modal;
  const deleteRole = async (e, id) => {
    confirm({
      title: "Are you sure you want to delete this Role?",
      content:
        "This action cannot be undone and will permanently remove the role.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      okType: "danger",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/admin/admin-delete-role",
            {
              data: { id },
            }
          );
          if (data.success === true) {
            message.success(data?.message);
            getAllRole();
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Error deleting the role."
          );
        }
      },
      onCancel() {
        console.log("Delete role action canceled");
      },
    });
  };

  const columns = [
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteRole(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditRole
            refreshData={getAllRole}
            roles={value?.row?._id}
            roleName={value?.row?.role_name}
            roleValue={value?.row?.value}
          />
        </Box>
      ),
    },
    {
      field: "role_name",
      headerName: "Role Name",
      width: 180,
      editable: true,
    },
    {
      field: "value",
      headerName: "Role Value",
      width: 180,
      editable: true,
    },

    {
      field: "createdAt",
      headerName: "Role Date",
      width: 180,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY"),
    },
  ];

  // Add Roles
  const handleSubmit = async (e) => {
    e.preventDefault();
    const addRole = {
      role_name: roleName,
      value: roleValue,
      // vendorId: user?._id, // todo
    };

    try {
      const { data } = await axios.post(
        "/api/v1/admin/admin-add-role",
        addRole
      );
      if (data.success === true) {
        message.success(data.message);
        // Clear form fields
        setRoleName("");
        setRoleValue("");
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <PageTitle title="Admin Roles | Test Series" />
      <Box>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Admin Roles
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="#"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Super Admin
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>Roles</Typography>
        </Breadcrumbs>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Role List" {...TabPanel(0)} />
            <Tab label="Add Role" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/* Role List */}
        <CustomTabPanel value={value} index={0}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Role List
          </Typography>
          <Paper sx={{ bgcolor: "white" }}>
            <Box sx={{ height: 450, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row?._id}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                  // Style for header cells
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                    // fontSize: "13px",
                  },
                  // Style for checkbox in header
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                rows={roles}
                columns={columns}
                pageSize={3}
                rowsPerPageOptions={[3]}
                // checkboxSelection
              />
            </Box>
          </Paper>
        </CustomTabPanel>

        {/* Create a New Roles */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Create a new Role
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="role_name"
              label="Role Name *"
              name="role_name"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Role Name"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="value"
              label="Value *"
              name="value"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="For example role value user O admin 1 sub-admin 2 editor 3 & other roles 4+"
              value={roleValue}
              onChange={(e) => setRoleValue(e.target.value)}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="courses_desc"
              sx={{
                mt: 3,
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              Add Role
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
}
