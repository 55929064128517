import React, { useState } from "react";
import { Container, Box, TextField, Button, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { message, Modal, Select } from "antd";
import Papa from "papaparse";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LinearProgress from "@mui/material/LinearProgress";
import MicIcon from "@mui/icons-material/Mic";
import axios from "../../axiosInstance";
import DownloadCSV from "./DownloadCSV";

const { Option } = Select;

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();

const AddSubjectiveQuestion = ({ vendor_Id, institute_Id }) => {
  const [instituteId, setInstituteId] = useState(institute_Id);
  const [vendorId, setVendorId] = useState(vendor_Id);
  const [csvFile, setCsvFile] = useState(null);
  const [addQuestionName, setAddQuestionName] = useState("");
  const [referenceAnswer, setReferenceAnswer] = useState("");
  const [questionNumber, setQuestionNumber] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isRecognitionActive, setIsRecognitionActive] = useState(false);
  const [language, setLanguage] = useState("en-IN");

  // Extracting id from useParams and localStorage
  const { id: paramId } = useParams();
  const localStorageId = JSON.parse(localStorage.getItem("subjectiveId"));
  const examId = paramId || localStorageId;

  // Handle file selection and upload CSV
  const handleCsvFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setCsvFile(file);
    handleCsvUpload(file);
  };

  // Upload CSV File
  const handleCsvUpload = (file) => {
    setIsUploading(true);
    setUploadProgress(0);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (parsed) => {
        try {
          if (parsed.errors.length > 0) {
            setIsUploading(false);
            console.error("Parsing error:", parsed.errors);
            throw new Error("Parsing error occurred");
          }

          const { data } = parsed;
          if (data.length === 0) {
            message.warning("CSV file is empty");
            return;
          }

          const formattedQuestions = data.map((row) => ({
            name: row.Name,
            referenceAnswer: row["Reference Answer"],
            questionNumber: row["Question Number"],
            subjectiveExam: examId,
            instituteId: instituteId,
            vendorId: vendorId,
          }));

          if (formattedQuestions.length > 0) {
            const response = await axios.post(
              "/api/v1/question/subjective/admin-add-exam-to-csv-subjectiveQuestions",
              {
                subjectiveQuestions: formattedQuestions,
                subjectiveExam: examId,
              },
              {
                onUploadProgress: (progressEvent) => {
                  const progress =
                    (progressEvent.loaded / progressEvent.total) * 100;
                  setUploadProgress(progress);
                },
              }
            );

            if (response.data.success) {
              message.success(response.data.message);
              setAddQuestionName([]);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.warning("No valid data found in CSV");
          }
        } catch (error) {
          console.error("Error during CSV upload:", error);
          message.error("Error during CSV upload");
        } finally {
          setIsUploading(false);
        }
      },
    });
  };

  // Handle Submit Question API
  const handleSubmitQuestion = async (e) => {
    e.preventDefault();
    if (addQuestionName.length === 0) {
      message.error("Please enter Question Name");
      return;
    }

    const subjectiveQuestions = {
      name: addQuestionName,
      referenceAnswer,
      questionNumber,
      subjectiveExam: examId,
      instituteId: instituteId,
      vendorId: vendorId,
    };

    try {
      const data = await axios.post(
        "/api/v1/question/subjective/admin-add-exam-to-subjectiveQuestions",
        subjectiveQuestions
      );

      if (data.data.success === true) {
        message.success(data.data.message);
        setAddQuestionName("");
        setReferenceAnswer("");
        setQuestionNumber("");
      } else {
        message.error(data?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  // Function to handle speech input
  // const handleSpeechInput = (field, index) => {
  //   if (isRecognitionActive) {
  //     message.warning("Speech recognition is already in progress!");
  //     return;
  //   }

  //   Modal.confirm({
  //     title: "Start Speech Recognition?",
  //     content: "Are you sure you want to start speech recognition?",
  //     okText: "Yes",
  //     cancelText: "No",
  //     onOk: () => {
  //       recognition.lang = language;
  //       setIsRecognitionActive(true);
  //       recognition.start();

  //       recognition.onresult = (event) => {
  //         const transcript = event.results[0][0].transcript.trim();
  //         if (field === "addQuestionName") {
  //           setAddQuestionName((prev) => prev + " " + transcript);
  //         } else if (field === "referenceAnswer") {
  //           setReferenceAnswer((prev) => prev + " " + transcript);
  //         }
  //       };

  //       recognition.onend = () => {
  //         setIsRecognitionActive(false);
  //         message.success("Speech recognition ended.");
  //       };

  //       recognition.onerror = (event) => {
  //         setIsRecognitionActive(false);
  //         message.error("Speech recognition error: " + event.error);
  //       };
  //     },
  //     onCancel: () => {
  //       message.info("Speech recognition cancelled.");
  //     },
  //   });
  // };

  const handleSpeechInput = (field, index) => {
    if (isRecognitionActive) {
      message.warning("Speech recognition is already in progress!");
      return;
    }

    if (field === "addQuestionName") {
      Modal.confirm({
        title: "Start Speech Recognition?",
        content: "Are you sure you want to start speech recognition?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          recognition.lang = language;
          setIsRecognitionActive(true);
          recognition.start();

          recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript.trim();
            if (field === "addQuestionName") {
              setAddQuestionName((prev) => prev + " " + transcript);
            }
          };

          recognition.onend = () => {
            setIsRecognitionActive(false);
            message.success("Speech recognition ended.");
          };

          recognition.onerror = (event) => {
            setIsRecognitionActive(false);
            message.error("Speech recognition error: " + event.error);
          };
        },
        onCancel: () => {
          message.info("Speech recognition cancelled.");
        },
      });
    } else if (field === "referenceAnswer") {
      recognition.lang = language;
      setIsRecognitionActive(true);
      recognition.start();

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript.trim();
        setReferenceAnswer((prev) => prev + " " + transcript);
      };

      recognition.onend = () => {
        setIsRecognitionActive(false);
        message.success("Speech recognition ended.");
      };

      recognition.onerror = (event) => {
        setIsRecognitionActive(false);
        message.error("Speech recognition error: " + event.error);
      };
    }
  };

  return (
    <>
      <Box sx={{ pb: 1 }}>
        <Grid container spacing={2} alignItems="center">
          {/* CSV File Name */}
          <Grid item xs={12} sm="auto">
            <Box>{csvFile?.name}</Box>
          </Grid>

          {/* File Input */}
          <input
            accept=".csv"
            id="csv-file-input"
            type="file"
            style={{ display: "none" }}
            onChange={handleCsvFileChange}
          />

          {/* Upload Button */}
          <Grid item xs={12} sm="auto">
            <label htmlFor="csv-file-input">
              <Button
                variant="outlined"
                component="span"
                startIcon={<CloudUploadIcon />}
                color="primary"
                className="courses_desc"
                sx={{
                  mb: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: "5px",
                  textTransform: "none",
                  fontFamily: "Poppins, sans-serif",
                  letterSpacing: ".1rem",
                }}
              >
                Open and Upload CSV File
              </Button>
            </label>
          </Grid>

          {/* Upload Progress */}
          {isUploading && (
            <Grid item xs={12} sm="auto">
              <Box sx={{ width: "100%" }}>
                <LinearProgress variant="determinate" value={uploadProgress} />
              </Box>
            </Grid>
          )}

          {/* Download CSV */}
          <Grid item xs={12} sm="auto">
            <Box>
              <DownloadCSV />
            </Box>
          </Grid>

          {/* Language Selector */}
          <Grid item xs={12} sm="auto" mt={-2}>
            <Select
              value={language}
              onChange={(value) => setLanguage(value)}
              style={{ width: 200 }}
            >
              <Option value="en-IN">English (India)</Option>
              <Option value="hi-IN">Hindi (India)</Option>
            </Select>
          </Grid>
        </Grid>
      </Box>
      <Container
        onSubmit={handleSubmitQuestion}
        component="form"
        className="form_style border-style"
        maxWidth
      >
        {/* <ReactQuill
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="name"
          label="Question"
          name="name"
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          placeholder="Question"
          value={addQuestionName}
          onChange={setAddQuestionName}
        /> */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <ReactQuill
              id="name"
              label="Question"
              name="name"
              placeholder="Question"
              value={addQuestionName}
              onChange={setAddQuestionName}
            />
          </Box>
          <Button
            onClick={() => handleSpeechInput("addQuestionName")}
            startIcon={<MicIcon />}
            sx={{ ml: 2 }}
          >
            Speak
          </Button>
        </Box>

        <TextField
          sx={{
            mb: 3,
            mt: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            "& input[type='number']": {
              MozAppearance: "textfield",
              "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                display: "none",
              },
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="questionNumber"
          label="Question Marks"
          name="questionNumber"
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          type="Number"
          placeholder="Question Marks"
          value={questionNumber}
          onChange={(e) => setQuestionNumber(e.target.value)}
        />

        {/* <ReactQuill
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="name"
          label="Reference Answer"
          name="referenceAnswer"
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          placeholder="Reference Answer"
          value={referenceAnswer}
          onChange={setReferenceAnswer}
          multiline
        /> */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <ReactQuill
              id="referenceAnswer"
              label="Reference Answer"
              name="referenceAnswer"
              placeholder="Reference Answer"
              value={referenceAnswer}
              onChange={setReferenceAnswer}
            />
          </Box>
          <Button
            onClick={() => handleSpeechInput("referenceAnswer")}
            startIcon={<MicIcon />}
            sx={{ ml: 2 }}
          >
            Speak
          </Button>
        </Box>

        <Button
          variant="contained"
          type="submit"
          color="primary"
          className="courses_desc"
          sx={{
            mt: 3,
            borderRadius: "5px",
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            letterSpacing: ".1rem",
          }}
        >
          Add Subjective Question
        </Button>
      </Container>
    </>
  );
};

export default AddSubjectiveQuestion;
