import React, { useState } from "react";
import { Modal, message } from "antd";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Container, TextField, Button } from "@mui/material";
import axios from "../../axiosInstance";

const EditVendor = ({ vendorId, name, email, phone, refreshData }) => {
  const [vendor_name, setVendor_Name] = useState(name);
  const [vendor_email, setVendor_Email] = useState(email);
  const [vendor_phone, setVendor_Phone] = useState(phone);
  // const [refreshDataRole, setRefreshDataRole] = useState(refreshData)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    // window.location.reload();
  };

  // Edit Vendor
  const { confirm } = Modal;
  const handleSubmit = async (e) => {
    e.preventDefault();
    confirm({
      title: "Are you sure you want to edit this vendor's details?",
      content:
        "Please confirm if you want to proceed with editing the institute teacher.",
      okText: "Yes, Edit",
      cancelText: "No, Cancel",
      onOk: async () => {
        const addVendor = {
          id: vendorId,
          name: vendor_name,
          email: vendor_email,
          phone: vendor_phone,
        };

        try {
          const { data } = await axios.put(
            "/api/v1/common/admin-update-vendor",
            addVendor
          );
          if (data.success === true) {
            message.success(data.message);
            refreshData();
            setIsModalOpen(false); 
          } else {
            message.error(data?.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to update vendor details."
          );
        }
      },
      onCancel() {
        console.log("Edit action canceled");
      },
    });
  };

  return (
    <div>
      <>
        <IconButton aria-label="edit" onClick={showModal}>
          <EditIcon sx={{ color: "#1976d2" }} />
        </IconButton>
        <Modal
          title="Edit Institute Account"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={false}
        >
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendor_name"
              label="Full Name"
              name="vendor_name"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Full Name"
              value={vendor_name}
              onChange={(e) => setVendor_Name(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendor_email"
              label="Email"
              name="vendor_email"
              InputLabelProps={{
                shrink: true,
              }}
              type="email"
              placeholder="Email"
              value={vendor_email}
              onChange={(e) => setVendor_Email(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendor_phone"
              label="Phone Number"
              name="vendor_phone"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="Phone Number"
              value={vendor_phone}
              onChange={(e) => setVendor_Phone(e.target.value)}
            />

            <Button
              variant="contained"
              type="submit"
              color="primary"
              className="courses_desc"
              sx={{
                mt: 1,
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              Edit Institute Account
            </Button>
          </Container>
        </Modal>
      </>
    </div>
  );
};

export default EditVendor;
