import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import IconButton from "@mui/material/IconButton";
import { useSelector, useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import {
  Container,
  TextField,
  Button,
  MenuItem,
  Typography,
} from "@mui/material";
import { getAllCourse } from "../../redux/actions/userAction";
import axios from "../../axiosInstance";

const EditStudentBatch = ({
  studentId,
  refreshData,
  batchId,
  studentName,
  studentEmail,
  studentPhone,
  studentCourseName,
  courseFees,
  studentFees,
  teachersNotes,
  parentName,
  parentEmail,
  parentPhone,
  vendorId,
  instituteId,
  studentCourse_Id,
}) => {
  const dispatch = useDispatch();

  const { courses } = useSelector((state) => state.courseData);
  // const { user } = useSelector((state) => state.user);
  const [course, setCourse] = useState([]);
  const [student_Name, setStudent_Name] = useState(studentName);
  const [student_Email, setStudent_Email] = useState(studentEmail);
  const [student_Phone, setStudent_Phone] = useState(studentPhone);
  const [studentCourseId, setStudentCourseId] = useState(studentCourse_Id);
  const [studentCourse_Name, setStudentCourse_Name] =
    useState(studentCourseName);
  const [course_Fees, setCourse_Fees] = useState(courseFees);
  const [student_Fees, setStudent_Fees] = useState(studentFees);
  const [teachers_Notes, setTeachers_Notes] = useState(teachersNotes);
  const [parent_Name, setParent_Name] = useState(parentName);
  const [parent_Email, setParent_Email] = useState(parentEmail);
  const [parent_Phone, setParent_Phone] = useState(parentPhone);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Get API call in Redux for courses
  useEffect(() => {
    dispatch(getAllCourse());
  }, [dispatch]);

  useEffect(() => {
    if (courses) {
      setCourse(courses);
    }
  }, [courses]);

  // Handle student course selection
  const handleChangeStudentCourse = (event) => {
    const selectedCourseName = event.target.value;
    setStudentCourse_Name(selectedCourseName);
    const selectedCourse = courses.find(
      (course) => course.courseName === selectedCourseName
    );
    if (selectedCourse) {
      setCourse_Fees(selectedCourse.courseFees);
      setStudentCourseId(selectedCourse._id);
    }
  };

  // Edit Student Batch
  const { confirm } = Modal;
  const handleSubmit = async (e) => {
    e.preventDefault();
    confirm({
      title: "Are you sure you want to edit this student's details?",
      content: "This action will update the student's information.",
      okText: "Yes, Edit",
      cancelText: "No, Cancel",
      onOk: async () => {
        const payload = {
          studentId,
          studentName: student_Name,
          studentEmail: student_Email,
          studentPhone: student_Phone,
          parentName: parent_Name,
          parentEmail: parent_Email,
          parentPhone: parent_Phone,
          courseUpdates: [
            {
              studentCourseName: studentCourse_Name,
              courseFees: course_Fees,
              studentFees: student_Fees,
              teachersNotes: teachers_Notes,
              studentCourseId,
              instituteId,
              batchId,
              vendorId,
            },
          ],
        };

        try {
          const { data } = await axios.put(
            `/api/v1/batch/admin-update-institute-user-batch`,
            payload
          );
          if (data.success === true) {
            message.success(data.message);
            refreshData();
            setIsModalOpen(false);
          } else {
            message.error(data.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to update student"
          );
        }
      },
      onCancel() {
        console.log("Edit operation canceled");
      },
    });
  };

  const handleFeesChange = (e) => {
    const fees = e.target.value;
    // Allow empty input or a number less than or equal to course fees
    if (fees === "" || parseFloat(fees) <= course_Fees) {
      setStudent_Fees(fees);
    } else {
      message.warning("Student Fees cannot exceed Course Fees.");
    }
  };

  return (
    <div>
      <>
        <IconButton aria-label="edit" onClick={showModal}>
          <EditIcon sx={{ color: "#1976d2" }} />
        </IconButton>
        <Modal
          title="Edit Student"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={false}
        >
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="studentName"
              label="Full Name *"
              name="studentName"
              placeholder="Full Name"
              value={student_Name}
              onChange={(e) => setStudent_Name(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="studentEmail"
              label="E-mail *"
              name="studentEmail"
              placeholder="E-mail"
              value={student_Email}
              onChange={(e) => setStudent_Email(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="studentPhone"
              label="Phone Number *"
              name="studentPhone"
              placeholder="Phone Number"
              value={student_Phone}
              onChange={(e) => setStudent_Phone(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              select
              label="Student Course"
              placeholder="Student Course"
              value={studentCourse_Name}
              onChange={handleChangeStudentCourse}
              fullWidth
              variant="outlined"
              disabled
            >
              <MenuItem disabled>Select Course</MenuItem>
              {courses &&
                courses.map((course) => (
                  <MenuItem key={course._id} value={course.courseName}>
                    {course.courseName}
                  </MenuItem>
                ))}
            </TextField>

            {/* Display Course Fees */}
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="studentCourseFees"
              label="Student Course Fees *"
              name="studentCourseFees"
              type="Number"
              placeholder="Student Course Fees"
              value={course_Fees}
              onChange={(e) => setCourse_Fees(e.target.value)}
              disabled
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="studentFees"
              label="Student Fees *"
              name="studentFees"
              type="Number"
              placeholder="Student Fees"
              value={student_Fees}
              // onChange={(e) => setStudent_Fees(e.target.value)}
              onChange={handleFeesChange}
            />

            <Typography
              gutterBottom
              variant="h6"
              component="div"
              color="text.secondary"
              sx={{
                fontFamily: "Poppins, sans-serif",
                lineHeight: 1.8,
                letterSpacing: "1.8px",
              }}
            >
              Parents Information{" "}
            </Typography>
            <TextField
              sx={{
                mb: 3,
                mt: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="ParentsName"
              label="Parents Full Name *"
              name="ParentsName"
              placeholder="Parents Full Name"
              value={parent_Name}
              onChange={(e) => setParent_Name(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="ParentsEmail"
              label="Parents E-mail *"
              name="ParentsEmail"
              placeholder="Parents E-mail"
              value={parent_Email}
              onChange={(e) => setParent_Email(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="ParentsPhone"
              label="Parents Phone Number *"
              name="ParentsPhone"
              placeholder="Parents Phone Number"
              value={parent_Phone}
              onChange={(e) => setParent_Phone(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="teachersNotes"
              label="Teachers Notes"
              name="teachersNotes"
              placeholder="Teachers Notes"
              value={teachers_Notes}
              onChange={(e) => setTeachers_Notes(e.target.value)}
            />

            <Button
              variant="contained"
              type="submit"
              onClick={handleSubmit}
              color="primary"
              className="courses_desc"
              sx={{
                mt: 3,
                mb: 3,
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              Edit Student
            </Button>
          </Container>
        </Modal>
      </>
    </div>
  );
};

export default EditStudentBatch;
