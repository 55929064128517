import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  CardActions,
  CardContent,
  Badge,
  IconButton
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import axios from "../axiosInstance";
import Footer from "../Components/Footer";
import CategoryIcon from "@mui/icons-material/Category";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SchoolIcon from "@mui/icons-material/School";
import DescriptionIcon from "@mui/icons-material/Description";
import moment from "moment";
import { message } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import useInstituteUser from "../Components/GetInstituteUser"; 

const SubjectiveExam = () => {
  const navigate = useNavigate();
  const studentCourse = useInstituteUser();
  const [exam, setExam] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [search, setSearch] = useState([]);
  const [reportsData, setReportsData] = useState([]);
  const currentDate = moment().startOf("day");

  // Get All Exam Data
  const getAllExams = async () => {
    try {
      const response = await axios.post(
        `/api/v1/subjective/get-all-exams-subjective`, {
          studentCourse
        }
      );
      setExam(response?.data?.data);
      setSearch(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  // Get all reports by user
  const getReportData = async () => {
    try {
      const response = await axios.post(
        `/api/v1/reports/subjective/get-all-subjectiveReport-by-user`, {studentCourse}
      );
      setReportsData(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (studentCourse) {
      getAllExams(studentCourse); 
      getReportData(studentCourse);
    }
  }, [studentCourse]);

  // Search Exam Name and Category
    const searchAllExams = (e) => {
      const value = e.target.value.toLowerCase();
      const filteredExams = search.filter(
        (exam) =>
          exam.name.toLowerCase().includes(value) ||
          exam.category.toLowerCase().includes(value)
      );
      setExam(filteredExams);
    };

  return (
    <>
      <Navbar />
      <PageTitle title="Subjective Exam | Test Series" />
      <Container sx={{ pt: 3, mt: { xs: 12, sm: 12, md: 12, lg: 14 } }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search Exam Name and Category"
          onChange={searchAllExams}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon sx={{ color: "#1976d2" }} />
              </IconButton>
            ),
          }}
          sx={{
            mb: 3,
            bgcolor: "white",
            borderRadius: "20px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
              "&:hover fieldset": {
                border: "none",
              },
              "&.Mui-focused fieldset": {
                border: "none",
              },
            },
          }}
        />
      </Container>

      <Box sx={{ bgcolor: "#fafafa", minHeight: "100vh" }}>
        <Box sx={{
            pt: 5,
            pb: 5,
            minHeight: "83vh",
            mr: { md: 12, xs: 2 },
            ml: { md: 12, xs: 2 },
          }}>
            <Grid container spacing={5}>
              {exam && exam?.length > 0 ? (
                exam?.map((exam, index) =>
                  exam?.subjectiveQuestions?.length > 0 ? (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Badge
                        badgeContent="Upcoming"
                        color="primary"
                        invisible={moment(exam?.startDate).isSameOrBefore(
                          currentDate
                        )}
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Card
                           sx={{
                            transition: "0.3s !important",
                            "&:hover": { transform: "scale(1.05)",  },
                            borderRadius: "15px",
                            boxShadow:
                              "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                            padding: "16px",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <CardContent sx={{ flexGrow: 1 }}>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="div"
                              className="courses_title"
                              sx={{
                                letterSpacing: ".1rem",
                                fontSize: { xs: "18px", sm: "20px" },
                                marginBottom: "12px",
                                fontWeight: "bold",
                                fontFamily: "Poppins, sans-serif",
                                display: "-webkit-box",
                                overflow: "hidden",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                                color: "#2C3E50",
                                // color: "#1E88E5",
                              }}
                            >
                              <SchoolIcon
                                style={{
                                  marginRight: "8px",
                                  verticalAlign: "middle",
                                }}
                              />
                              {exam.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              className="courses_desc"
                              sx={{
                                mt: 1,
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                                overflow: "hidden",
                                fontSize: "15px",
                                letterSpacing: ".1rem",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              <CategoryIcon
                                fontSize="small"
                                sx={{ marginRight: "8px", verticalAlign: "middle", }}
                              />
                              <strong>Category</strong> : {exam.category}
                            </Typography>

                            <Typography
                              variant="body2"
                              color="text.secondary"
                              className="courses_desc"
                              sx={{
                                mt: 1,
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                                overflow: "hidden",
                                fontSize: "15px",
                                letterSpacing: ".1rem",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              <DescriptionIcon
                                fontSize="small"
                                style={{ marginRight: "8px", verticalAlign: "middle", }}
                              />
                              <strong> Exam Set</strong> : {exam.examSet}
                            </Typography>

                            <Typography
                              variant="body2"
                              color="text.secondary"
                              className="courses_desc"
                              sx={{
                                mt: 1,
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                                overflow: "hidden",
                                fontSize: "15px",
                                letterSpacing: ".1rem",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              <QuestionAnswerIcon
                                fontSize="small"
                                style={{ marginRight: "8px", verticalAlign: "middle", }}
                              />
                              <strong> Total Questions</strong> :{" "}
                              {exam.subjectiveQuestions.length}
                            </Typography>
                            {moment(exam.startDate).isSameOrBefore(
                              currentDate
                            ) ? (
                              <Typography
                              className="courses_desc"
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                mt: 1,
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "#34495E",
                                letterSpacing: ".1rem",
                                fontFamily: "Poppins, sans-serif",
                              }}
                              >
                                <DateRangeIcon
                                  fontSize="small"
                                  style={{ marginRight: "8px", verticalAlign: "middle", }}
                                />
                                <strong> End Date </strong> :{" "}
                                {new Date(exam?.endDate)
                                  .toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  })
                                  .replace(/ /g, "-")}
                                {/* {moment(exam.endDate).format("MMMM Do YYYY")} */}
                              </Typography>
                            ) : (
                              <Typography
                              className="courses_desc"
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                mt: 1,
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "#34495E",
                                letterSpacing: ".1rem",
                                fontFamily: "Poppins, sans-serif",
                              }}
                              >
                                <DateRangeIcon
                                  fontSize="small"
                                  style={{ marginRight: "8px",  verticalAlign: "middle" }}
                                />
                                <strong> Start Date </strong> :{" "}
                                {new Date(exam?.startDate)
                                  .toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  })
                                  .replace(/ /g, "-")}
                                {/* {moment(exam.startDate).format("MMMM Do YYYY")} */}
                              </Typography>
                            )}
                          </CardContent>
                          <CardActions sx={{ mb: 2 }}>
                            {moment(exam.startDate).isSameOrBefore(
                              currentDate
                            ) ? (
                              reportsData.some(
                                (report) =>
                                  report.examId === exam._id &&
                                  report.examSet === "Live Exam"
                              ) ? (
                                <Button
                                  variant="outlined"
                                  fullWidth
                                  disabled
                                  sx={{
                                    mt:1,
                                    borderRadius: "50px",
                                    textTransform: "none",
                                    fontFamily: "Poppins, sans-serif",
                                    letterSpacing: ".1rem",
                                  }}
                                >
                                  Exam Already Submitted
                                </Button>
                              ) : (
                                <Button
                                  variant="outlined"
                                  fullWidth
                                  onClick={() =>
                                    navigate(
                                      `/subjective/write-exam/${exam._id}`
                                    )
                                  }
                                  color="primary"
                                sx={{
                                  mt: 1,
                                  borderRadius: "50px",
                                  textTransform: "none",
                                  fontFamily: "Poppins, sans-serif",
                                  letterSpacing: ".1rem",
                                }}
                                >
                                  Start Exam
                                </Button>
                              )
                            ) : (
                              <Button
                              variant="outlined"
                              color="primary"
                              fullWidth
                              disabled
                              sx={{
                                mt:1,
                                borderRadius: "50px",
                                textTransform: "none",
                                fontFamily: "Poppins, sans-serif",
                                letterSpacing: ".1rem",
                              }}
                              >
                                Upcoming Exam
                              </Button>
                            )}
                          </CardActions>
                        </Card>
                      </Badge>
                    </Grid>
                  ) : null
                )
              ) : (
                <Box
                  style={{
                    color: "#878787",
                    margin: "30px 80px",
                    fontSize: 18,
                  }}
                >
                  No Available Subjective Exams!!
                </Box>
              )}
            </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default SubjectiveExam;
