import React, { useEffect, useState } from "react";
import { Typography, Card, CardContent, Box, Avatar } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "../axiosInstance";
import { message } from "antd";

const InstituteTeacher = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const instituteInfo = JSON.parse(localStorage.getItem("instituteInfo"));
  const instituteId = instituteInfo[0].instituteId;

  // // Fetch institute information
  const getInstituteInformation = async () => {
    try {
      const response = await axios.post(
        `/api/v1/vendor/get-institute-all-teachers`,
        {
          instituteId,
        }
      );
      const data = response?.data?.instituteTeacher;

      // Filter the data to include only those with teacherPublic set to 'yes'
      const filteredData = Array.isArray(data)
        ? data.filter((teacher) => teacher.teacherPublic === "Yes")
        : [];

      setTeamMembers(filteredData);
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Failed to fetch teachers"
      );
    }
  };

  useEffect(() => {
    if (instituteId) {
      getInstituteInformation();
    }
  }, []);

  const settings = {
    // dots: true,
    infinite: teamMembers.length > 1,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    centerPadding: "30px",
    autoplaySpeed: 3000,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // dots: true,
        },
      },
    ],
  };

  return (
    <div>
      {teamMembers?.length > 0 ? (
        <Box
          className="our-awesome-team"
          sx={{ mt: 0, mb: 4, background: "#f7fafd" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // mt: 4,
              // mb: 4,
            }}
          >
            <Typography
              style={{
                fontWeight: "600px",
                fontSize: "22px",
                textAlign: "center",
              }}
              sx={{ color: "black", pb: 3, mt: 3 }}
            >
              <b>Institute All Teachers</b>
            </Typography>
          </Box>

          <Slider {...settings}>
            {teamMembers?.map((member, index) => (
              <Box key={index} className="team-card-wrapper">
                <Card
                  className="team-card"
                  sx={{
                    "&:hover": {
                      opacity: "1",
                      "& .MuiAvatar-root": {
                        border: "3px solid #70d338",
                        opacity: "1",
                        transition:
                          "border 0.3s ease-in-out, opacity 0.3s ease-in-out",
                      },
                    },

                    "&:hover .team-member-info": {
                      borderRadius: "3px",
                      transition: "border-radius 0.5s ease-in-out",
                    },
                  }}
                >
                  <Box className="image-container">
                    <Avatar
                      src={member?.url || member?.teacherName}
                      sx={{
                        width: 130,
                        height: 130,
                        border: "3px solid #cdf1d8",
                        cursor: "pointer",
                        transition: "border 0.5s ease, opacity 0.5s ease",
                      }}
                      alt={member?.teacherName}
                    />
                  </Box>

                  <CardContent>
                    <Box
                      className="team-member-info"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        borderRadius: "40px",
                        height: "50px",
                        transition: "none",
                        background:
                          "linear-gradient(to right, #5f2c82, #49a09d)",
                        color: "#FFF",
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          letterSpacing: "1px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <b>{member?.teacherExperience} Years Exp.</b>
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: "5px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          mt: 2,
                          fontSize: "18px",
                          letterSpacing: "1px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <b>{member?.teacherName}</b>
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          mt: 3,
                          letterSpacing: "1px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {/* <b> Physics </b> */}
                        <b>{member?.teacherSubject}</b>
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          mt: 3,
                          letterSpacing: "1px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {/* <b> Physics </b> */}
                        <b>{member?.teacherDescription}</b>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Slider>
        </Box>
      ) : null}
    </div>
  );
};

export default InstituteTeacher;
