import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Select,
  CircularProgress,
  MenuItem,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../axiosInstance";
import { message } from "antd";
import moment from "moment";

const ShareReport = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const currentDate = new Date().toLocaleDateString();
  const [instituteInfo, setInstituteInfo] = useState([]);
  const [instituteUserInfo, setInstituteUserInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  const [reportData, setReportData] = useState({
    ...state?.report,
    academicSession: "2024-25",
    date: currentDate,
    courseName: "",
    classSignature: "",
    principalSignature: "",
    addComments: "",
  });

  useEffect(() => {
    const fetchCourseName = async () => {
      if (reportData?.instituteCourseId) {
        // Check if the instituteCourseId matches the specific ID
        if (reportData.instituteCourseId === "81af3e57846a42aab058dc4d") {
          setReportData((prev) => ({
            ...prev,
            courseName: "General",
          }));
        } else {
          try {
            const response = await axios.post(
              "/api/v1/vendor/admin/get-course-details-by-id-institute",
              { instituteCourseId: reportData?.instituteCourseId }
            );

            setReportData((prev) => ({
              ...prev,
              courseName: response.data?.data?.courseName || "",
            }));
          } catch (error) {
            console.error("Error fetching course name:", error);
          }
        }
      }
    };

    fetchCourseName();
  }, [reportData?.instituteCourseId]);
 
  const instituteId = reportData?.instituteId;
  
  useEffect(() => {
    const getAllInstituteInfo = async () => {
      try {
        const response = await axios.post(
          `/api/v1/reports/get-institute-info-by-report-instituteId`
        , {instituteId});
        if (response?.data?.success === true) {
          setInstituteInfo(response?.data?.data);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    };
    getAllInstituteInfo();
  }, []);

  useEffect(() => {
    const fetchUserInfoByUserId = async () => {
      if (reportData?.userId) {
        try {
          const response = await axios.post(
            "/api/v1/batch/admin-get-institute-user-info-by-id-batch",
            { userId: reportData?.userId }
          );
          if (response?.data?.success === true) {
            setInstituteUserInfo(response?.data?.user);
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      }
    };
    fetchUserInfoByUserId();
  }, [reportData?.userId]);

  useEffect(() => {
    // Save reportData in session storage to retain data after preview
    sessionStorage.setItem("reportData", JSON.stringify(reportData));
  }, [reportData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReportData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNestedChange = (name, value) => {
    setReportData((prev) => ({
      ...prev,
      result: {
        ...prev.result,
        [name]: value,
      },
    }));
  };

  const totalMarks = reportData?.result?.totalMarks;
  const marksObtained = reportData?.result?.obtainedMarks;
  const percentage = (marksObtained / totalMarks) * 100;

  // Function to determine the total grade based on the percentage
  const getTotalGrade = (percentage) => {
    if (percentage >= 90) return "A+";
    if (percentage >= 80) return "A";
    if (percentage >= 70) return "B+";
    if (percentage >= 60) return "B";
    return "C";
  };

  // Function to determine the rank based on the grade (customize as needed)
  const rank = percentage >= 85 ? "1st" : percentage >= 75 ? "2nd" : "3rd";

  const reportCurrentDate = moment().format('MMMM Do YYYY')
  // Share Student Reports
  const handleShareReport = async () => {
    setLoading(true);
    const shareReportData = {
      logo: instituteInfo[0]?.instituteLogo,
      instituteName: instituteInfo[0]?.instituteName,
      instituteAddress: instituteInfo[0]?.instituteAddress,
      institutePhone: instituteInfo[0]?.institutePhone,
      instituteEmail: instituteInfo[0]?.instituteEmail,
      courseClass: reportData.courseClass,
      courseName: reportData?.courseName,
      academicSession: reportData?.academicSession,
      userName: reportData?.userName,
      userEmail: reportData?.userEmail,
      parentName: instituteUserInfo?.parentName,
      parentEmail: instituteUserInfo?.parentEmail,
      subject: reportData.examName,
      totalMarks: reportData?.result?.totalMarks,
      marksObtained,
      percentage,
      rank,
      grade: getTotalGrade(percentage),
      result: reportData?.result?.verdict,
      comments: reportData?.addComments,
      reportDate: reportCurrentDate,
      classTeacher: reportData?.classSignature,
      principalSignature: reportData?.principalSignature,
    };
    try {
      const response = await axios.post(
        "/api/v1/reports/admin-share-reports",
        shareReportData
      );
      message.success(response.data.message || "Report shared successfully!");
      sessionStorage.removeItem("reportData");
      navigate("/admin/reports");
    } catch (error) {
      message.error("Failed to share report.");
    } finally {
      setLoading(false);
    }
  };

  const handleShowPreview = () => {
    sessionStorage.setItem("reportData", JSON.stringify(reportData));
    navigate("/admin/share/report/preview", {
      state: { reportData, instituteInfo, instituteUserInfo },
    });
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" sx={{ mb: 3 }} align="center" gutterBottom>
        Edit Report Details
      </Typography>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          mb: 5,
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        <MUILink
          component={Link}
          to="/"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Dashboard
        </MUILink>
        <MUILink
          component={Link}
          to="/admin/reports"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Reports
        </MUILink>
        <Typography sx={{ color: "primary.main" }}>Edit Report</Typography>
      </Breadcrumbs>
      <Paper sx={{ padding: 3, margin: "0 auto" }}>
        <TextField
          fullWidth
          label="Exam Name"
          name="examName"
          value={reportData.examName || ""}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Student Email"
          name="userEmail"
          value={reportData.userEmail || ""}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Total Marks"
          name="totalMarks"
          value={reportData.totalMarks || ""}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Obtained Marks"
          name="obtainedMarks"
          value={reportData.result?.obtainedMarks?.toFixed(2) || ""} 
          onChange={(e) => handleNestedChange("obtainedMarks", e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Verdict"
          name="verdict"
          value={reportData.result?.verdict || ""}
          onChange={(e) => handleNestedChange("verdict", e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Course / Class Name"
          name="courseName"
          value={reportData.courseName}
          onChange={handleChange}
          margin="normal"
          disabled
        />
        {/* Dropdown for Course/Class */}
        <Select
          fullWidth
          name="courseClass"
          value={reportData.courseClass || ""}
          onChange={handleChange}
          displayEmpty
          sx={{ mt: 2, mb: 2 }}
          required
        >
          {/* Example options, replace with actual course/class options */}
          <MenuItem value="">Select Name</MenuItem>
          <MenuItem value="Course">Course</MenuItem>
          <MenuItem value="Class">Class</MenuItem>
        </Select>
        <TextField
          fullWidth
          label="Academic Session"
          name="academicSession"
          value={reportData.academicSession}
          onChange={handleChange}
          margin="normal"
        />
        {/* <TextField
          fullWidth
          label="Current Date"
          name="date"
          value={reportData.date}
          onChange={handleChange}
          margin="normal"
        /> */}
        <TextField
          fullWidth
          label="Class Teacher Name"
          name="classSignature"
          value={reportData.classSignature}
          onChange={handleChange}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Principal Name"
          name="principalSignature"
          value={reportData.principalSignature}
          onChange={handleChange}
          margin="normal"
          required
        />

        <TextField
          fullWidth
          label="Add Comments"
          name="addComments"
          placeholder="Great performance! Keep up the good work!"
          value={reportData.addComments}
          onChange={handleChange}
          margin="normal"
          // required
        />

        <Button
          disabled={loading}
          type="submit"
          onClick={handleShareReport}
          variant="contained"
          color="primary"
          className="courses_desc"
          sx={{
            mt: 3,
            borderRadius: "5px",
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            letterSpacing: ".1rem",
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Share Report"}
        </Button>
        <Button
          onClick={handleShowPreview}
          variant="outlined"
          sx={{ ml: 2, mt: 3 }}
        >
          Show Preview
        </Button>
      </Paper>
    </Box>
  );
};

export default ShareReport;
