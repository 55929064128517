import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  MenuItem,
  IconButton,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { message, Modal } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import axios from "../../axiosInstance";
import PageTitle from "../../Components/PageTitle/PageTitle";
import EditBatch from "./EditBatch";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddBatch() {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  const [batchName, setBatchName] = useState("");
  const [batchDuration, setBatchDuration] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(startDate);
  const [searchQuery, setSearchQuery] = useState("");
  const [batchClassMode, setBatchClassMode] = useState("");
  const [batches, setBatches] = useState([]);
  const [batchStudentLength, setBatchStudentLength] = useState([]);
  const fetchedBatches = useRef(new Set());

  const handleChangeClassMode = (event) => {
    setBatchClassMode(event.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const batchStartDate = moment(startDate).format("MM/DD/YYYY");
  const batchEndDate = moment(endDate).format("MM/DD/YYYY");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Get All Batch Data
  const getAllBatch = async () => {
    try {
      const response = await axios.get(`/api/v1/batch/admin-get-batch`);
      if (response?.data?.success === true) {
        setBatches(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllBatch();
    return () => clearInterval(intervalId);
  }, []);

  // Delete Batch
  const { confirm } = Modal;
  const deleteBatch = async (e, id) => {
    confirm({
      title: "Are you sure you want to delete this Batch?",
      content: "Once deleted, this action cannot be undone.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      okType: "danger",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/batch/admin-delete-batch",
            {
              data: { id },
            }
          );
          if (data.success === true) {
            message.success(data?.message);
            getAllBatch();
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Error deleting the batch."
          );
        }
      },
      onCancel() {
        console.log("Batch deletion canceled");
      },
    });
  };

  // Filter Batches (Search Batch Name)
  const filteredBatches = batches.filter((batch) =>
    batch.batchName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // Enroll Batch
const enrollBatch = (e, batchId, batchName, instituteId) => {
  e.preventDefault();
  confirm({
    title: `Are you sure you want to enroll in Batch "${batchName}"?`,
    content: "You will be enrolled in the selected batch once you confirm.",
    okText: "Yes, Enroll",
    cancelText: "No, Cancel",
    onOk: () => {
      navigate(`/admin/student/batch`, {
        state: { batchId, batchName, instituteId },
      });
    },
    onCancel() {
      console.log("Enrollment canceled");
    },
  });
};


  // Get All Batch Student Length
  const getAllBatchStudent = async (batchId, instituteId) => {
    try {
      const response = await axios.post(
        `/api/v1/batch/admin-get-institute-user-batch`,
        {
          batchId,
          instituteId,
        }
      );
      if (response?.data?.success === true) {
        setBatchStudentLength((prevLengths) => ({
          ...prevLengths,
          [batchId]: response?.data?.data?.length,
        }));
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      // message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    filteredBatches.forEach((batch) => {
      if (!fetchedBatches.current.has(batch._id)) {
        getAllBatchStudent(batch._id, batch.instituteId);
        fetchedBatches.current.add(batch._id);
      }
    });
  }, [filteredBatches]);

  // Add Batch
  const handleSubmit = async (e) => {
    e.preventDefault();
    const addBatch = {
      batchName,
      batchDuration,
      batchClassMode,
      batchStartDate,
      batchEndDate,
      vendorId: user?._id,
      instituteId: user?.instituteId,
    };
    try {
      const { data } = await axios.post(
        "/api/v1/batch/admin-add-batch",
        addBatch
      );
      if (data.success === true) {
        message.success(data.message);
        // Clear form fields
        setBatchName("");
        setBatchDuration("");
        setBatchClassMode("");
        setStartDate(null);
        setEndDate(null);
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <PageTitle title="Admin Add Batches | Test Series" />
      <Box>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          All Batch List
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>Batch</Typography>
        </Breadcrumbs>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Batch List" {...TabPanel(0)} />
            <Tab label="Add Batch" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/* Student Batch List */}
        <CustomTabPanel value={value} index={0}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            All Batch List
          </Typography>

          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search Batch Name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon sx={{ color: "#1976d2" }} />
                </IconButton>
              ),
            }}
            sx={{
              mb: 3,
              bgcolor: "white",
              borderRadius: "20px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
              },
            }}
          />
          <Grid container spacing={3} sx={{ pb: 2, pt: 2 }}>
            {filteredBatches.map((batch, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  sx={{
                    transition: "0.3s !important",
                    "&:hover": { transform: "scale(1.05)" },
                    borderRadius: "15px",
                    boxShadow:
                      "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                    // padding: "16px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      className="courses_title"
                      sx={{
                        letterSpacing: ".1rem",
                        fontSize: { xs: "18px", sm: "20px" },
                        marginBottom: "12px",
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                        color: "#2C3E50",
                        // color: "#1E88E5",
                      }}
                    >
                      {batch.batchName}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className="courses_desc"
                      sx={{
                        mt: 1,
                        fontSize: "15px",
                        letterSpacing: ".1rem",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <strong style={{ color: "#000" }}>Mode</strong>:{" "}
                      {batch.batchClassMode}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className="courses_desc"
                      sx={{
                        mt: 1,
                        fontSize: "15px",
                        letterSpacing: ".1rem",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <strong style={{ color: "#000" }}>Duration</strong>:{" "}
                      {batch.batchDuration}
                    </Typography>

                    {user && user?.role === 1 && (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        className="courses_desc"
                        sx={{
                          mt: 1,
                          fontSize: "15px",
                          letterSpacing: ".1rem",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <strong style={{ color: "#000" }}>Institute Id</strong>:{" "}
                        <strong style={{ color: "#000" }}>
                          {batch.instituteId}
                        </strong>{" "}
                      </Typography>
                    )}
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className="courses_desc"
                      sx={{
                        mt: 1,
                        fontSize: "15px",
                        letterSpacing: ".1rem",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <strong style={{ color: "#000" }}>Start Date</strong>:{" "}
                      {batch.batchStartDate}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className="courses_desc"
                      sx={{
                        mt: 1,
                        fontSize: "15px",
                        letterSpacing: ".1rem",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <strong style={{ color: "#000" }}>End Date</strong>:{" "}
                      {batch.batchEndDate}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className="batchTotalStudent"
                      sx={{
                        mt: 1,
                        fontSize: "15px",
                        letterSpacing: ".1rem",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <strong style={{ color: "#000" }}>
                        {" "}
                        Batch Total Student
                      </strong>
                      : {batchStudentLength[batch._id] || 0}
                    </Typography>

                    <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                      {" "}
                      <Button
                        variant="outlined"
                        className="courses_desc"
                        color="error"
                        sx={{
                          mt: 1,
                          borderRadius: "50px",
                          textTransform: "none",
                          fontFamily: "Poppins, sans-serif",
                          letterSpacing: ".1rem",
                        }}
                        onClick={(e) => deleteBatch(e, batch?._id)}
                      >
                        Delete
                      </Button>
                      <EditBatch Batch={batch} refreshData={getAllBatch} />
                    </Box>
                  </CardContent>
                  <CardActions sx={{ mb: 2 }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className="courses_desc"
                      fullWidth
                      sx={{
                        mt: 1,
                        borderRadius: "50px",
                        textTransform: "none",
                        fontFamily: "Poppins, sans-serif",
                        letterSpacing: ".1rem",
                      }}
                      onClick={(e) =>
                        enrollBatch(
                          e,
                          batch._id,
                          batch.batchName,
                          batch.instituteId
                        )
                      }
                    >
                      Enroll Student
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CustomTabPanel>

        {/* Add student batch */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Create a new Batch
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="name"
              label="Batch Name *"
              name="name"
              placeholder="Batch Name"
              value={batchName}
              onChange={(e) => setBatchName(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="duration"
              label="Batch Duration *"
              name="duration"
              placeholder="Batch Duration"
              value={batchDuration}
              onChange={(e) => setBatchDuration(e.target.value)}
            />

            <TextField
              select
              label="Class Mode"
              placeholder="Class Mode"
              value={batchClassMode}
              onChange={handleChangeClassMode}
              fullWidth
              variant="outlined"
            >
              {/* Dropdown options */}
              <MenuItem disabled>Select Class Mode</MenuItem>
              <MenuItem value="online">Online</MenuItem>
              <MenuItem value="offline">Offline</MenuItem>
            </TextField>

            <Box
              style={{ display: "flex", marginTop: "30px" }}
              className="Date"
            >
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Batch Start Date"
                  selected={startDate}
                  value={startDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
              <Box>
                <DatePicker
                  required
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Batch End Date"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  value={endDate}
                  // eslint-disable-next-line no-mixed-operators
                  minDate={(new Date() && startDate) || new Date()}
                  maxDate={endDate}
                />
              </Box>
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="courses_desc"
              sx={{
                mt: 3,
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              Add Batch
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
}
