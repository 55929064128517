/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { message } from "antd";
import {
  Box,
  Container,
  Grid,
  CircularProgress,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import useInstituteUser from "../Components/GetInstituteUser"; 
import axios from "../axiosInstance";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import Footer from "../Components/Footer";


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const StartVideo = () => {
  const params = useParams();
  const {id} = params;
  const studentCourse = useInstituteUser(); 
  const [video, setVideo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Get by Id Video Course details 
  const getAllVideos = async () => {
    try {
      const response = await axios.post(`/api/v1/admin/get-video-details`, {id, studentCourse});
      setVideo(response?.data?.videos[0]);
    } catch (error) {
      setError(
        error.response?.data?.message ||
          "An error occurred while fetching the video."
      );
      message.error(
        error.response?.data?.message ||
          "An error occurred while fetching the video."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (studentCourse) {
      getAllVideos(studentCourse); 
    }
  }, [studentCourse]);

  const isYouTubeUrl = (url) => {
    return /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/.test(url);
  };

  const getYouTubeEmbedUrl = (url) => {
    const videoIdMatch = url.match(
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    );
    return videoIdMatch ? `https://www.youtube.com/embed/${videoIdMatch[1]}` : null;
  };

  return (
    <>
      <Navbar />
      <PageTitle title="Start Video | Test Series" />

      <Box sx={{ bgcolor: "#fafafa", minHeight: "100vh", mt:{ xs: 10, sm:10, md: 10, lg: 10 } }}>
        <Container sx={{ pt: 5, pb: 5, minHeight: "83vh", width: "100%" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={12} md={12}>
                {loading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                  >
                    <CircularProgress />
                  </Box>
                ) : error ? (
                  <Typography color="error" variant="h6" align="center">
                    {error}
                  </Typography>
                ) : (
                  <>
                    {isYouTubeUrl(video?.url) ? (
                      <iframe
                        width="100%"
                        height="500px"
                        src={getYouTubeEmbedUrl(video?.url)}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{ border: "1px solid #878b91" }}
                      ></iframe>
                    ) : (
                      <video
                        controls
                        controlsList="nodownload"
                        width="100%"
                        height="500px"
                        style={{ border: "1px solid #878b91" }}
                      >
                        <source src={video?.url} />
                        Your browser does not support the video tag.
                      </video>
                    )}

                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab label="Overview" />
                      </Tabs>
                    </Box>

                    <TabPanel value={value} index={0}>
                      <Container sx={{ pt: 3 }}>
                        <Typography variant="h5">
                          <b>{video?.title}</b>
                        </Typography>
                        <Typography paragraph sx={{ mt: 3 }}>
                          {/* {video?.description} */}
                         <span dangerouslySetInnerHTML={{
                            __html:video.description,
                          }}></span>
                        </Typography>
                      </Container>
                    </TabPanel>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default StartVideo;
