import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Box,
  ListItemText,
} from "@mui/material";
import { message, Modal } from "antd";
import Navbar from "../Components/Navbar";
import Instructions from "./SubjectiveInstructions";
import axios from "../axiosInstance";
import PageTitle from "../Components/PageTitle/PageTitle";
import useInstituteUser from "../Components/GetInstituteUser";
import "./WriteExam.css";

const SubjectiveWriteExam = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [examData, setExamData] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [result, setResult] = useState({});
  const [view, setView] = useState("instructions");
  const [timeLeft, setTimeLeft] = useState({ minutes: 0, seconds: 0 });
  const [timeUp, setTimeUp] = useState(false);
  const studentCourse = useInstituteUser();
  const [intervalId, setIntervalId] = useState(null);
  const { user } = useSelector((state) => state.user);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Fetch exam data
  const getExamData = async () => {
    try {
      const response = await axios.get(
        `/api/v1/subjective/get-exam-subjective-by-id/${params.id}`
      );
      setQuestions(response?.data?.data?.subjectiveQuestions);
      setExamData(response?.data?.data);
      const savedTime = JSON.parse(localStorage.getItem(`timer-${params.id}`));
      if (savedTime) {
        setTimeLeft(savedTime);
      } else {
        setTimeLeft({
          minutes: Math.floor(response?.data?.data?.duration),
          seconds: 0,
        });
      }
    } catch (error) {
      message.error(error.response?.data?.message);
    }
  };

  // Start Timer Function
  const startTimer = () => {
    let totalSeconds = timeLeft.minutes * 60 + timeLeft.seconds;
    const intervalId = setInterval(() => {
      if (totalSeconds > 0) {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        if (hours > 0) {
          setTimeLeft({ hours, minutes, seconds });
        } else {
          setTimeLeft({ minutes, seconds });
        }

        localStorage.setItem(
          `timer-${params.id}`,
          JSON.stringify({ hours, minutes, seconds })
        );
        totalSeconds--;
      } else {
        clearInterval(intervalId);
        setTimeUp(true);
      }
    }, 1000);

    setIntervalId(intervalId);
  };

  useEffect(() => {
    if (timeUp && view === "questions") {
      clearInterval(intervalId);
      localStorage.removeItem(`timer-${params.id}`);
    }
  }, [timeUp]);

  useEffect(() => {
    if (params.id) {
      getExamData();
    }
  }, [params.id]);

  // Store data in local storage
  useEffect(() => {
    return () => {
      localStorage.setItem("answers", JSON.stringify(answers));
      localStorage.setItem("selectedQuestionIndex", selectedQuestionIndex);
      localStorage.setItem("timeLeft", JSON.stringify(timeLeft));
    };
  }, [answers, selectedQuestionIndex, timeLeft]);

  // Restore data from local storage
  useEffect(() => {
    const storedAnswers = JSON.parse(localStorage.getItem("answers"));
    const storedSelectedQuestionIndex = localStorage.getItem(
      "selectedQuestionIndex"
    );
    const storedTimeLeft = JSON.parse(localStorage.getItem("timeLeft"));
    if (
      storedAnswers &&
      storedSelectedQuestionIndex !== null &&
      storedTimeLeft
    ) {
      setAnswers(storedAnswers);
      setSelectedQuestionIndex(parseInt(storedSelectedQuestionIndex));
      setTimeLeft(storedTimeLeft);
    }
  }, []);

  useEffect(() => {
    return () => {
      // Clear local storage when the exam is submitted
      localStorage.removeItem("answers");
      localStorage.removeItem("selectedQuestionIndex");
      localStorage.removeItem("timeLeft");
      localStorage.removeItem(`timer-${params.id}`);
      exitFullScreen();
    };
  }, [params.id]);

  // Full screen utility
  const requestFullScreen = (element) => {
    if (element?.requestFullscreen) {
      element?.requestFullscreen();
    } else if (element?.mozRequestFullScreen) {
      element?.mozRequestFullScreen();
    } else if (element?.webkitRequestFullscreen) {
      element?.webkitRequestFullscreen();
    } else if (element?.msRequestFullscreen) {
      element?.msRequestFullscreen();
    }
  };

  // Handle full screen function
  const handleStartExam = () => {
    setView("questions");
    requestFullScreen(document?.documentElement);
    startTimer();
  };

  // Exit Full screen function
  const exitFullScreen = () => {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  // Handle Question Click Function
  const handleQuestionClick = (index) => {
    setSelectedQuestionIndex(index);
    setView("questions");
  };

  // Handle answer change
  const handleAnswerChange = (index, value) => {
    const updatedAnswers = { ...answers, [index]: value };
    setAnswers(updatedAnswers);
  };

  // Handle submit
  const handleSubmit = async () => {
    clearInterval(intervalId);
    setTimeUp(true);
    localStorage.removeItem(`timer-${params.id}`);
    setIsModalVisible(false);
    // Prepare data for submission
    const TempResult = {
      examId: params.id,
      exam: params?.id,
      examName: examData.name,
      totalMarks: examData.totalMarks,
      passingMarks: examData?.passingMarks,
      category: examData?.category,
      negativeMarking: examData?.negativeMarking,
      examSet: examData?.examSet,
      user: user?._id,
      userId: user?._id,
      userName: user?.studentName,
      userEmail: user?.studentEmail,
      instituteId: studentCourse[0]?.instituteId,
      vendorId: user?._id,
      instituteCourseId: examData?.instituteCourseId,
      answersData: questions.map((question, index) => ({
        question: question.name,
        answer: answers[index],
        referenceAnswer: question.referenceAnswer,
        questionNumber: question.questionNumber,
      })),
    };
    try {
      const response = await axios.post(
        "/api/v1/reports/subjective/add-subjectiveReport",
        TempResult
      );
      setAnswers("");
      message.success(response.data.message);
      setTimeout(() => {
        navigate("/subjective/exams");
      }, 2000);
    } catch (error) {
      message.error("Submission failed. Please try again.");
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    handleSubmit();
  };

  return (
    <>
      <Navbar />
      <PageTitle title="Subjective Write Exam | Test Series" />
      {examData && (
        <Container
          className="mt-2"
          sx={{ mt: { xs: 12, sm: 12, md: 12, lg: 12 } }}
        >
          <div className="divider" style={{ marginTop: "10%" }}></div>
          <h2 className="text-center">{examData?.name}</h2>
          <div className="divider"></div>

          {view === "instructions" && (
            <Instructions
              examData={examData}
              setView={handleStartExam}
              question={questions?.length}
            />
          )}

          {view === "questions" && (
            <div className="flex flex-col gap-2" style={{ marginTop: "3%" }}>
              <div className="exam-info-container">
                {examData?.negativeMarking !== null && (
                  <span className="info-item">
                    Negative Marks: -
                    <span className="info-badge negative">
                      {examData.negativeMarking}
                    </span>
                  </span>
                )}
                <span className="info-item">
                  Answered:
                  <span className="info-badge answered">
                    {Object.keys(answers).length}
                  </span>
                </span>
                <span className="info-item">
                  Time:
                  <span className="time-display">
                    {timeLeft.hours
                      ? `${timeLeft.hours.toString().padStart(2, "0")}:`
                      : ""}
                    {timeLeft.minutes.toString().padStart(2, "0")}:
                    {timeLeft.seconds.toString().padStart(2, "0")}
                  </span>
                </span>
              </div>

              <Box className="flex justify-between" sx={{ mt: 5 }}>
                {/* <h3 style={{ whiteSpace: "pre-line" }}>
                  {selectedQuestionIndex + 1} :{" "}
                  {questions[selectedQuestionIndex]?.name}
                </h3> */}
                <p
                  style={{ whiteSpace: "pre-line" }}
                  className="flex justify-between"
                >
                  {selectedQuestionIndex + 1} : {""}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: questions[selectedQuestionIndex]?.name,
                    }}
                  ></span>
                </p>
              </Box>

              <div className="flex flex-col gap-2">
                <TextField
                  fullWidth
                  label="Answer"
                  value={answers[selectedQuestionIndex] || ""}
                  onChange={(e) =>
                    handleAnswerChange(selectedQuestionIndex, e.target.value)
                  }
                  margin="normal"
                  multiline
                />
                {/* <ReactQuill
                  theme="snow"
                  value={answers[selectedQuestionIndex] || ""}
                  onChange={(value) =>
                    handleAnswerChange(selectedQuestionIndex, value)
                  }
                  style={{ marginTop: "16px", marginBottom: "16px" }}
                /> */}
              </div>

              <div
                className="flex gap-2"
                style={{ marginBottom: "30px", marginTop: "20px" }}
              >
                {selectedQuestionIndex > 0 && (
                  <Button
                    variant="outlined"
                    className="primary-outlined-btn"
                    onClick={() => {
                      setSelectedQuestionIndex(selectedQuestionIndex - 1);
                    }}
                  >
                    Previous
                  </Button>
                )}

                {selectedQuestionIndex < questions.length - 1 && (
                  <Button
                    variant="contained"
                    className="primary-contained-btn"
                    style={{ marginLeft: "12px" }}
                    onClick={() => {
                      setSelectedQuestionIndex(selectedQuestionIndex + 1);
                    }}
                  >
                    Next
                  </Button>
                )}

                {selectedQuestionIndex === questions?.length - 1 && (
                  <>
                    <Button
                      variant="contained"
                      className="primary-contained-btn"
                      onClick={showModal}
                    >
                      Submit
                    </Button>

                    <Modal
                      title="Confirm Submission"
                      visible={isModalVisible}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <p>
                        Are you sure you want to submit this subjective exam?
                      </p>
                    </Modal>
                  </>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "flex-end",
                  }}
                >
                  {selectedQuestionIndex < questions?.length - 1 && (
                    <>
                      <Button
                        variant="contained"
                        className="primary-contained-btn"
                        onClick={showModal}
                      >
                        Submit
                      </Button>

                      <Modal
                        title="Confirm Submission"
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <p>
                          Are you sure you want to submit this subjective exam?
                        </p>
                      </Modal>
                    </>
                  )}
                </Box>
              </div>
              {/* <Box
                sx={{
                  flexDirection: { xs: "column", sm: "column", md: "row" },
                  width: "100%",
                  mb: 2,
                }}
              >
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Questions</TableCell>
                        <TableCell>Answer</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {questions?.map((question, index) => (
                        <TableRow
                          key={question?._id}
                          onClick={() => handleQuestionClick(index)}
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              selectedQuestionIndex === index
                                ? "#f0f0f0"
                                : "inherit",
                          }}
                        >
                          <TableCell>{question?.name}</TableCell>
                          <TableCell>
                            {answers[index] ? "Answered" : "Unanswered"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box> */}
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Container
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "5%",
                    marginTop: "5%",
                    width: "100%",
                    height: "auto",
                    flexWrap: "wrap",
                    backgroundColor: " #f9f9f9",
                  }}
                >
                  {questions.map((question, index) => {
                    // Determine if the question is answered or skipped
                    const isAnswered =
                      answers[index] && answers[index].trim() !== "";
                    const isSkipped =
                      !answers[index] && index < selectedQuestionIndex;

                    return (
                      <Box
                        key={index}
                        onClick={() => handleQuestionClick(index)}
                        selected={selectedQuestionIndex === index}
                        style={{
                          backgroundColor:
                            selectedQuestionIndex === index
                              ? "#ffc93c"
                              : isAnswered
                              ? "#42b883"
                              : isSkipped
                              ? "#e63946"
                              : "#455d7a",
                          fontSize: "17px",
                          color: "#FFF",
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          margin: "5px",
                          transition: "background-color 0.3s ease",
                        }}
                      >
                        <ListItemText
                          primary={`${index + 1}`}
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        />
                      </Box>
                    );
                  })}
                </Container>
              </Box>
            </div>
          )}
          {view === "result" && (
            <Container className="mt-2" sx={{ mt: { xs: 2, sm: 2, md: 2 } }}>
              <Box display="flex" justifyContent="center" mt={5}>
                <Typography variant="h4">Result</Typography>
              </Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Question</TableCell>
                      <TableCell>Your Answer</TableCell>
                      <TableCell>Correct Answer</TableCell>
                      <TableCell>Marks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {questions?.map((question, index) => (
                      <TableRow key={question?._id}>
                        <TableCell>{question?.name}</TableCell>
                        <TableCell>
                          {answers[index]
                            ? answers[index]
                            : "No answer provided"}
                        </TableCell>
                        <TableCell>{result[index]?.answer}</TableCell>
                        <TableCell>{result[index]?.marks}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          )}
        </Container>
      )}
    </>
  );
};

export default SubjectiveWriteExam;
