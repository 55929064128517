import React from "react";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { Modal, message } from "antd";

const DownloadCSV = () => {
  const downloadCSV = () => {
    // Show the confirmation modal
    showDownloadConfirmation();
  };
  // Modal confirmation logic
  const showDownloadConfirmation = () => {
    Modal.confirm({
      title: "Download Sample CSV",
      content: "Would you like to download the sample CSV file?",
      okText: "Yes, Download",
      cancelText: "No, Cancel",
      onOk: () => {
        const csvFilePath = "/downloaded_sample.csv";
        fetch(csvFilePath)
          .then((response) => {
            if (!response.ok) {
              throw new Error(
                `Network response was not ok: ${response.statusText}`
              );
            }
            return response.blob();
          })
          .then((blob) => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "downloaded_sample.csv";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error("Error fetching the file:", error);
            message.error("Failed to download the CSV file");
          });
      },
      onCancel() {
        console.log("Download canceled");
      },
    });
  };

  return (
    <Button
      onClick={downloadCSV}
      color="primary"
      variant="contained"
      startIcon={<DownloadIcon />}
      className="courses_desc"
      sx={{
        mb: 2,
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "5px",
        textTransform: "none",
        fontFamily: "Poppins, sans-serif",
        letterSpacing: ".1rem",
      }}
    >
      Download Sample CSV
    </Button>
  );
};

export default DownloadCSV;
