import React, { useState, useEffect } from "react";
import axios from "../../axiosInstance";
import { message, Modal } from "antd";
import { Box, Paper } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditInstituteInfo from "./EditInstituteInfo";
import { useSelector } from "react-redux";

const InstituteInfoList = () => {
  const { user } = useSelector((state) => state.user);
  const [instituteInfo, setInstituteInfo] = useState("");

  // Get institute details data
  const getAllInstituteInfo = async () => {
    try {
      const response = await axios.get(
        `/api/v1/vendor/admin-get-about-institute`
      );
      if (response?.data?.success === true) {
        setInstituteInfo(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllInstituteInfo();
    return () => clearInterval(intervalId);
  }, []);

  // Delete institute details data
  const { confirm } = Modal;
  const deleteAboutInstitute = async (e, id) => {
    confirm({
      title: "Are you sure you want to delete this institute details?",
      content: "This action will permanently remove the institute details.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      okType: "danger",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/vendor/admin-delete-about-institute",
            {
              data: { id },
            }
          );
          if (data?.success === true) {
            message.success(data?.message);
            getAllInstituteInfo();
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message ||
              "Error deleting institute details."
          );
        }
      },
      onCancel() {
        console.log("Institute details deletion canceled");
      },
    });
  };

  const columns = [
    {
      field: "Actions",
      width: 110,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteAboutInstitute(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditInstituteInfo
            refreshData={getAllInstituteInfo}
            instituteInfo={value?.row?._id}
            instituteId={value?.row?.instituteId}
            instituteName={value?.row?.instituteName}
            instituteDescription={value?.row?.description}
            institutePhone={value?.row?.institutePhone}
            instituteAddress={value?.row?.instituteAddress}
            instituteLogo={value?.row?.instituteLogo}
            instituteEmail={value?.row?.instituteEmail}
            vendorId={value?.row?.vendorId}
          />
        </Box>
      ),
    },
    {
      field: "instituteLogo",
      headerName: "Logo",
      width: 130,
      editable: true,
      renderCell: (params) => (
        <img
          src={params.row.instituteLogo}
          alt="Institute Logo"
          style={{ width: "100px", height: "auto", borderRadius: "5px" }}
        />
      ),
    },
    // {
    //   field: "instituteId",
    //   headerName: "Institute Id",
    //   width: 120,
    //   editable: true,
    // },
    {
      field: "instituteName",
      headerName: "Institute Name",
      width: 210,
      editable: true,
    },
    {
      field: "description",
      headerName: "About US",
      width: 210,
      renderCell: (params) => (
        <div
          style={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
          }}
          dangerouslySetInnerHTML={{ __html: params.row.description }}
        />
      ),
    },

    // {
    //   field: "description",
    //   headerName: "About US",
    //   width: 210,
    //   editable: true,
    // },

    {
      field: "institutePhone",
      headerName: "Phone Number",
      width: 170,
      editable: true,
    },

    {
      field: "instituteEmail",
      headerName: "Email",
      width: 170,
      editable: true,
    },

    {
      field: "instituteAddress",
      headerName: "Address",
      width: 260,
      editable: true,
    },
  ];
  // Conditionally add the instituteId column if user.role === 1
  if (user && user.role === 1) {
    columns.splice(2, 0, {
      field: "instituteId",
      headerName: "Institute Id",
      width: 180,
      editable: true,
    });
  }

  return (
    <>
      <Paper sx={{ bgcolor: "white" }}>
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row?._id}
            sx={{
              "& .MuiTablePagination-displayedRows": {
                color: "black",
              },
              color: "black",
              [`& .${gridClasses?.row}`]: {
                bgcolor: "white",
              },
              // Style for header cells
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgb(25, 118, 210)",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
                fontWeight: "bold",
                // fontSize: "13px",
              },
              // Style for checkbox in header
              "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                color: "#FFF",
              },
              "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                color: "white",
              },
            }}
            rows={instituteInfo}
            columns={columns}
            pageSize={3}
            rowsPerPageOptions={[3]}
            // checkboxSelection
          />
        </Box>
      </Paper>
    </>
  );
};

export default InstituteInfoList;
