import React, { useState } from "react";
import { Modal, message } from "antd";
import axios from "../../axiosInstance";
import { Container, TextField, MenuItem, Box, Button } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const EditBatch = ({ Batch, refreshData }) => {
  const [batch, setBatch] = useState(Batch?._id);
  const [batchName, setBatchName] = useState(Batch?.batchName);
  const [batchDuration, setBatchDuration] = useState(Batch?.batchDuration);
  const [batchClassMode, setBatchClassMode] = useState(Batch?.batchClassMode);
  const [startDate, setStartDate] = useState(Batch?.batchStartDate);
  const [endDate, setEndDate] = useState(Batch?.batchEndDate);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleChangeClassMode = (event) => {
    setBatchClassMode(event.target.value);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const batchStartDate = moment(startDate).format("MM/DD/YYYY");
  const batchEndDate = moment(endDate).format("MM/DD/YYYY");
  
  // Edit Batch
  const { confirm } = Modal;
  const handleSubmit = async (e) => {
    e.preventDefault();
    confirm({
      title: "Are you sure you want to Edit this Batch Details?",
      content: "You are about to update the batch details. Please confirm.",
      okText: "Yes, Edit",
      cancelText: "No, Cancel",
      onOk: async () => {
        const EditBatch = {
          batchName,
          batchDuration,
          batchClassMode,
          batchStartDate,
          batchEndDate,
          id: batch,
        };

        try {
          const { data } = await axios.put(
            "/api/v1/batch/admin-update-batch",
            EditBatch
          );
          if (data.success === true) {
            message.success(data.message);
            refreshData();
            setIsModalOpen(false);
          } else {
            message.error(data?.message);
          }
        } catch (error) {
          message.error(error?.response?.data?.message);
        }
      },
      onCancel() {
        // Optionally handle the cancel action
        console.log("Edit batch operation canceled");
      },
    });
  };

  return (
    <div>
      {/* <IconButton aria-label="edit" onClick={showModal}>
        <EditIcon sx={{ color: "#1976D2" }} />
      </IconButton> */}
      <Button
        onClick={showModal}
        variant="outlined"
        className="courses_desc"
        sx={{
          color: "primary",
          mt: 1,
          borderRadius: "50px",
          textTransform: "none",
          fontFamily: "Poppins, sans-serif",
          letterSpacing: ".1rem",
        }}
      >
        Edit
      </Button>
      <Modal
        title="Edit Batch"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <Container
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
          maxWidth
        >
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="name"
            label="Batch Name *"
            name="name"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Batch Name"
            value={batchName}
            onChange={(e) => setBatchName(e.target.value)}
          />
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="duration"
            label="Batch Duration *"
            name="duration"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Batch Duration"
            value={batchDuration}
            onChange={(e) => setBatchDuration(e.target.value)}
          />
          <TextField
            select
            label="Class Mode"
            placeholder="Class Mode"
            value={batchClassMode}
            onChange={handleChangeClassMode}
            fullWidth
            variant="outlined"
          >
            <MenuItem disabled>Select Class Mode</MenuItem>
            <MenuItem value="online">Online</MenuItem>
            <MenuItem value="offline">Offline</MenuItem>
          </TextField>
          <Box style={{ display: "flex", marginTop: "30px" }} className="Date">
            <Box
              sx={{
                mb: 3,
              }}
              style={{ marginRight: "2%" }}
            >
              <DatePicker
                showIcon
                toggleCalendarOnIconClick
                isClearable
                placeholderText="Batch Start Date"
                selected={startDate}
                value={startDate}
                onChange={handleStartDateChange}
                minDate={new Date()}
                maxDate={endDate}
                required
              />
            </Box>
            <Box>
              <DatePicker
                required
                showIcon
                toggleCalendarOnIconClick
                isClearable
                placeholderText="Batch End Date"
                selected={endDate}
                onChange={handleEndDateChange}
                value={endDate}
                // eslint-disable-next-line no-mixed-operators
                minDate={(new Date() && startDate) || new Date()}
                maxDate={endDate}
              />
            </Box>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="courses_desc"
            sx={{
              mt: 3,
              borderRadius: "5px",
              textTransform: "none",
              fontFamily: "Poppins, sans-serif",
              letterSpacing: ".1rem",
            }}
          >
            Edit Batch
          </Button>
        </Container>
      </Modal>
    </div>
  );
};
export default EditBatch;
