import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {
  Container,
  Typography,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import { useParams } from 'react-router-dom';

export default function AccessPublicNavbar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [bottomNavValue, setBottomNavValue] = useState();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  // Update bottomNavValue based on current location
  useEffect(() => {
    const currentPath = window.location.pathname;
    switch (currentPath) {
      case "":
        setBottomNavValue(0);
        break;
      case "/access-public-user":
        setBottomNavValue(1);
        break;
      default:
        setBottomNavValue(0);
        break;
    }
  }, [window.location.pathname]);

  // Handle bottom navigation change
  const handleBottomNavChange = (event, newValue) => {
    setBottomNavValue(newValue);
    switch (newValue) {
      case 0:
        navigate();
        break;
      case 1:
        navigate("/access-public-user");
        break;
      default:
        break;
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

 // Handle Logout api call
 const { confirm } = Modal;
 const handleLogout = () => {
   confirm({
     title: "Are you sure you want to Log out?",
     icon: <ExclamationCircleOutlined />,
     content: "Your current session will be terminated.",
     onOk() {
       localStorage.clear(); 
       message.success("Logout Successfully");
       window.location.reload("/access-public-user"); 
     },
     // onCancel() {
     //   message.info("Logout cancelled");
     // }
   });
 };

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: "#FFF",
          color: "#000",
          fontWeight: "600",
          position: "fixed",
          p: 1.5,
          width: "100%",
          zIndex: 1000,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              noWrap
              component={Link}
              // to={`/access-public-exam/${examId}/${instituteId}`}
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontWeight: 600,
                color: "inherit",
                textDecoration: "none",
                wordSpacing: "2px",
                letterSpacing: " 1px",
              }}
            >
              TEST SERIES
            </Typography>

            {/* Menu Icon for Mobile */}
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      wordSpacing: "2px",
                      letterSpacing: "1px",
                    }}
                    // to={`/access-public-exam/${examId}/${instituteId}`}
                  >
                    Home
                  </Link>
                  
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                <Link
                onClick={handleLogout}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      wordSpacing: "2px",
                      letterSpacing: "1px",
                    }}
                    to="/access-public-user"
                  >
                    logout
                  </Link>
                </MenuItem>
              </Menu>
            </Box>

            <Typography
              variant="h5"
              noWrap
              component={Link}
              // to={`/access-public-exam/${examId}/${instituteId}`}
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontWeight: 600,
                color: "inherit",
                textDecoration: "none",
                wordSpacing: "2px",
                letterSpacing: "1px",
              }}
            >
              TEST SERIES
            </Typography>

            {/* Desktop Links */}
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                  wordSpacing: "2px",
                  letterSpacing: "1px",
                }}
                // to={`/access-public-exam/${examId}/${instituteId}`}
              >
                Home
              </Link>
              <Link
              onClick={handleLogout}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                  wordSpacing: "2px",
                  letterSpacing: "1px",
                }}
                to="/access-public-user"
              >
                Logout
              </Link>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Bottom Navigation for Mobile */}
      {isMobile && (
        <BottomNavigation
          value={bottomNavValue}
          onChange={handleBottomNavChange}
          showLabels
          sx={{ position: "fixed", bottom: 0, width: "100%", zIndex: 1300 }}
        >
          <BottomNavigationAction label="Home" icon={<HomeIcon />} />
          <BottomNavigationAction label="Logout" onClick={handleLogout} icon={<LogoutIcon />} />
        </BottomNavigation>
      )}
    </>
  );
}
