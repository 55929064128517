import React, { useEffect, useState } from "react";
import { Modal, message, Input } from "antd";
import { IconButton, Container, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../axiosInstance"; // Make sure you have axios installed

const EditFacilities = ({ facilityId, facilities, onSave, refreshData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedFacilities, setEditedFacilities] = useState(facilities || []);
  useEffect(() => {
    setEditedFacilities(facilities || []);
  }, [facilities]);
  // Show modal to edit
  const showModal = () => {
    setIsModalOpen(true);
  };

  // Close modal
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Handle input change for each facility
  const handleFacilityChange = (index, value) => {
    const updatedFacilities = [...editedFacilities];
    updatedFacilities[index] = value;
    setEditedFacilities(updatedFacilities);
  };

  // Handle facility deletion with confirmation
  const handleDeleteFacility = (index, facilityName) => {
    Modal.confirm({
      title: "Are you sure you want to delete this facility?",
      content: `Facility: ${facilityName}. This action cannot be undone`,
      onOk: async () => {
        try {
          const response = await axios.delete(
            `/api/v1/vendor/admin-delete-facilities-name-institute`,
            {
              data: {
                facilityId, 
                index,
                facilityName,
              },
            }
          );
          if (response.status === 200) {
            const updatedFacilities = [...editedFacilities];
            updatedFacilities.splice(index, 1); 
            setEditedFacilities(updatedFacilities);
            message.success("Facility deleted successfully!");
            refreshData(); 
          }
        } catch (error) {
          message.error(
            error.response?.data?.message || "Error deleting facility."
          );
        }
      },
    });
  };

  // Edit Institute facilities
  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedFacilitiesData = editedFacilities.map((facility, index) => ({
      facilityNameId: index,
      newFacilityName: facility,
    }));
    Modal.confirm({
      title: "Are you sure you want to Edit this facility?",
      content:
        "You are about to update the facility names. This action cannot be undone.",
      onOk: async () => {
        try {
          // API request to update facilities
          const response = await axios.put(
            "/api/v1/vendor/admin-update-facilities-institute",
            {
              facilityId,
              updatedFacilitiesData,
            }
          );
          if (response.status === 200) {
            message.success("Facilities updated successfully!");
            setIsModalOpen(false);
            if (onSave) {
              onSave(response.data.data);
            }
            refreshData();
          }
        } catch (error) {
          message.error(
            error.response?.data?.message || "Error updating facilities."
          );
        }
      },
    });
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={showModal}>
        <EditIcon sx={{ color: "#1976d2" }} />
      </IconButton>

      <Modal
        title="Edit Facilities"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <Container
          component="form"
          className="facilities-form_style border-style"
          maxWidth="sm"
          onSubmit={handleSubmit}
        >
          {editedFacilities?.map((facility, index) => (
            <div
              key={index}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Input
                value={facility}
                onChange={(e) => handleFacilityChange(index, e.target.value)}
                placeholder={`Facility Name ${index + 1}`}
                required
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "5px",
                  padding: "10px",
                  border: "1px solid #ddd",
                }}
              />
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteFacility(index, facility)}
              >
                <DeleteIcon sx={{ color: "#d32f2f" }} />
              </IconButton>
            </div>
          ))}

          <Button variant="contained" type="submit">
            Edit Facilities
          </Button>
        </Container>
      </Modal>
    </div>
  );
};

export default EditFacilities;
