import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Breadcrumbs, Link as MUILink,
} from "@mui/material";
import axios from "../../axiosInstance";
import { Link } from "react-router-dom";
import { message } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import AddQuestions from "./AddQuestions";
import { useNavigate } from "react-router-dom";
import { getAllCourse } from "../../redux/actions/userAction";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddExamQuestion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { courses } = useSelector((state) => state.courseData);
  const { user } = useSelector((state) => state.user);
  const [value, setValue] = useState(0);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [duration, setDuration] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [passingMarks, setPassingMarks] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(startDate);
  const [negativeMarking, setNegativeMarking] = useState("");
  const [type, setType] = useState("MCQ");
  // const [questionType, setQuestionType] = useState("");
  const [examSet, setExamSet] = useState("");
  const [questionCount, setQuestionCount] = useState(0);
  const [studentCourseId, setStudentCourseId] = useState("");
  const [studentCourse, setStudentCourse] = useState("");
  const [publicExam, setPublicExam] = useState("");

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const examStartDate = moment(startDate).format("MM/DD/YYYY");
  const examEndDate = moment(endDate).format("MM/DD/YYYY");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Fetch all institute courses
  useEffect(() => {
    dispatch(getAllCourse());
  }, [dispatch]);

  useEffect(() => {
    if (courses) {
      setStudentCourse(courses);
    }
  }, [courses]); 

  // Handle Institute Course
  const handleChangeStudentCourse = (event) => {
    const selectedCourseName = event.target.value;
    setStudentCourse(selectedCourseName);

    // Assign a fixed ID for "General Course"
    const generalCourseId = "81af3e57846a42aab058dc4d"; // UUID Generate

    const selectedCourse = courses.find(
      (course) => course.courseName === selectedCourseName
    );

    if (selectedCourse) {
      setStudentCourseId(selectedCourse._id);
    } else if (selectedCourseName === "General") {
      setStudentCourseId(generalCourseId);
    }
  };
 
  // Add Exam
  const handleSubmit = async (e) => {
    e.preventDefault();
    const addExam = {
      name,
      category,
      duration,
      totalMarks,
      passingMarks,
      type,
      // questionType,
      examSet,
      startDate: examStartDate,
      endDate: examEndDate,
      negativeMarking,
      instituteId: user?.instituteId,
      vendorId: user?._id,
      userId: user?._id,
      instituteCourseId: studentCourseId,
      publicExam
    };

    try {
      const { data } = await axios.post( 
        "/api/v1/exams/admin-create-exams",
        addExam
      );
      if (data.success === true) {
        message.success(data.message);
        localStorage.setItem("id", JSON.stringify(data.newExam._id));
        // Clear form fields
        setName("");
        setCategory("");
        setDuration("");
        setTotalMarks("");
        setPassingMarks("");
        // setQuestionType("");
        setType("");
        setExamSet("");
        setStartDate(null);
        setEndDate(null);
        setNegativeMarking("");
        setStudentCourse("");
        // Automatically switch to the correct tab after adding the exam
        setValue(1);
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  // Use The Navigate in Question List
  const localStorageId = JSON.parse(localStorage.getItem("id"));
  // check the question length
  const getAllQuestionLength = async () => {
    try {
      // Check if localStorageId exists
      if (!localStorageId) {
        message.warning("No questions added yet. Please add questions first.");
        return { success: false, count: 0 };
      }
      const response = await axios.get(
        `/api/v1/exams/get-exam-by-id/${localStorageId}`
      );
      const count = response?.data?.data?.questions?.length || 0;
      setQuestionCount(count);
      return { success: true, count };
    } catch (error) {
      message.error(error?.response?.data?.message);
      return { success: false, count: 0 };
    }
  };

  useEffect(() => {
    if (localStorageId) {
      getAllQuestionLength();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorageId]);

  const handleShowQuestionList = async () => {
    const { success, count } = await getAllQuestionLength();
    if (!success) {
      return; // Exit if there was an error
    }
    if (count === 0) {
      message.warning("No questions added yet. Please add questions first.");
    } else {
      navigate(`/admin/edit/exam/${localStorageId}`);
    }
  };

  return (
    <>
      <PageTitle title="Admin Exam and Question | Test Series" />
      <Box sx={{ width: "100%" }}>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Add Exams And Questions
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="/admin/exams"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Exams
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>Add Exams And Questions</Typography>
        </Breadcrumbs>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Add Exam" {...TabPanel(0)} />
            <Tab label="Add Question" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/* Add Exams */}
        <CustomTabPanel value={value} index={0}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Create a new Exam
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <Box sx={{ mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="public-exam-label">Public Exam</InputLabel>
                <Select
                  labelId="public-exam-label"
                  id="public-exam"
                  value={publicExam}
                  label="Public Exam"
                  required
                  onChange={(e) => setPublicExam(e.target.value)}
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              select
              name="studentCourse"
              label="Institute Course *"
              placeholder="Institute Course *"
              value={studentCourse}
              onChange={handleChangeStudentCourse}
              fullWidth
              variant="outlined"
            >
              <MenuItem disabled>Select Course</MenuItem>
              <MenuItem key="generalCourse" value="General">
                General
              </MenuItem>
              {courses &&
                courses.map((course) => (
                  <MenuItem key={course._id} value={course.courseName}>
                    {course.courseName}
                  </MenuItem>
                ))}
            </TextField>

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="name"
              label="Exam Name *"
              name="name"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Exam Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="category"
              label="Category *"
              name="category"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="duration"
              label="Duration (mins) *"
              name="duration"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Duration (mins)"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="totalMarks"
              label="Total Marks *"
              name="totalMarks"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Total Marks"
              value={totalMarks}
              onChange={(e) => setTotalMarks(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="passingMarks"
              label="Passing Marks *"
              name="passingMarks"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Passing Marks"
              value={passingMarks}
              onChange={(e) => setPassingMarks(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="negativeMarks"
              label="Negative Marks (optional)"
              name="negativeMarks"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Negative Marks (optional)"
              value={negativeMarking}
              onChange={(e) => setNegativeMarking(e.target.value)}
            />
            <Box sx={{ mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {" "}
                  Exam Set{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={examSet}
                  label="Exam Set"
                  required
                  onChange={(e) => setExamSet(e.target.value)}
                >
                  <MenuItem value="Practice Exam">Practice Exam </MenuItem>
                  <MenuItem value="Live Exam">Live Exam</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box style={{ display: "flex" }} className="Date">
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Start Date"
                  selected={startDate}
                  value={startDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
              <Box>
                <DatePicker
                  required
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="End Date"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  value={endDate}
                  // eslint-disable-next-line no-mixed-operators
                  minDate={(new Date() && startDate) || new Date()}
                  maxDate={endDate}
                />
              </Box>
            </Box>

            <Button
              variant="contained"
              type="submit"
              color="primary"
              className="courses_desc"
              sx={{
                mt: 3,
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
                 color:"white"
              }}
            >
              Add Exam
            </Button>
          </Container>
        </CustomTabPanel>

        {/* Add Questions */}
        <CustomTabPanel value={value} index={1}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color:"#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Add Question
          </Typography>
          <AddQuestions
            vendor_Id={user?._id}
            institute_Id={user?.instituteId}
          />

          <Button
            variant="outlined"
            onClick={handleShowQuestionList}
            type="submit"
            className="courses_desc"
            sx={{
              mt: 3,
              borderRadius: "50px",
              textTransform: "none",
              fontFamily: "Poppins, sans-serif",
              letterSpacing: ".1rem",
            }}
          >
            Show Question List
          </Button>
        </CustomTabPanel>
      </Box>
    </>
  );
}
