import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Paper, Typography, IconButton, Breadcrumbs, Link as MUILink, } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import axios from "../../axiosInstance";
import { message, Modal } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";

const ViewUserReports = () => {
  const { userEmail } = useParams();
  const { user } = useSelector((state) => state.user);
  const [userReports, setUserReports] = useState([]);

  const fetchUserReports = async () => {
    try {
      const response = await axios.post(`/api/v1/reports/admin-user-email-group-reports`, { userEmail });
      setUserReports(response?.data?.data);
    } catch (error) {
      message.error("Failed to fetch user reports.");
    }
  };

  useEffect(() => {
    fetchUserReports();
  }, [userEmail]);

   // delete Report by Id
   const deleteReport = async (e, id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Report?",
      content:
        "This action will permanently delete the report. Are you sure you want to continue?",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/reports/admin-delete-reports",
            {
              data: { id },
            }
          );
          if (data.success === true) {
            message.success(data.message);
            fetchUserReports();
          } else {
            message.error(data.message);
          }
        } catch (error) {
          message.error(error?.response?.data?.message);
        }
      },
      onCancel() {
        console.log("Delete operation canceled.");
      },
    });
  };

  const columns = [
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteReport(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "examName",
      headerName: "Exam Name",
      width: 180,
      editable: true,
    },

    {
      field: "category",
      headerName: "Exam Category",
      width: 120,
      editable: true,
    },

    {
      field: "examSet",
      headerName: "Exam Type",
      width: 120,
      editable: true,
    },

    {
      field: "userEmail",
      headerName: "Student Email",
      width: 160,
      editable: true,
    },

    {
      field: "totalMarks",
      headerName: "Total Marks",
      width: 110,
      editable: true,
    },

    {
      field: "passingMarks",
      headerName: "Passing Marks",
      width: 110,
      editable: true,
    },

    {
      fields: "result",
      headerName: "Obtained Marks",
      width: 110,
      renderCell: (row) => {
        const obtainedMarks = row?.row?.result?.obtainedMarks;
        return obtainedMarks ? obtainedMarks.toFixed(2) : "0.00";
      },
    },
    {
      field: "result",
      headerName: "Verdict",
      width: 110,
      renderCell: (row) => row?.row?.result?.verdict,
    },
    {
      field: "date",
      headerName: "Report Date",
      width: 110,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY"),
    },
  ];
  // Conditionally add the instituteId column if user.role === 1
  if (user && user.role === 1) {
    columns.splice(1, 0, {
      field: "instituteId",
      headerName: "Institute Id",
      width: 120,
      editable: true,
    });
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
       
      </Typography>
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          mb: 3,
          fontFamily: "Poppins, sans-serif",
          color: "#2C3E50",
          letterSpacing: "2.5px",
          lineHeight: 1.8,
        }}
      >
        Reports for {userEmail}
      </Typography>

      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          mb: 5,
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        <MUILink
          component={Link}
          to="/"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Dashboard
        </MUILink>
        <MUILink
          component={Link}
          to="/admin/exams"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Exams
        </MUILink>
        <MUILink
          component={Link}
          to="/admin/student/share/all/report"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Share Reports
        </MUILink>
        <Typography sx={{ color: "primary.main" }}>All Reports</Typography>
      </Breadcrumbs>
        <Box sx={{ height: 500 }}>
          <DataGrid
           getRowId={(row) => row?._id}
           sx={{
             "& .MuiTablePagination-displayedRows": {
               color: "black",
             },
             color: "black",
             [`& .${gridClasses?.row}`]: {
               bgcolor: "white",
             },
             // Style for header cells
             "& .MuiDataGrid-columnHeaders": {
               backgroundColor: "rgb(25, 118, 210)",
             },
             "& .MuiDataGrid-columnHeaderTitle": {
               color: "white",
               fontWeight: "bold",
               // fontSize: "13px",
             },
             // Style for checkbox in header
             "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
               color: "#FFF",
             },
             "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
               color: "white",
             },
             "& .MuiDataGrid-sortIcon": {
               color: "white",
             },
            }}
            rows={userReports}
            columns={columns}
            pageSize={5}
            // sx={{ bgcolor: "white" }}
          />
        </Box>
    </Box>
  );
};

export default ViewUserReports;
