import React, { useState } from "react";
import { Modal, message } from "antd";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Container, TextField, Button, Chip, Box } from "@mui/material";
import axios from "../../../axiosInstance";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

const SubEditAssignCategoryPermissions = ({
  permissionName,
  institute,
  userId,
  permissionID,
}) => {
  const [user_id, setUser_id] = useState(userId);
  const [instituteId, setInstituteId] = useState(institute);
  const [permissionsName, setPermissionsName] = useState(
    permissionName[0]?.permission_name
  );

  const [permissionsId, setPermissionsId] = useState(permissionID);

  const [permissionValue, setPermissionValue] = useState(
    permissionName[0]?.permission_value
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    // window.location.reload();
  };

  //Update Category Permission
  const { confirm } = Modal;
  const handleSubmit = async (e) => {
    e.preventDefault();
    confirm({
      title: "Are you sure you want to Edit this Category Permissions?",
      content: "This action will update the selected category permissions.",
      okText: "Yes, Edit",
      cancelText: "No, Cancel",
      onOk: async () => {
        const categoryPermissions = {
          id: permissionsId,
          user_id,
          instituteId,
          permissions: [
            {
              permission_name: permissionsName,
              permission_value: permissionValue,
            },
          ],
        };

        try {
          const { data } = await axios.put(
            "/api/v1/common/admin-update-category-permission",
            categoryPermissions
          );
          if (data.success === true) {
            message.success(data.message);
            setIsModalOpen(false);
          } else {
            message.error(data?.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to update permission."
          );
        }
      },
      onCancel() {
        console.log("Permission edit canceled.");
      },
    });
  };

  const getMenuItemLabel = (value) => {
    switch (value) {
      case 0:
        return "Create";
      case 1:
        return "Read";
      case 2:
        return "Update";
      case 3:
        return "Delete";
      default:
        return "";
    }
  };
  return (
    <>
      <IconButton aria-label="edit" onClick={showModal}>
        <EditIcon sx={{ color: "#1976d2" }} />
      </IconButton>
      <Modal
        title="Edit Permissions"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <Container
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
          maxWidth
        >
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="permissionsId"
            label="Permission Name"
            name="permissionsId"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Permission Name"
            value={permissionsName}
            //   onChange={(e) => setPermissions(e.target.value)}
          />

          <Box sx={{ width: "100%", mb: 3 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {" "}
                Add Permission{" "}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={permissionValue}
                label="Add Permission"
                required
                multiple // This allows selecting multiple items
                onChange={(e) => setPermissionValue(e.target.value)}
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <Chip
                        sizes="medium"
                        key={value}
                        label={getMenuItemLabel(value)}
                      />
                    ))}
                  </div>
                )}
              >
                <MenuItem value={0}>Create</MenuItem>
                <MenuItem value={1}>Read</MenuItem>
                <MenuItem value={2}>Update</MenuItem>
                <MenuItem value={3}>Delete</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Button
            variant="contained"
            type="submit"
            color="primary"
            className="courses_desc"
            sx={{
              mt: 3,
              borderRadius: "5px",
              textTransform: "none",
              fontFamily: "Poppins, sans-serif",
              letterSpacing: ".1rem",
            }}
          >
            Edit Category Permissions
          </Button>
        </Container>
      </Modal>
    </>
  );
};

export default SubEditAssignCategoryPermissions;
