import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Breadcrumbs, Link as MUILink, 
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "../../axiosInstance";
import SearchIcon from "@mui/icons-material/Search";
import { message, Modal } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditCategoryPermissions from "./EditCategoryPermissions";
import { Chip } from "@mui/material";
import { useSelector } from "react-redux";
import AssignCategoryPermissionsList from "./AssignCategoryPermissionsList";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CategoryPermissions() {
  const [value, setValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  const [categoryPermissions, setCategoryPermissions] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [vendorId, setVendorId] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vendorRole, setVendorRole] = useState("");
  const [vendorInstituteId, setVendorInstituteId] = useState("");
  const [routerPermission, setRouterPermission] = useState([]);
  const [addPermissionId, setAddPermissionId] = useState("");
  const [addPermission, setAddPermission] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Get Category Permissions
  const getCategoryPermissions = async () => {
    try {
      const response = await axios.get(
        `/api/v1/common/admin-get-category-permission`
      );
      if (response?.data?.success === true) {
        setCategoryPermissions(response?.data?.allPermissions);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getCategoryPermissions();
    return () => clearInterval(intervalId);
  }, []);

  // Delete Category Permission
const { confirm } = Modal;
const deleteCategoryPermissions = async (id) => {
  confirm({
    title: "Are you sure you want to delete this Category Permission?",
    content: "This action is irreversible and will remove the assigned permission.",
    okText: "Yes, Delete",
    cancelText: "No, Cancel",
    onOk: async () => {
      try {
        const { data } = await axios.delete(
          "/api/v1/common/admin-delete-category-permission",
          {
            data: { id },
          }
        );
        if (data.success === true) {
          message.success(data.message);
          getCategoryPermissions(); 
        } else {
          message.error(data.message);
        }
      } catch (error) {
        message.error(error?.response?.data?.message || "Failed to delete category permission");
      }
    },
    onCancel() {
      console.log("Delete operation canceled");
    },
  });
};


  const columns = [
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteCategoryPermissions(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditCategoryPermissions
            refreshData={getCategoryPermissions}
            permissionName={value?.row?.permissions}
            institute={value?.row?.instituteId}
            userId={value?.row?.user_id?._id}
            permissionID={value?.row?._id}
          />
        </Box>
      ),
    },
    {
      field: "instituteId",
      headerName: "Institute Id",
      width: 220,
      editable: true,
    },
    {
      field: "user_id",
      headerName: "Vendor Name",
      width: 250,
      // editable: true,
      renderCell: (row) => row?.row?.user_id?.name,
    },

    {
      field: "email",
      headerName: "Vendor Email",
      width: 290,
      // editable: true,
      renderCell: (row) => row?.row?.user_id?.email,
    },

    {
      field: "role_name",
      headerName: "Role",
      width: 280,
      // editable: true,
      renderCell: (row) => row?.row?.user_id?.role_name,
    },

    {
      field: "permissions",
      headerName: "Permissions",
      width: 220,
      renderCell: (row) => {
        // Render permissions as comma-separated string
        return row.row.permissions
          .map((permission) => permission.permission_name)
          .join(", ");
      },
    },

    // {
    //   field: "permission Value",
    //   headerName: "Permission Value",
    //   width: 280,
    //   renderCell: (row) => {
    //     // Render permissions as comma-separated string
    //     return row.row.permissions
    //       .map((permission) => permission.permission_value)
    //       .join(", ");
    //   },
    // },
  ];

  // get-all-vendor
  const displayVendor = async () => {
    try {
      const { data } = await axios.get("/api/v1/common/admin-get-vendor");
      setVendors(data?.vendors);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    displayVendor();
  }, []);

  const AddRouterPermissionHandler = (e) => {
    const selectedValue = e.target.value; // Get the selected value
    setVendorId(selectedValue?._id);
    setVendorRole(selectedValue?.role_name);
    setVendorInstituteId(selectedValue?.instituteId);
    setVendorName(selectedValue?.name);
  };

  const AddPermissionHandler = (e) => {
    const selectedValue = e.target.value;
    setAddPermissionId(selectedValue);
  };

  // Get Permission Name Data
  const getAllPermissionInRouter = async () => {
    try {
      const response = await axios.get(`/api/v1/admin/admin-get-permission`);

      if (response?.data?.success === true) {
        setRouterPermission(response?.data?.permission);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllPermissionInRouter();
    return () => clearInterval(intervalId);
  }, []);

  // Define a function to get the label for each MenuItem value in Add Permissions
  const getMenuItemLabel = (value) => {
    switch (value) {
      case 0:
        return "Create";
      case 1:
        return "Read";
      case 2:
        return "Update";
      case 3:
        return "Delete";
      default:
        return "";
    }
  };

  // Assign a new Category Permissions
  const handleSubmit = async (e) => {
    e.preventDefault();
    const addCategoryPermissions = {
      user_id: vendorId,
      instituteId: vendorInstituteId,
      permissions: [
        {
          id: addPermissionId,
          value: addPermission,
        },
      ],
      permission_id: addPermissionId,
      assignCategoryPermissions: user?._id,
    };
  
    // if (
    //   window.confirm(
    //     "Are you sure you want to Assign this Category Permission?"
    //   )
    // ) {
    //   try {
    //     const { data } = await axios.post(
    //       "/api/v1/common/admin-create-category-permission",
    //       addCategoryPermissions
    //     );
    //     if (data.success === true) {
    //       message.success(data.message);
    //       // Clear form fields
    //     } else {
    //       message.error(data?.message);
    //     }
    //   } catch (error) {
    //     message.error(error?.response?.data?.message);
    //   }
    // }
    confirm({
      title: "Are you sure you want to Assign this Category Permission?",
      content: "This action will assign the selected permission to the user.",
      okText: "Yes, Assign",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.post(
            "/api/v1/common/admin-create-category-permission",
            addCategoryPermissions
          );
          if (data.success === true) {
            message.success(data.message);
          } else {
            message.error(data?.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to assign permission."
          );
        }
      },
      onCancel() {
        console.log("Permission assignment canceled.");
      },
    });
  };

  // Filter users based on search term
  const filteredUsers = categoryPermissions.filter((user) => {
    const userName = user?.user_id?.name?.toLowerCase() || "";
    const userEmail = user?.user_id?.email?.toLowerCase() || "";
    // Get all permission names as a single string (if multiple permissions exist)
    const permissionNames = user.permissions
      .map((permission) => permission.permission_name.toLowerCase())
      .join(", ");
    return (
      userName.includes(searchTerm.toLowerCase()) ||
      userEmail.includes(searchTerm.toLowerCase()) ||
      permissionNames.includes(searchTerm.toLowerCase())
    );
  });

  return (
    <>
      <PageTitle title="Admin Category Permissions | Test Series" />
      <Box>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Category Permissions
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="#"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Super Admin
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>Category Permissions</Typography>
        </Breadcrumbs>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Category Permissions" {...TabPanel(0)} />
            <Tab label="Add Category Permissions" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/* All Category Permissions List */}
        <CustomTabPanel value={value} index={0}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            All Category Permissions List
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by Vendor Name, Email, or Permission Name"
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon sx={{ color: "#1976d2" }} />
                </IconButton>
              ),
            }}
            sx={{
              mb: 3,
              bgcolor: "white",
              borderRadius: "20px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
              },
            }}
          />
          <Paper sx={{ bgcolor: "white" }}>
            <Box sx={{ height: 400, width: "auto" }}>
              <DataGrid
                getRowId={(row) => row?._id}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                  // Style for header cells
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                    // fontSize: "13px",
                  },
                  // Style for checkbox in header
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                rows={filteredUsers}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[3]}
                // checkboxSelection
              />
            </Box>
          </Paper>

          <AssignCategoryPermissionsList />
        </CustomTabPanel>

        {/* Assign a new Category Permissions */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Assign a new Category Permissions
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            {/* All User  */}
            <Box sx={{ minWidth: "100%", mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Vendors Email
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Vendors Email"
                  onChange={AddRouterPermissionHandler}
                  sx={{ minWidth: "50%" }}
                  required
                >
                  {vendors && vendors.length > 0 ? (
                    vendors.map((vendor, index) => (
                      <MenuItem key={index} value={vendor}>
                        {vendor.email}
                      </MenuItem>
                    ))
                  ) : (
                    <Box
                      style={{
                        color: "#878787",
                        margin: "10px",
                        fontSize: 18,
                      }}
                    >
                      Create a new Vendor
                    </Box>
                  )}
                </Select>
              </FormControl>
            </Box>

            {/* <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendorInstituteId"
              label="Vendor Institute Id *"
              name="vendorInstituteId"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Vendor Institute Id"
              value={vendorInstituteId}
            /> */}

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendorName"
              label="Vendor Name *"
              name="vendorName"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Vendor Name"
              value={vendorName}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendorRole"
              label="Vendor Role *"
              name="vendorRole"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Vendor Role"
              value={vendorRole}
            />

            {/* Permission Dropdown */}

            <Box sx={{ width: "100%", mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Permission Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Permission Name"
                  required
                  onChange={AddPermissionHandler}
                >
                  {routerPermission && routerPermission.length > 0 ? (
                    routerPermission.map((routerPermission, index) => (
                      <MenuItem key={index} value={routerPermission._id}>
                        {routerPermission.permission_name}
                      </MenuItem>
                    ))
                  ) : (
                    <Box
                      style={{
                        color: "#878787",
                        margin: "10px",
                        fontSize: 18,
                      }}
                    >
                      No available Permission
                    </Box>
                  )}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {" "}
                  Add Permission{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={addPermission}
                  label="Add Permission"
                  required
                  multiple // This allows selecting multiple items
                  onChange={(e) => setAddPermission(e.target.value)}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip
                          sizes="medium"
                          key={value}
                          label={getMenuItemLabel(value)}
                        />
                      ))}
                    </div>
                  )}
                >
                  <MenuItem value={0}>Create</MenuItem>
                  <MenuItem value={1}>Read</MenuItem>
                  <MenuItem value={2}>Update</MenuItem>
                  <MenuItem value={3}>Delete</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="courses_desc"
              sx={{
                mt: 3,
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              Add Category
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
}
