import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Breadcrumbs, Link as MUILink } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import PageTitle from "../../Components/PageTitle/PageTitle";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Paper, TextField, Button, Typography, Container } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../axiosInstance";
import { message, Modal } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import EditCourse from "./EditCourse";
import { getAllCourse } from "../../redux/actions/userAction";


const { confirm } = Modal;
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddCourses() {
  const dispatch = useDispatch();
  const { courses } = useSelector((state) => state.courseData);
  const [value, setValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  const [course, setCourse] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [courseFees, setCourseFees] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  // get api call in Redux (course)
  useEffect(() => {
    dispatch(getAllCourse());
  }, [dispatch]);

  useEffect(() => {
    if (courses) {
      setCourse(courses);
    }
  }, [courses]);

  const courseStartingDate = moment(startDate).format("MM/DD/YYYY");
  const courseEndingDate = moment(endDate).format("MM/DD/YYYY");

  // Add Course
  const handleSubmit = async (e) => {
    e.preventDefault();
    const AddCourse = {
      courseName,
      courseFees,
      courseDescription,
      startDate: courseStartingDate,
      endDate: courseEndingDate,
      instituteId: user?.instituteId,
      vendorId: user?._id,
    };
    try {
      const { data } = await axios.post(
        "/api/v1/vendor/admin-add-course-institute",
        AddCourse
      );
      if (data.success === true) {
        message.success(data.message);
        // Clear form fields
        setCourseName("");
        setCourseFees("");
        setCourseDescription("");
        setStartDate("");
        setEndDate("");
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  // Delete Courses
  const deleteCourse = async (e, id) => {
    confirm({
      title: "Are you sure you want to delete this Course?",
      content: "Once deleted, you will not be able to recover this course.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/vendor/admin-delete-course-institute",
            {
              data: { courseId: id },
            }
          );
          if (data.success === true) {
            message.success(data?.message);
            getAllCourse();
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Error deleting course."
          );
        }
      },
      // onCancel() {
      //   console.log("Delete action canceled");
      // },
    });
  };

  // Handle search input change
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter courses based on search query
  const filteredCourses = course?.filter((course) =>
    course.courseName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const columns = [
    {
      field: "Actions",
      width: 120,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteCourse(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditCourse
            refreshData={getAllCourse}
            course={value?.row?._id}
            courseName={value?.row?.courseName}
            courseFees={value?.row?.courseFees}
            courseDescription={value?.row?.courseDescription}
            courseStartDate={value?.row?.startDate}
            courseEndDate={value?.row?.endDate}
          />
        </Box>
      ),
    },
    // {
    //   field: "instituteId",
    //   headerName: "InstituteId",
    //   width: 120,
    //   editable: true,
    // },
    {
      field: "courseName",
      headerName: "Course Name",
      width: 180,
      editable: true,
    },
    {
      field: "courseFees",
      headerName: "Course Fees",
      width: 180,
      editable: true,
    },
    // {
    //   field: "courseDescription",
    //   headerName: "Course Description",
    //   width: 410,
    //   editable: true,
    // },

    {
      field: "courseDescription",
      headerName: "Course Description",
      width: 410,
      renderCell: (params) => (
        <div
          style={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
          }}
          dangerouslySetInnerHTML={{ __html: params.row.courseDescription }}
        />
      ),
    },

    {
      field: "startDate",
      headerName: "Course Start Date",
      width: 180,
      renderCell: (params) =>
        moment(params?.row?.startDate).format("MM/DD/YYYY"),
    },

    {
      field: "endDate",
      headerName: "Course End Date",
      width: 180,
      renderCell: (params) => moment(params?.row?.endDate).format("MM/DD/YYYY"),
    },
  ]; // Conditionally add the instituteId column if user.role === 1
  if (user && user.role === 1) {
    columns.splice(1, 0, {
      field: "instituteId",
      headerName: "Institute Id",
      width: 180,
      editable: true,
    });
  }

  return (
    <>
      <PageTitle title="Add Courses | Test Series" />
      <Box>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Institute Courses
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 3,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="/admin/institute/info"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Institute Information
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>
            Institute Courses
          </Typography>
        </Breadcrumbs>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Course List" {...a11yProps(0)} />
            <Tab label="Add Course" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Course List
          </Typography>

          {/* Search Input */}
          <Box sx={{ pb: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search by Course Name"
              value={searchQuery}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon sx={{ color: "#1976d2" }} />
                  </IconButton>
                ),
              }}
              sx={{
                mb: 3,
                bgcolor: "white",
                borderRadius: "20px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
            />
          </Box>

          <Paper sx={{ bgcolor: "white" }}>
            <Box sx={{ height: 500, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row?._id}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                  },
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                rows={filteredCourses}
                columns={columns}
                pageSize={3}
                rowsPerPageOptions={[3]}
              />
            </Box>
          </Paper>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Create a new Course
          </Typography>

          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
              }}
              fullWidth
              id="course_name"
              label="Course Name *"
              name="course_name"
              placeholder="Course Name"
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
            />

            <TextField
              // sx={{
              //   mb: 3,
              // }}
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="course_fees"
              label="Course Fees *"
              name="course_fees"
              placeholder="Course Fees"
              type="Number"
              value={courseFees}
              onChange={(e) => setCourseFees(e.target.value)}
            />

            {/* <TextField
              sx={{
                mb: 3,
              }}
              fullWidth
              id="course_description"
              label="Course Description *"
              name="course_description"
              placeholder="Course Description"
              value={courseDescription}
              onChange={(e) => setCourseDescription(e.target.value)}
              multiline
            /> */}

            <ReactQuill
              value={courseDescription}
              onChange={setCourseDescription}
              placeholder="Course Description"
              style={{
                // height: "200px",
                marginBottom: "30px",
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: "10px",
              }}
            />

            <Box style={{ display: "flex" }} className="Date">
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Course Start Date"
                  selected={startDate}
                  value={startDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
              <Box>
                <DatePicker
                  required
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Course End Date"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  value={endDate}
                  // eslint-disable-next-line no-mixed-operators
                  minDate={(new Date() && startDate) || new Date()}
                  maxDate={endDate}
                />
              </Box>
            </Box>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              className="courses_desc"
              sx={{
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              Add Course
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
}
