import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography, Box, Paper } from "@mui/material";
import { message } from "antd";
import axios from "../../../axiosInstance";
import SubViewRoutesPermissionView from "./SubViewRoutesPermissionView";
import { DataGrid } from "@mui/x-data-grid";
import { gridClasses } from "@mui/x-data-grid";

const SubAssignRoutesPermission = () => {
  const [assignRoutes, setAssignRoutes] = useState([]);

  // Get Assign Category Permission
  const getRoutesPermissions = async () => {
    try {
      const response = await axios.get(
        "/api/v1/common/get-assign-routes-permissions"
      );
      if (response?.data?.success === true) {
        setAssignRoutes(response?.data?.assignRoutes);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getRoutesPermissions();
    return () => clearInterval(intervalId);
  }, []);

  // Delete Assign Routes Permission
  const deleteRoutesPermission = async (e, id) => {
    if (
      window.confirm("Are you sure you want to delete this Routes Permission?")
    ) {
      try {
        const { data } = await axios.delete(
          "/api/v1/admin/admin-delete-router-permission",
          {
            data: { id },
          }
        );
        if (data.success === true) {
          message.success(data.message);
          getRoutesPermissions();
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };
  const columns = [
    {
      field: "instituteId",
      headerName: "Institute Id",
      width: 220,
      editable: true,
    },
    // {
    //   field: "user_id",
    //   headerName: "Vendor Name",
    //   width: 250,
    //   // editable: true,
    //   renderCell: (row) => row?.row?.user_id?.name,
    // },

    {
      field: "email",
      headerName: "Vendor Email",
      width: 280,
      // editable: true,
      renderCell: (row) => row?.row?.user_id?.email,
    },
    {
      field: "role",
      headerName: "Role",
      width: 140,
      // editable: true,
      renderCell: (row) => row?.row?.user_id?.role,
    },

    {
      field: "router_endpoint",
      headerName: "router_endpoint",
      width: 220,
      editable: true,
    },

    {
      field: "permission_id",
      headerName: "Permission",
      width: 220,
      editable: true,
      renderCell: (row) => row?.row?.permission_id?.permission_name,
    },

    //   {
    //     field: "Permission Info",
    //     width: 210,
    //     renderCell: (row) => (
    //       <Box sx={{ display: "flex", justifyContent: "space-between" }}>
    //         <SubViewRoutesPermissionView
    //           permission_Value={row?.row?.permission}
    //         />

    //       </Box>
    //     ),
    //   },

    {
      field: "Actions",
      width: 210,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* <SubViewRoutesPermissionView /> */}

          <SubViewRoutesPermissionView
            permission_Value={value?.row?.permission}
          />

          <IconButton
            aria-label="delete"
            onClick={(e) => deleteRoutesPermission(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <div>
      <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
        Assign Routes Permissions List
      </Typography>
      <Paper sx={{ bgcolor: "white" }}>
        <Box sx={{ height: 400, width: "auto" }}>
          <DataGrid
            getRowId={(row) => row?._id}
            sx={{
              "& .MuiTablePagination-displayedRows": {
                color: "black",
              },
              color: "black",
              [`& .${gridClasses?.row}`]: {
                bgcolor: "white",
              },
               // Style for header cells
               "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgb(25, 118, 210)",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
                fontWeight: "bold",
                // fontSize: "13px",
              },
              // Style for checkbox in header
              "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                color: "#FFF",
              },
              "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                color: "white",
              },
            }}
            rows={assignRoutes}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[3]}
            // checkboxSelection
          />
        </Box>
      </Paper>
    </div>
  );
};

export default SubAssignRoutesPermission;
