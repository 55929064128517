import React, { useState, useEffect } from "react";
import { message, Modal, Select } from "antd";
import { Container, Box, TextField, Button } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MicIcon from "@mui/icons-material/Mic";
import axios from "../../axiosInstance";

const { Option } = Select;

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();

const EditSubjectiveQuestionModal = ({
  editSubjectiveQuestionModal,
  setEditSubjectiveQuestionModal,
  refreshData,
  examId,
  selectedQuestion,
  setSelectedQuestion,
}) => {
  const [name, setName] = useState("");
  const [referenceAnswer, setReferenceAnswer] = useState("");
  const [questionNumber, setQuestionNumber] = useState("");
  const [instituteId, setInstituteId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const id = selectedQuestion?._id;
  const [isRecognitionActive, setIsRecognitionActive] = useState(false);
  const [language, setLanguage] = useState("en-IN");

  // Get Exam By Subjective Question ID
  const getExamsBySubjectiveQuestionID = async () => {
    try {
      const response = await axios.post(
        `/api/v1/question/subjective/admin-get-exam-to-subjectiveQuestions`,
        { id }
      );
      setName(response?.data?.data?.name);
      setReferenceAnswer(response?.data?.data?.referenceAnswer);
      setQuestionNumber(response?.data?.data?.questionNumber);
      setInstituteId(response?.data?.data?.instituteId);
      setVendorId(response?.data?.data?.vendorId);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getExamsBySubjectiveQuestionID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Subjective Question Edit Api Call
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Show confirmation modal before submission
    Modal.confirm({
      title: "Are you sure you want to Edit this Subjective Question?",
      content:
        "Please verify that all the details are correct before updating.",
      okText: "Yes, Edit",
      cancelText: "No, Cancel",
      onOk: async () => {
        const required = {
          name,
          referenceAnswer,
          questionNumber,
          questionId: id,
          exam: examId,
          id: id,
          instituteId: instituteId,
          vendorId: vendorId,
        };

        try {
          const response = await axios.put(
            "/api/v1/question/subjective/admin-update-exam-to-subjectiveQuestions",
            required
          );
          if (response.data.success === true) {
            message.success(response?.data?.message);
            refreshData();
            setEditSubjectiveQuestionModal(false);
          } else {
            message.error(response?.data?.message);
          }
          setSelectedQuestion(null);
        } catch (error) {
          message.error(error?.response?.data?.message || "An error occurred");
        }
      },
      onCancel() {
        console.log("Edit canceled");
      },
    });
  };

  // Function to handle speech input
  // const handleSpeechInput = (field, index) => {
  //   if (isRecognitionActive) {
  //     message.warning("Speech recognition is already in progress!");
  //     return;
  //   }

  //   Modal.confirm({
  //     title: "Start Speech Recognition?",
  //     content: "Are you sure you want to start speech recognition?",
  //     okText: "Yes",
  //     cancelText: "No",
  //     onOk: () => {
  //       recognition.lang = language;
  //       setIsRecognitionActive(true);
  //       recognition.start();

  //       recognition.onresult = (event) => {
  //         const transcript = event.results[0][0].transcript.trim();
  //         if (field === "name") {
  //           setName((prev) => prev + " " + transcript);
  //         } else if (field === "referenceAnswer") {
  //           setReferenceAnswer((prev) => prev + " " + transcript);
  //         }
  //       };

  //       recognition.onend = () => {
  //         setIsRecognitionActive(false);
  //         message.success("Speech recognition ended.");
  //       };

  //       recognition.onerror = (event) => {
  //         setIsRecognitionActive(false);
  //         message.error("Speech recognition error: " + event.error);
  //       };
  //     },
  //     onCancel: () => {
  //       message.info("Speech recognition cancelled.");
  //     },
  //   });
  // };

  const handleSpeechInput = (field, index) => {
    if (isRecognitionActive) {
      message.warning("Speech recognition is already in progress!");
      return;
    }
    if (field === "name") {
      Modal.confirm({
        title: "Start Speech Recognition?",
        content: "Are you sure you want to start speech recognition?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          recognition.lang = language;
          setIsRecognitionActive(true);
          recognition.start();

          recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript.trim();
            if (field === "name") {
              setName((prev) => prev + " " + transcript);
            }
          };

          recognition.onend = () => {
            setIsRecognitionActive(false);
            message.success("Speech recognition ended.");
          };

          recognition.onerror = (event) => {
            setIsRecognitionActive(false);
            message.error("Speech recognition error: " + event.error);
          };
        },
        onCancel: () => {
          message.info("Speech recognition cancelled.");
        },
      });
    } else if (field === "referenceAnswer") {
      recognition.lang = language;
      setIsRecognitionActive(true);
      recognition.start();

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript.trim();
        setReferenceAnswer((prev) => prev + " " + transcript);
      };

      recognition.onend = () => {
        setIsRecognitionActive(false);
        message.success("Speech recognition ended.");
      };

      recognition.onerror = (event) => {
        setIsRecognitionActive(false);
        message.error("Speech recognition error: " + event.error);
      };
    }
  };

  return (
    <div>
      <Modal
        title={selectedQuestion ? "Edit Subjective Question" : "Add Question"}
        visible={editSubjectiveQuestionModal}
        footer={false}
        onCancel={() => {
          setEditSubjectiveQuestionModal(false);
          setSelectedQuestion(null);
        }}
        width={1500}
      >
        <Container
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
          maxWidth
        >
          {/* Language Selector */}
          <Select
            value={language}
            onChange={(value) => setLanguage(value)}
            style={{ width: 200, marginBottom: "12px" }}
          >
            <Option value="en-IN">English (India)</Option>
            <Option value="hi-IN">Hindi (India) </Option>
          </Select>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <ReactQuill
                id="name"
                label="Question Name"
                name="name"
                placeholder="Question Name"
                value={name}
                onChange={setName}
              />
            </Box>
            <Button
              onClick={() => handleSpeechInput("name")}
              startIcon={<MicIcon />}
              sx={{ ml: 2 }}
            >
              Speak
            </Button>
          </Box>
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              "& input[type='number']": {
                MozAppearance: "textfield",
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  display: "none",
                },
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="questionNumber"
            label="Question Number"
            name="questionNumber"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            type="Number"
            placeholder="Question Number"
            value={questionNumber}
            onChange={(e) => setQuestionNumber(e.target.value)}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <ReactQuill
                id="referenceAnswer"
                label="Reference Answer"
                name="referenceAnswer"
                placeholder="Reference Answer"
                value={referenceAnswer}
                onChange={setReferenceAnswer}
              />
            </Box>
            <Button
              onClick={() => handleSpeechInput("referenceAnswer")}
              startIcon={<MicIcon />}
              sx={{ ml: 2 }}
            >
              Speak
            </Button>
          </Box>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            className="courses_desc"
            sx={{
              mt: 3,
              borderRadius: "5px",
              textTransform: "none",
              fontFamily: "Poppins, sans-serif",
              letterSpacing: ".1rem",
            }}
          >
            Edit Subjective Question
          </Button>
        </Container>
      </Modal>
    </div>
  );
};

export default EditSubjectiveQuestionModal;
