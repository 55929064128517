import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, menuClasses } from "react-pro-sidebar";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FlagIcon from "@mui/icons-material/Flag";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import LoginIcon from "@mui/icons-material/Login";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NotificationsIcon from "@mui/icons-material/Notifications";
import BusinessIcon from "@mui/icons-material/Business"; // Or by Business
import InfoIcon from "@mui/icons-material/Info";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ArticleIcon from "@mui/icons-material/ArticleOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import PublicIcon from '@mui/icons-material/Public';
import DescriptionIcon from '@mui/icons-material/Description';
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import AssessmentIcon from "@mui/icons-material/Assessment";
import GroupIcon from "@mui/icons-material/Group";
import CardMembership from "@mui/icons-material/CardMembership";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SchoolIcon from "@mui/icons-material/School";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';  
import ShareIcon from '@mui/icons-material/Share';
import { Public } from "@mui/icons-material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { Box, Collapse, List } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FolderIcon from "@mui/icons-material/Folder";
import { logout } from "../../redux/actions/userAction";
import logo from "../../assets/favicon.png";

const SidebarAdm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [mcqExamOpen, setMcqExamOpen] = useState(false);
  const [superAdminOpen, setSuperAdminOpen] = useState(false);
  const [subjectiveExamOpen, setSubjectiveExamOpen] = useState(false);
  const [instituteInformation, setInstituteInformation] = useState(false);
  const [addBatch, setAddBatch] = useState(false);

  // Logout
  const { confirm } = Modal;
  const handleLogout = () => {
    confirm({
      title: "Are you sure you want to Log out?",
      icon: <ExclamationCircleOutlined />,
      content: "Your current session will be terminated.",
      onOk() {
        dispatch(logout());
        localStorage.clear();  
        message.success("Logout Successfully");
        window.location.reload(); 
        navigate("/login");
      },
    });
  };

  return (
    <>
      <Sidebar backgroundColor="white" style={{ borderRightStyle: "none" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box>
            <Menu
              menuItemStyles={{
                button: {
                  [`&.${menuClasses.button}`]: {
                    color: "#000",
                  },
                  [`&.${menuClasses.disabled}`]: {
                    color: "green",
                  },
                  "&:hover": {
                    backgroundColor: "#fafafa",
                    color: "#1976d2",
                  },
                },

                icon: {
                  [`&.${menuClasses.icon}`]: {
                    color: "#1976d2",
                  },
                },
              }}
            >
              <MenuItem
                style={{ marginTop: "20px", color: "#000" }}
                display="flex"
                alignItems="center"
                component={<Link to="/admin/dashboard" />}
              >
                <img
                  src={user.logo || logo}
                  alt="avatar"
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                    marginRight: "10px",
                  }}
                />
                <b> {user.name}</b>
              </MenuItem>
              <MenuItem style={{ color: "#1976d2" }}>{user.email}</MenuItem>
              <hr />
              <MenuItem
                component={<Link to="/admin/dashboard" />}
                icon={<DashboardIcon />}
              >
                {" "}
                Dashboard{" "}
              </MenuItem>
              {/* Only Access Admin */}
              {user && user?.role === 1 ? (
                <>
                  <MenuItem
                    icon={<FolderIcon />}
                    onClick={() => setSuperAdminOpen(!superAdminOpen)}
                  >
                    {" "}
                    Super Admin{" "}
                  </MenuItem>
                  <Collapse in={superAdminOpen}>
                    <List disablePadding>
                      <hr />
                      <MenuItem
                        component={<Link to="/admin/roles" />}
                        icon={<FlagIcon />}
                      >
                        {" "}
                        Roles{" "}
                      </MenuItem>
                      <MenuItem
                        component={<Link to="/admin/permissions" />}
                        icon={<FollowTheSignsIcon />}
                      >
                        {" "}
                        Permissions{" "}
                      </MenuItem>

                      <MenuItem
                        component={<Link to="/admin/create/vendor" />}
                        icon={<GroupAddIcon />}
                      >
                        {" "}
                        Vendors{" "}
                      </MenuItem>

                      <MenuItem
                        component={<Link to="/admin/create/editor/vendor" />}
                        icon={<PersonAddAltIcon />}
                      >
                        {" "}
                        Sub-Vendors{" "}
                      </MenuItem>

                      <MenuItem
                        component={<Link to="/admin/category/permissions" />}
                        icon={<CategoryIcon />}
                      >
                        {" "}
                        Category Permissions{" "}
                      </MenuItem>

                      <MenuItem
                        component={<Link to="/admin/vendor/plans" />}
                        icon={<CardMembership />}
                      >
                        {" "}
                        Vendor Plans{" "}
                      </MenuItem>
                      <MenuItem
                        component={<Link to="/admin/assign/vendor/plans" />}
                        icon={<AssignmentIcon />}
                      >
                        {" "}
                        Assign Vendor Plans{" "}
                      </MenuItem>
                      <hr />
                    </List>
                  </Collapse>
                  <MenuItem
                    icon={<FolderIcon />}
                    onClick={() =>
                      setInstituteInformation(!instituteInformation)
                    }
                  >
                    {" "}
                    Institute Information{" "}
                  </MenuItem>

                  <Collapse in={instituteInformation}>
                    <List disablePadding>
                      <hr />

                      <MenuItem
                        component={<Link to="/admin/institute/info" />}
                        icon={<InfoIcon />}
                      >
                        {" "}
                        Institute Information{" "}
                      </MenuItem>
                      <MenuItem
                        component={<Link to="/admin/institute/course" />}
                        icon={<SchoolIcon />}
                      >
                        {" "}
                        Add Courses{" "}
                      </MenuItem>
                      <MenuItem
                        component={<Link to="/admin/add/teachers" />}
                        icon={<GroupIcon />}
                      >
                        {" "}
                        Add Teachers{" "}
                      </MenuItem>
                      <MenuItem
                        component={<Link to="/admin/job/notification" />}
                        icon={<NotificationsIcon />}
                      >
                        {" "}
                        Job Notification{" "}
                      </MenuItem>
                      <MenuItem
                        component={<Link to="/admin/institute/facilities" />}
                        icon={<BusinessIcon />}
                      >
                        {" "}
                        Facilities{" "}
                      </MenuItem>
                      <MenuItem
                        component={<Link to="/admin/institute/enquiry" />}
                        icon={<HelpOutlineIcon />}
                      >
                        {" "}
                        Enquiry{" "}
                      </MenuItem>
                      <hr />
                    </List>
                  </Collapse>
                </>
              ) : (
                <>
                  {/* Sub-Admin & Other Role Access This Routes */}
                  <MenuItem
                    component={<Link to="/admin/permission/category" />}
                    icon={<FollowTheSignsIcon />}
                  >
                    {" "}
                    Permissions{" "}
                  </MenuItem>
                  <MenuItem
                    icon={<FolderIcon />}
                    onClick={() =>
                      setInstituteInformation(!instituteInformation)
                    }
                  >
                    {" "}
                    Institute Information{" "}
                  </MenuItem>
                  <Collapse in={instituteInformation}>
                    <List disablePadding>
                      <hr />

                      <MenuItem
                        component={<Link to="/admin/institute/info" />}
                        icon={<InfoIcon />}
                      >
                        {" "}
                        Institute Information{" "}
                      </MenuItem>
                      <MenuItem
                    component={<Link to="/admin/create/editor" />}
                    icon={<PersonAddAltIcon />}
                  >
                    {" "}
                    Add Account{" "}
                  </MenuItem>
                      <MenuItem
                        component={<Link to="/admin/institute/course" />}
                        icon={<SchoolIcon />}
                      >
                        {" "}
                        Add Courses{" "}
                      </MenuItem>
                      <MenuItem
                        component={<Link to="/admin/add/teachers" />}
                        icon={<GroupIcon />}
                      >
                        {" "}
                        Add Teachers{" "}
                      </MenuItem>
                      <MenuItem
                        component={<Link to="/admin/job/notification" />}
                        icon={<NotificationsIcon />}
                      >
                        {" "}
                        Job Notification{" "}
                      </MenuItem>
                      <MenuItem
                        component={<Link to="/admin/institute/facilities" />}
                        icon={<BusinessIcon />}
                      >
                        {" "}
                        Facilities{" "}
                      </MenuItem>
                      <MenuItem
                        component={<Link to="/admin/institute/enquiry" />}
                        icon={<HelpOutlineIcon />}
                      >
                        {" "}
                        Enquiry{" "}
                      </MenuItem>
                      <hr />
                    </List>
                  </Collapse>
                </>
              )}
              {/* All Role access this routes */}
              <MenuItem
                icon={<FolderIcon />}
                onClick={() => setAddBatch(!addBatch)}
              >
                {" "}
                Batch{" "}
              </MenuItem>
              <Collapse in={addBatch}>
                <List disablePadding>
                  <hr />
                  <MenuItem
                    component={<Link to="/admin/batch" />}
                    icon={<GroupIcon />}
                  >
                    {" "}
                    Add Batch{" "}
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/admin/institute/all/student" />}
                    icon={<AccountCircleIcon />}
                  >
                    {" "}
                    Student List{" "}
                  </MenuItem>
                  <hr />
                </List>
              </Collapse>
              <MenuItem
                icon={<FolderIcon />}
                onClick={() => setMcqExamOpen(!mcqExamOpen)}
              >
                {" "}
                MCQ Exam{" "}
              </MenuItem>
              <Collapse in={mcqExamOpen}>
                <List disablePadding>
                  <hr />
                  <MenuItem
                    component={<Link to="/admin/exams" />}
                    icon={<ArticleIcon />}
                  >
                    {" "}
                    Exams List{" "}
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/admin/public/exams" />}
                    icon={<PublicIcon />}
                  >
                    {" "}
                    Public Exams List{" "}
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/admin/add/exam" />}
                    icon={<PostAddIcon />}
                  >
                    {" "}
                    Add Exam & Question{" "}
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/admin/reports" />}
                    icon={<BarChartIcon />}
                  >
                    {" "}
                    Reports{" "}
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/admin/public/exam/reports" />}
                    icon={<DescriptionIcon />}
                  >
                    {" "}
                    Public Exam Reports{" "}
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/admin/top/rank" />}
                    icon={<MilitaryTechIcon />}
                  >
                    {" "}
                    Top Rank{" "}
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/admin/student/share/all/report" />}
                    icon={<ShareIcon />}
                  >
                    {" "}
                   Share Report{" "}
                  </MenuItem>
                  <hr />
                </List>
              </Collapse>
              <MenuItem
                icon={<FolderIcon />}
                onClick={() => setSubjectiveExamOpen(!subjectiveExamOpen)}
              >
                {" "}
                Subjective Exam{" "}
              </MenuItem>
              <Collapse in={subjectiveExamOpen}>
                <List disablePadding>
                  <hr />
                  <MenuItem
                    component={<Link to="/admin/subjective/exams" />}
                    icon={<ArticleIcon />}
                  >
                    {" "}
                    Exams List{" "}
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/admin/add/subjective/exam" />}
                    icon={<PostAddIcon />}
                  >
                    {" "}
                    Add Exam & Question{" "}
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/admin/subjective/evaluation" />}
                    icon={<AssessmentIcon />}
                  >
                    {" "}
                    Evaluation{" "}
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/admin/subjective/reports" />}
                    icon={<BarChartIcon />}
                  >
                    {" "}
                    Reports{" "}
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/admin/subjective/top/rank" />}
                    icon={<MilitaryTechIcon />}
                  >
                    {" "}
                    Top Rank{" "}
                  </MenuItem>
                  <hr />
                </List>
              </Collapse>

              <MenuItem
                component={<Link to="/admin/files" />}
                icon={<PictureAsPdfIcon />}
              >
                {" "}
                PDF Notes{" "}
              </MenuItem>
              <MenuItem
                component={<Link to="/admin/courses" />}
                icon={<SlowMotionVideoIcon />}
              >
                {" "}
                Course Videos{" "}
              </MenuItem>
              <MenuItem
                component={<Link to="/admin/contact" />}
                icon={<ContactsOutlinedIcon />}
              >
                {" "}
                Contacts{" "}
              </MenuItem>
              <MenuItem
                component={<Link to="/admin/new/course/request" />}
                icon={<MenuBookIcon />}
              >
                {" "}
                New Course Request{" "}
              </MenuItem>
              <MenuItem
                component={<Link to="/admin/public/student" />}
                icon={<Public />}
              >
                {" "}
                Public Student List{" "}
              </MenuItem>
              <MenuItem
                component={<Link to="/admin/profile" />}
                icon={<ManageAccountsIcon />}
              >
                {" "}
                Manage Accounts{" "}
              </MenuItem>
            </Menu>
          </Box>

          {/* Log Out  */}
          <Box sx={{ pb: 2 }}>
            <Menu
              menuItemStyles={{
                button: {
                  [`&.${menuClasses.button}`]: {
                    color: "#000",
                  },

                  "&:hover": {
                    backgroundColor: "#fafafa",
                    color: "#1976d2",
                  },
                },

                icon: {
                  [`&.${menuClasses.icon}`]: {
                    color: "#1976d2",
                  },
                },
              }}
            >
              <hr />
              <MenuItem onClick={handleLogout} icon={<LoginIcon />}>
                {" "}
                Log out{" "}
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Sidebar>
    </>
  );
};

export default SidebarAdm;