import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Card,
  CardContent,
  Grid,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import moment from "moment";
import { message, Modal } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../axiosInstance";
import PageTitle from "../../Components/PageTitle/PageTitle";
import EditFacilities from "./EditFacilities";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Facilities = () => {
  const [value, setValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  const [facilities, setFacilities] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [fields, setFields] = useState([{ id: Date.now(), value: "" }]);

  // Function to handle adding a new TextField
  const handleAddField = () => {
    setFields([...fields, { id: Date.now(), value: "" }]);
  };

  // Function to handle removing a TextField by ID
  const handleRemoveField = (id) => {
    setFields(fields.filter((field) => field.id !== id));
  };

  // Function to handle text field value change
  const handleFieldChange = (id, value) => {
    const updatedFields = fields.map((field) =>
      field.id === id ? { ...field, value } : field
    );
    setFields(updatedFields);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const facilitiesData = {
      facilitiesName: fields.map((field) => field.value),
      userId: user._id,
      instituteId: user.instituteId,
    };
    try {
      const response = await axios.post(
        "/api/v1/vendor/admin-add-facilities-institute",
        facilitiesData
      );
      if (response.data.success) {
        message.success(response.data.message);
        // Reset form if needed
        setFields([{ id: Date.now(), value: "" }]);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  //  Get All Institute Facilities
  const getAllInstituteFacilities = async () => {
    try {
      const response = await axios.get(
        "/api/v1/vendor/admin-get-facilities-institute"
      );
      if (response.data.success) {
        setFacilities(response.data.data);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getAllInstituteFacilities();
  }, []);

  // Function to handle delete action
  const handleDelete = async (facilityId) => {
    try {
      Modal.confirm({
        title: "Are you sure you want to delete this all facility?",
        content:
          "You are about to delete the all facility names. This action cannot be undone.",
        onOk: async () => {
          const response = await axios.delete(
            `/api/v1/vendor/admin-delete-facilities-institute`,
            {
              data: { facilityId },
            }
          );
          message.success(response.data.message);
          getAllInstituteFacilities();
        },
      });
    } catch (error) {
      message.error(
        error.response?.data?.message || "Failed to delete facility"
      );
    }
  };

  // Handle facility deletion with confirmation
  const handleDeleteFacility = (facilityId, index, facilityName) => {
    Modal.confirm({
      title: "Are you sure you want to delete this facility?",
      content: `Facility: ${facilityName}. This action cannot be undone`,
      onOk: async () => {
        try {
          const response = await axios.delete(
            `/api/v1/vendor/admin-delete-facilities-name-institute`,
            {
              data: {
                facilityId,
                index,
                facilityName,
              },
            }
          );
          message.success(response.data.message);
          getAllInstituteFacilities(); 
        } catch (error) {
          message.error(
            error.response?.data?.message || "Failed to delete facility"
          ); 
        }
      },
    });
  };
  return (
    <>
      <PageTitle title="Admin Facilities | Test Series" />
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          mb: 3,
          fontFamily: "Poppins, sans-serif",
          color: "#2C3E50",
          letterSpacing: "2.5px",
          lineHeight: 1.8,
        }}
      >
        Facilities
      </Typography>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          mb: 3,
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        <MUILink
          component={Link}
          to="/"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Dashboard
        </MUILink>
        <MUILink
          component={Link}
          to="/admin/institute/info"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Institute Information
        </MUILink>
        <Typography sx={{ color: "primary.main" }}>Facilities</Typography>
      </Breadcrumbs>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Facilities List" {...TabPanel(0)} />
          <Tab label="Add Facilities" {...TabPanel(1)} />
        </Tabs>
      </Box>

      {/* Facilities List */}
      <CustomTabPanel value={value} index={0}>
        <Typography
          variant="h6"
          sx={{
            pb: 2,
            pt: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#555",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Facilities List
        </Typography>
        {/* <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card
                sx={{
                  height: "100%",
                  maxHeight: "500px",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "12px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  overflow: "hidden",
                }}
              >
                <CardContent sx={{ flexGrow: 1, overflowY: "auto" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 500, color: "#1976d2" }}
                    >
                      Institute Facilities
                    </Typography>
                    <Box>
                      <IconButton
                        color="secondary"
                        onClick={() =>
                          handleDelete(facilities.map((f) => f._id))
                        } 
                      >
                        <DeleteIcon />
                      </IconButton>
                      <EditFacilities
                        facilityId={
                          facilities.length > 0 ? facilities[0]._id : ""
                        }
                        facilities={
                          facilities.length > 0
                            ? facilities[0].facilitiesName
                            : []
                        }
                        refreshData={getAllInstituteFacilities}
                      />
                    </Box>
                  </Box>
                  {facilities.length > 0 ? (
                    <List>
                      {facilities.map((facility) => (
                        <React.Fragment key={facility._id}>
                          <ListItem
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              borderBottom: "1px solid #e0e0e0",
                              padding: "16px 0",
                            }}
                          >
                            
                            <ListItemText
                              primary={`Institute ID: ${facility.instituteId}`}
                              secondary={
                                <>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    component="span"
                                  >
                                    Facilities:
                                  </Typography>
                                  <Typography variant="body2" component="span">
                                    {facility.facilitiesName.join(", ")}
                                  </Typography>
                                </>
                              }
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      ))}
                    </List>
                  ) : (
                    <Typography variant="body2" sx={{ color: "#777" }}>
                      No facilities found.
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container> */}

        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card
                sx={{
                  height: "100%",
                  maxHeight: "500px",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "12px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  overflow: "hidden",
                }}
              >
                <CardContent sx={{ flexGrow: 1, overflowY: "auto" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <IconButton
                        color="secondary"
                        onClick={() =>
                          handleDelete(facilities?.map((f) => f._id))
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                      <EditFacilities
                        facilityId={
                          facilities.length > 0 ? facilities[0]._id : ""
                        }
                        facilities={
                          facilities.length > 0
                            ? facilities[0].facilitiesName
                            : []
                        }
                        refreshData={getAllInstituteFacilities}
                      />
                    </Box>
                  </Box>
                  {facilities.length > 0 ? (
                    <List>
                      {facilities.map((facility) =>
                        facility.facilitiesName.map((name, index) => (
                          <React.Fragment key={`${facility._id}-${index}`}>
                            <ListItem
                              secondaryAction={
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  color="secondary"
                                  onClick={() =>
                                    handleDeleteFacility(
                                      facility._id,
                                      index,
                                      name
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              }
                            >
                              <ListItemText primary={name} />
                              {user && user?.role===1 ?(
                                <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  mr:2
                                }}
                              >
                                <ListItemText
                                  secondary={facility.instituteId}
                                />
                              </Box>
                              ): null}
                              
                             
                            </ListItem>
                            {index < facility.facilitiesName.length - 1 && (
                              <Divider />
                            )}
                          </React.Fragment>
                        ))
                      )}
                    </List>
                  ) : (
                    <Typography variant="body2" sx={{ color: "#777" }}>
                      No facilities found.
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </CustomTabPanel>

      {/* Create a New Facilities */}
      <CustomTabPanel value={value} index={1} maxWidth>
        <Typography
          variant="h6"
          sx={{
            pb: 2,
            pt: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#555",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Create a new Facilities
        </Typography>
        <Container
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
          maxWidth
        >
          {/* Render dynamic text fields */}
          {fields.map((field, index) => (
            <div
              key={field.id}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <TextField
                sx={{
                  mb: 3,
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
                fullWidth
                id={`facilities_${index}`}
                label={`Facilities Name ${index + 1} *`}
                name={`facilities_${index}`}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                placeholder="Facilities Name"
                value={field.value}
                onChange={(e) => handleFieldChange(field.id, e.target.value)}
              />

              {/* Show Remove button only if there are more than 1 fields */}
              {fields.length > 1 && (
                <Button
                  sx={{
                    ml: 1,
                    mb: 3,
                    borderRadius: "5px",
                    textTransform: "none",
                    fontFamily: "Poppins, sans-serif",
                  }}
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleRemoveField(field.id)}
                >
                  Remove
                </Button>
              )}

              {/* Show Add button only for the last field */}
              {index === fields.length - 1 && (
                <Button
                  sx={{
                    ml: 1,
                    mb: 3,
                    borderRadius: "5px",
                    textTransform: "none",
                    fontFamily: "Poppins, sans-serif",
                    letterSpacing: ".1rem",
                  }}
                  variant="outlined"
                  color="primary"
                  onClick={handleAddField}
                >
                  Add
                </Button>
              )}
            </div>
          ))}

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="courses_desc"
            sx={{
              mt: 3,
              borderRadius: "5px",
              textTransform: "none",
              fontFamily: "Poppins, sans-serif",
              letterSpacing: ".1rem",
            }}
          >
            Add Facilities
          </Button>
        </Container>
      </CustomTabPanel>
    </>
  );
};

export default Facilities;
