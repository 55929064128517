import React, { useState } from "react";
import { message, Modal } from "antd";
import {
  Container,
  Box,
  TextField,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "../../axiosInstance";

const EditInstituteInfo = ({
  refreshData,
  instituteId,
  instituteInfo,
  vendorId,
  instituteName,
  instituteDescription,
  instituteLogo,
  instituteAddress,
  institutePhone,
  instituteEmail,
}) => {
  const [institute_Name, setInstitute_Name] = useState(instituteName);
  const [description, setDescription] = useState(instituteDescription);
  const [address, setAddress] = useState(instituteAddress);
  const [phone, setPhone] = useState(institutePhone);
  const [email, setEmail] = useState(instituteEmail);
  const [image, setImage] = useState(instituteLogo || "");
  const [imageBase64, setImageBase64] = useState(instituteLogo);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Upload button functionality
  const VisuallyHiddenInput = styled("input")({
    display: "none",
  });

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Create a URL for preview
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
      // Convert image to base64
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageBase64(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Edit Institute Information
  const { confirm } = Modal;
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    confirm({
      title: "Are you sure you want to edit this Institute's Details?",
      content: "This action will update the institute information.",
      okText: "Yes, Edit",
      cancelText: "No, Cancel",
      okType: "primary",
      onOk: async () => {
        const formData = new FormData();
        formData.append("id", instituteInfo);
        formData.append("instituteName", institute_Name);
        formData.append("description", description);
        formData.append("vendorId", vendorId);
        formData.append("instituteId", instituteId);
        formData.append("instituteAddress", address);
        formData.append("institutePhone", phone);
        formData.append("instituteEmail", email);
        formData.append("instituteLogo", imageBase64);

        try {
          // API call to update institute information
          const { data } = await axios.put(
            "/api/v1/vendor/admin-edit-about-institute",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (data?.success === true) {
            message.success(data?.message);
            refreshData();
            setIsModalOpen(false);
          } else {
            message.error(data?.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message ||
              "Error updating institute details."
          );
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        setLoading(false);
        console.log("Edit institute details canceled");
      },
    });
  };

  return (
    <>
      <IconButton aria-label="edit" onClick={showModal}>
        <EditIcon sx={{ color: "#1976d2" }} />
      </IconButton>
      <Modal
        title="Edit Institute Details"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        width={1000}
      >
        <Container
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
          maxWidth
        >
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="institute_Name"
            label="Institute Name"
            name="institute_Name"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Institute Name"
            value={institute_Name}
            onChange={(e) => setInstitute_Name(e.target.value)}
          />

          {/* <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="instituteDescription"
            label="Institute Description"
            name="instituteDescription"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            multiline
            placeholder="Institute Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          /> */}
          <h4 style={{color:"#2C3E50"}}> Short Description In Institute</h4>

          <ReactQuill
            value={description}
            onChange={setDescription}
            placeholder="Short Description In Institute"
            style={{
              // height: "200px",
              marginBottom: "30px",
              border: "1px solid rgba(0,0,0,0.1)",
              borderRadius: "10px",
            }}
          />

          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              "& input[type='number']": {
                MozAppearance: "textfield",
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  display: "none",
                },
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            type="email"
            id="instituteEmail"
            label="Institute Email"
            name="instituteEmail"
            InputLabelProps={{
              shrink: true,
            }}
            required
            margin="normal"
            placeholder="Institute Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              "& input[type='number']": {
                MozAppearance: "textfield",
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  display: "none",
                },
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            type="number"
            id="institutePhone"
            label="Institute Phone Number"
            name="institutePhone"
            InputLabelProps={{
              shrink: true,
            }}
            required
            margin="normal"
            placeholder="Institute Phone Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />

          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="instituteAddress"
            label="Institute Address"
            name="instituteAddress"
            InputLabelProps={{
              shrink: true,
            }}
            required
            margin="normal"
            multiline
            placeholder="Institute Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />

          <Box sx={{ mt: 3 }}>
            <VisuallyHiddenInput
              accept="image/*"
              id="contained-button-file"
              type="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="outlined"
                color="primary"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Upload Institute logo
              </Button>
            </label>
            {image && (
              <Box mt={3} display="flex" alignItems="center">
                <img
                  src={image}
                  alt="avatar"
                  style={{
                    height: "100px",
                    width: "100px",
                    borderRadius: "50%",
                  }}
                />
              </Box>
            )}
          </Box>
          <Button
            variant="contained"
            type="submit"
            disabled={loading}
            color="primary"
            className="courses_desc"
            sx={{
              mt: 3,
              borderRadius: "5px",
              textTransform: "none",
              fontFamily: "Poppins, sans-serif",
              letterSpacing: ".1rem",
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Edit Institute Info"}
          </Button>
        </Container>
      </Modal>
    </>
  );
};

export default EditInstituteInfo;
