import React from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { Link } from "react-router-dom";


const StudentGroupReportSharePreview = () => {
  const location = useLocation();
  const { commonData, reportDataArray } = location.state || {};

  const totalMarks =
    reportDataArray?.reduce((acc, curr) => acc + curr.totalMarks, 0) || 0;
  const marksObtained =
    reportDataArray?.reduce((acc, curr) => acc + curr.marksObtained, 0) || 0;
  const percentage = (marksObtained / totalMarks) * 100;

  const getTotalGrade = (percentage) => {
    if (percentage >= 90) return "A+";
    if (percentage >= 80) return "A";
    if (percentage >= 70) return "B+";
    if (percentage >= 60) return "B";
    return "C";
  };

  const rank = percentage >= 85 ? "1st" : percentage >= 75 ? "2nd" : "3rd";

  return (
    <Box sx={{ padding: 3 }}>
     <Typography variant="h5" sx={{ mb: 3 }} align="center" gutterBottom>
          Student Group Report Preview
        </Typography>
      {/* Breadcrumbs and Paper UI */}
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          mb: 5,
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        <MUILink
          component={Link}
          to="/"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Dashboard
        </MUILink>
        <MUILink
          component={Link}
          to="/admin/student/share/all/report"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Reports
        </MUILink> 
        <MUILink
          component={Link}
          to="/admin/student/share/group/report"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
         Share Reports
        </MUILink>
       
        <Typography sx={{ color: "primary.main" }}>
          {" "}
        Report Preview{" "}
        </Typography>
      </Breadcrumbs>
      <Container
        maxWidth="sm"
        sx={{
          bgcolor: "#f9f9f9",
          p: 3,
          borderRadius: 2,
          border: "2px solid #ccc",
        }}
      >
        <Box textAlign="center" mb={3} mt={2}>
          {commonData?.logo && (
            <img
              src={commonData.logo}
              alt="Institute Logo"
              style={{ maxWidth: 150, marginRight: 20 }}
            />
          )}
          <Box flexGrow={1} textAlign="center" mt={3}>
            <Typography
              variant="h5"
              fontWeight="bold"
              style={{
                letterSpacing: ".1rem",
                fontFamily: "Poppins, sans-serif",
                lineHeight: "1.8rem",
                marginBottom: "12px",
              }}
              gutterBottom
            >
              {commonData?.instituteName}
            </Typography>
            <Typography
              variant="body1"
              style={{
                letterSpacing: ".1rem",
                fontFamily: "Poppins, sans-serif",
                lineHeight: "1.5rem",
              }}
            >
              Address: {commonData?.instituteAddress}
            </Typography>
            <Typography
              variant="body1"
              style={{
                letterSpacing: ".1rem",
                fontFamily: "Poppins, sans-serif",
                lineHeight: "1.5rem",
              }}
            >
              Phone: {commonData?.institutePhone}, Email:{" "}
              {commonData?.instituteEmail}
            </Typography>
          </Box>
        </Box>

        <Box textAlign="center" mb={3}>
          <Typography variant="h6" style={{ letterSpacing: ".1rem" }}>
            Report Card
          </Typography>
          {/* <Typography style={{ letterSpacing: ".1rem" }}>Course: {commonData?.courseClass}</Typography> */}
          <Typography style={{ letterSpacing: ".1rem" }}>
            Academic Session: {commonData?.academicSession}
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          mb={3}
          p={2}
          borderRadius={1}
        >
          <Box>
            <Typography variant="body1">
              <strong>Student Name:</strong> {commonData?.userName}
            </Typography>
            <Typography variant="body1">
              <strong>Parent Name:</strong> {commonData?.parentName}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant="h6" mb={2} style={{ letterSpacing: ".1rem" }}>
            Scores
          </Typography>
          <TableContainer
            component={Paper}
            sx={{ boxShadow: 3, borderRadius: 3 }}
          >
            <Table>
              <TableHead>
                <TableRow bgcolor={"#d5d5d5"}>
                  <TableCell style={{ fontWeight: "bold" }}>Subject</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Total Marks
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Marks Obtained
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Grade</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportDataArray?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.subject}</TableCell>
                    <TableCell>{row.totalMarks}</TableCell>
                    <TableCell>{row.marksObtained.toFixed(2)}</TableCell>
                    <TableCell>{row.grade}</TableCell>
                  </TableRow>
                ))}
                <TableRow bgcolor={"#f2f2f2"}>
                  <TableCell>Total</TableCell>
                  <TableCell>{totalMarks}</TableCell>
                  <TableCell>{marksObtained.toFixed(2)}</TableCell>
                  <TableCell>{getTotalGrade(percentage)}</TableCell>
                </TableRow>
                <TableRow bgcolor={"#d5d5d5"}>
                  <TableCell>Overall Marks</TableCell>
                  <TableCell>
                    {marksObtained.toFixed(2)} / {totalMarks}
                  </TableCell>
                  <TableCell>Total Grade</TableCell>
                  <TableCell>{getTotalGrade(percentage)}</TableCell>
                </TableRow>
                <TableRow bgcolor={"#f2f2f2"}>
                  <TableCell>Percentage</TableCell>
                  <TableCell>{percentage.toFixed(2)}%</TableCell>
                  <TableCell>Rank</TableCell>
                  <TableCell>{rank}</TableCell>
                </TableRow>
                <TableRow bgcolor={"#e2e2e2"}>
                  <TableCell>Result</TableCell>
                  <TableCell>
                    {marksObtained >= totalMarks * 0.4 ? "Pass" : "Fail"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box mt={5}>
          <Typography mb={3}>{commonData?.comments}</Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1" mt={2}>
            <strong
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              Date
            </strong>{" "}
            {commonData?.reportDate}
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            <strong
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              Class Teacher
            </strong>{" "}
            {commonData?.classTeacher}
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            <strong
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              Principal
            </strong>
            {commonData?.principalSignature}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default StudentGroupReportSharePreview;
