/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { message, Modal, Select } from "antd";
import {
  Container,
  Box,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material";
import axios from "../../axiosInstance";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AddIcon from "@mui/icons-material/Add";
import MicIcon from "@mui/icons-material/Mic";
import DeleteIcon from "@mui/icons-material/Delete";
import imageCompression from "browser-image-compression";

const { Option } = Select;

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();

// Utility function to convert file to base64
const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

const EditQuestionModal = ({
  editQuestionModal,
  setEditQuestionModal,
  refreshData,
  examId,
  selectedQuestion,
  setSelectedQuestion,
}) => {
  const id = selectedQuestion?._id;
  const [name, setName] = useState([]);
  const [questionNameImages, setQuestionNameImages] = useState([]);
  const [correctOption, setCorrectOption] = useState([]);
  const [options, setOptions] = useState([]);
  // const [options, setOptions] = useState([
  //   { text: "", images: [] },
  //   { text: "", images: [] },
  //   { text: "", images: [] },
  //   { text: "", images: [] },
  // ]);
  const [instituteId, setInstituteId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [isRecognitionActive, setIsRecognitionActive] = useState(false);
  const [language, setLanguage] = useState("en-IN");

  // const handleAddMore = () => {
  //   setOptions([...options, ""]);
  // };

  const handleAddMore = () => {
    setOptions((prevOptions) => [...prevOptions, { text: "", images: [] }]);
  };

  const handleDeleteInputs = (index) => {
    confirm({
      title: "Are you sure you want to delete this option?",
      content:
        "Deleting this option will update the list of available options and the correct answers.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: () => {
        // Update options array
        const newOptions = options.filter((_, i) => i !== index);
        setOptions(newOptions);

        // Update correctOption array
        const updatedCorrectOption = correctOption.filter(
          (optIndex) => optIndex !== index
        );
        const adjustedCorrectOption = updatedCorrectOption.map((optIndex) =>
          optIndex > index ? optIndex - 1 : optIndex
        );

        setCorrectOption(adjustedCorrectOption);
      },
      onCancel: () => {
        // Do nothing if the user cancels
        console.log("Deletion cancelled");
      },
    });
  };

  const handleChangeCorrectOption = (isChecked, index) => {
    if (isChecked) {
      setCorrectOption((prev) => [...prev, index]);
    } else {
      setCorrectOption((prev) => prev.filter((optIndex) => optIndex !== index));
    }
  };

  // const handleChangeOptions = (newValue, index) => {
  //   setOptions((prev) => {
  //     const updatedOptions = [...prev];
  //     updatedOptions[index] = {
  //       ...updatedOptions[index],
  //       text: newValue,
  //     };
  //     return updatedOptions;
  //   });
  // };

  // Get Exams By QuestionID
  const getExamsByQuestionID = async () => {
    try {
      const response = await axios.post(
        `/api/v1/question/admin-get-exam-to-questions`,
        { id }
      );
      const fetchedData = response?.data?.data;
      // Set name as it is (Array of text and images)
      setName(fetchedData?.name || []);
      setQuestionNameImages(
        fetchedData?.name
          ?.filter((item) => item.type === "image")
          .map((item) => item.content) || []
      );
      setCorrectOption(fetchedData?.correctOption);
      setOptions(fetchedData?.options);
      setInstituteId(fetchedData?.instituteId);
      setVendorId(fetchedData?.vendorId);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (id) getExamsByQuestionID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Add
  const { confirm } = Modal;
  const handleSubmit = async (e) => {
    e.preventDefault();
    confirm({
      title: "Are you sure you want to Edit this Question?",
      content:
        "Please verify that all the details are correct before updating.",
      okText: "Yes, Edit",
      cancelText: "No, Cancel",
      onOk: async () => {
        const updatedName =
          questionNameImages?.length > 0
            ? [...(name || []), [...(questionNameImages || [])]]
            : name || [];

        const payload = {
          name: updatedName,
          correctOption,
          options,
          questionId: id,
          exam: examId,
          id,
          instituteId,
          vendorId,
        };
        try {
          const response = await axios.put(
            "/api/v1/question/admin-update-exam-to-questions",
            payload
          );
          if (response.data.success) {
            message.success(response?.data?.message);
            refreshData();
            setEditQuestionModal(false);
          } else {
            message.error(response?.data?.message);
          }
          setSelectedQuestion(null);
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to edit the question."
          );
        }
      },
    });
  };

  // Function to handle speech input
  // const handleSpeechInput = (field, index) => {
  //   if (isRecognitionActive) {
  //     message.warning("Speech recognition is already in progress!");
  //     return;
  //   }
  //   Modal.confirm({
  //     title: "Start Speech Recognition?",
  //     content: "Are you sure you want to start speech recognition?",
  //     okText: "Yes",
  //     cancelText: "No",
  //     onOk: () => {
  //       recognition.lang = language;
  //       setIsRecognitionActive(true);
  //       recognition.start();

  //       recognition.onresult = (event) => {
  //         const transcript = event.results[0][0].transcript.trim();
  //         if (field === "question") {
  //           setName((prev) => prev + " " + transcript);
  //         } else if (field === "option") {
  //           const updatedOptions = [...options];
  //           updatedOptions[index] = updatedOptions[index] + " " + transcript;
  //           setOptions(updatedOptions);
  //         }
  //       };

  //       recognition.onend = () => {
  //         setIsRecognitionActive(false);
  //         message.success("Speech recognition ended.");
  //       };

  //       recognition.onerror = (event) => {
  //         setIsRecognitionActive(false);
  //         message.error("Speech recognition error: " + event.error);
  //       };
  //     },
  //     onCancel: () => {
  //       message.info("Speech recognition cancelled.");
  //     },
  //   });
  // };

  // const handleSpeechInput = (field, index) => {
  //   if (isRecognitionActive) {
  //     message.warning("Speech recognition is already in progress!");
  //     return;
  //   }

  //   // Show the modal only for the "question" field
  //   if (field === "question") {
  //     Modal.confirm({
  //       title: "Start Speech Recognition?",
  //       content: "Are you sure you want to start speech recognition?",
  //       okText: "Yes",
  //       cancelText: "No",
  //       onOk: () => {
  //         recognition.lang = language;
  //         setIsRecognitionActive(true);
  //         recognition.start();
  //         recognition.onresult = (event) => {
  //           const transcript = event.results[0][0].transcript.trim();
  //           if (field === "question") {
  //             setName((prev) => [
  //               ...prev, // Preserve existing items
  //               { type: "text", content: transcript },
  //             ]);
  //           }
  //         };
  //         recognition.onend = () => {
  //           setIsRecognitionActive(false);
  //           message.success("Speech recognition ended.");
  //         };
  //         recognition.onerror = (event) => {
  //           setIsRecognitionActive(false);
  //           message.error("Speech recognition error: " + event.error);
  //         };
  //       },
  //       onCancel: () => {
  //         message.info("Speech recognition cancelled.");
  //       },
  //     });
  //   } else if (field === "option") {
  //     // Directly start recognition without showing the modal for "option"
  //     recognition.lang = language;
  //     setIsRecognitionActive(true);
  //     recognition.start();
  //     recognition.onresult = (event) => {
  //       const transcript = event.results[0][0].transcript.trim();
  //       const updatedOptions = [...options];
  //       updatedOptions[index] = updatedOptions[index] + " " + transcript;
  //       setOptions(updatedOptions);
  //     };
  //     recognition.onend = () => {
  //       setIsRecognitionActive(false);
  //       message.success("Speech recognition ended.");
  //     };
  //     recognition.onerror = (event) => {
  //       setIsRecognitionActive(false);
  //       message.error("Speech recognition error: " + event.error);
  //     };
  //   }
  // };

  const handleSpeechInput = (field, index) => {
    if (isRecognitionActive) {
      message.warning("Speech recognition is already in progress!");
      return;
    }
    const confirmStart = field === "question";
    const startRecognition = () => {
      recognition.lang = language;
      setIsRecognitionActive(true);
      recognition.start();

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript.trim();
        if (field === "question") {
          setName((prev) => [...prev, { type: "text", content: transcript }]);
        } else if (field === "option") {
          setOptions((prev) => {
            const updated = [...prev];
            updated[index].text += ` ${transcript}`;
            return updated;
          });
        }
      };

      recognition.onend = () => {
        setIsRecognitionActive(false);
        message.success("Speech recognition ended.");
      };

      recognition.onerror = (event) => {
        setIsRecognitionActive(false);
        message.error("Speech recognition error: " + event.error);
      };
    };

    if (confirmStart) {
      Modal.confirm({
        title: "Start Speech Recognition?",
        content: "Are you sure you want to start speech recognition?",
        okText: "Yes",
        cancelText: "No",
        onOk: startRecognition,
      });
    } else {
      startRecognition();
    }
  };

  // Utility function to check image size before uploading
  const MAX_IMAGE_SIZE = 500 * 1024;

  const handleImagesChange = async (event, setImages) => {
    const files = Array.from(event.target.files);
    const compressedImages = await Promise.all(
      files.map(async (file) => {
        if (file.type.startsWith("image/")) {
          if (file.size > MAX_IMAGE_SIZE) {
            message.warning("File size exceeds the 500 KB limit.");
            return null;
          }
          const compressedFile = await imageCompression(file, {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 800,
          });
          return convertFileToBase64(compressedFile);
        } else {
          message.warning("Only image files are allowed");
          return null;
        }
      })
    );
    setImages((prevImages) => [
      ...prevImages,
      ...compressedImages.filter(Boolean),
    ]);
  };

  const handleRemoveImage = (index, setImages) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleOptionImagesChange = async (e, index) => {
    const files = Array.from(e.target.files);

    const base64Images = await Promise.all(
      files.map(async (file) => {
        if (file.type.startsWith("image/")) {
          if (file.size > MAX_IMAGE_SIZE) {
            message.warning("File size exceeds the 500 KB limit.");
            return null;
          }
          const compressedFile = await imageCompression(file, {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 800,
          });
          return convertFileToBase64(compressedFile);
        } else {
          message.warning("Only image files are allowed.");
          return null;
        }
      })
    );

    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index].images = [
        ...(updatedOptions[index].images || []),
        ...base64Images.filter(Boolean),
      ];
      return updatedOptions;
    });
  };

  const handleRemoveOptionImage = (optionIndex, imageIndex) => {
    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[optionIndex].images.splice(imageIndex, 1);
      return updatedOptions;
    });
  };

  return (
    <div>
      <Modal
        title={selectedQuestion ? "Edit Question" : "Add Question"}
        open={editQuestionModal}
        footer={false}
        onCancel={() => {
          setEditQuestionModal(false);
          setSelectedQuestion(null);
        }}
        width={1500}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            pb: 1,
          }}
        >
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
          >
            {/* Language Selector */}
            <Select
              value={language}
              onChange={(value) => setLanguage(value)}
              style={{ width: 200, marginBottom: "12px" }}
            >
              <Option value="en-IN">English (India)</Option>
              <Option value="hi-IN">Hindi (India) </Option>
            </Select>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {/* Display Combined Text and Images */}
              <ReactQuill
                id="name"
                label="Question"
                name="name"
                placeholder="Question"
                value={name
                  .filter((item) => item.type === "text")
                  .map((item) => item.content)
                  .join(" ")}
                onChange={(value) => {
                  const updatedName = [
                    ...name.filter((item) => item.type !== "text"),
                  ];
                  updatedName.push({ type: "text", content: value });
                  setName(updatedName);
                }}
              />

              {/* Image Upload Section */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Button
                  onClick={() => handleSpeechInput("question")}
                  startIcon={<MicIcon />}
                >
                  Speak
                </Button>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={(e) => handleImagesChange(e, setQuestionNameImages)}
                  style={{ display: "none" }}
                  id={`image-upload`}
                />
                <label htmlFor={`image-upload`}>
                  <Button variant="outlined" component="span">
                    Choose File
                  </Button>
                </label>
                {/* Image Previews */}
                <Box display="flex" gap={1} flexWrap="wrap">
                  {questionNameImages?.map((src, index) => (
                    <Box key={index} position="relative" display="inline-block">
                      <img
                        src={src}
                        alt={`Title Preview ${index + 1}`}
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "4px",
                        }}
                      />
                      <IconButton
                        onClick={() =>
                          handleRemoveImage(index, setQuestionNameImages)
                        }
                        color="error"
                        size="small"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>

            <Grid container spacing={4} rowSpacing={2} columnSpacing={4}>
              {/* {options.map((option, i) => ( */}
              {(options || []).map((option, i) => (
                <Grid item xs={12} sm={6} md={6} key={i}>
                  <Box sx={{ mb: 2, fontWeight: 600 }}>
                    Option {String.fromCharCode(65 + i)}:
                  </Box>
                  {/* <ReactQuill
                    theme="snow"
                    value={options[i]?.text || ""}
                    onChange={(value) => handleChangeOptions(value, i)}
                    placeholder="Enter option text"
                    style={{
                      marginBottom: "16px",
                      border: "1px solid rgb(231, 235, 240)",
                      borderRadius: "4px",
                    }}
                  /> */}
                  <ReactQuill
                    theme="snow"
                    id={`option-${i}`}
                    placeholder={`Option ${i + 1}`}
                    value={option.text}
                    onChange={(value) => {
                      const updatedOptions = [...options];
                      updatedOptions[i] = { ...updatedOptions[i], text: value };
                      setOptions(updatedOptions);
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={correctOption.includes(i)}
                          onChange={(e) =>
                            handleChangeCorrectOption(e.target.checked, i)
                          }
                        />
                      }
                      label="Correct Option"
                    />
                    {options.length > 1 && (
                      <Button
                        onClick={() => handleDeleteInputs(i)}
                        color="error"
                      >
                        Remove
                      </Button>
                    )}
                    <Button
                      onClick={() => handleSpeechInput("option", i)}
                      startIcon={<MicIcon />}
                      sx={{ ml: 2, mr: 2 }}
                    >
                      Speak
                    </Button>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mt: 2,
                        flexWrap: "wrap",
                      }}
                    >
                      <Box
                        sx={{
                          flexGrow: 1,
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={(e) => handleOptionImagesChange(e, i)}
                          style={{ display: "none" }}
                          id={`image-upload-${i}`}
                        />
                        <label htmlFor={`image-upload-${i}`}>
                          <Button
                            sx={{ mt: -2 }}
                            variant="outlined"
                            component="span"
                          >
                            Choose File
                          </Button>
                        </label>
                        <Box display="flex" gap={1} sx={{ mt: -2 }}>
                          {/* {(option?.images || []).map((src, imgIndex) =>( */}
                          {option?.images.map((src, imgIndex) => (
                            <Box
                              key={imgIndex}
                              position="relative"
                              display="inline-block"
                            >
                              <img
                                src={src}
                                alt={`Option ${i + 1} Image ${imgIndex + 1}`}
                                style={{ width: "50px", height: "50px" }}
                              />
                              <IconButton
                                onClick={() =>
                                  handleRemoveOptionImage(i, imgIndex)
                                }
                                color="error"
                                size="small"
                                style={{ top: 0, right: 0 }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>

                    {i + 1 === options.length && (
                      <Button
                        variant="outlined"
                        onClick={handleAddMore}
                        sx={{ mb: 2, mt: 2 }}
                        color="success"
                        startIcon={<AddIcon />}
                      >
                        Add More Option
                      </Button>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              sx={{
                mt: 3,
                borderRadius: "5px",
                textTransform: "none",
              }}
            >
              Edit Question
            </Button>
          </Container>
        </Box>
      </Modal>
    </div>
  );
};

export default EditQuestionModal;
