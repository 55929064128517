import React, { useState, useEffect } from "react";
import { Container, Box, TextField } from "@mui/material";
import axios from "../../axiosInstance";
import { useParams } from "react-router-dom";
import { message, Table, Modal } from "antd";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import EditSubjectiveQuestionModal from "./EditSubjectiveQuestionModal";
import QuestionPaperPDF from "./QuestionPaperPDF";
import SearchIcon from "@mui/icons-material/Search";

const EditSubjectiveQuestion = () => {
  let { id } = useParams();
  const { user } = useSelector((state) => state.user);
  const [exam, setExam] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [instituteId, setInstituteId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [editSubjectiveQuestionModal, setEditSubjectiveQuestionModal] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const getAllExams = async () => {
    try {
      const response = await axios.get(
        `/api/v1/subjective/get-exam-subjective-by-id/${id}`
      );
      const examData = response.data.data;
      setExam(examData);
      setQuestions(examData?.subjectiveQuestions);
      setInstituteId(examData.instituteId);
      setVendorId(examData.vendorId);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllExams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filteredQuestions = exam?.subjectiveQuestions?.filter((question) => {
      return (
        question.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        question.referenceAnswer
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    });
    setQuestions(filteredQuestions);
  }, [searchTerm, exam]);

  // Delete Exam Questions By id
  const deleteSubjectiveQuestion = async (questionId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Subjective Question?",
      content: "Once deleted, this action cannot be undone.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.post(
            `/api/v1/question/subjective/admin-delete-exam-to-subjectiveQuestions`,
            {
              questionId,
              subjectiveExamId: id,
            }
          );
          if (data.success === true) {
            message.success(data.message);
            getAllExams(); 
          } else {
            message.error(data.message);
          }
        } catch (error) {
          message.error(error?.response?.data?.message || "An error occurred");
        }
      },
      onCancel() {
        console.log("Deletion canceled");
      },
    });
  };

  // Show Questions Columns
const questionsColumns = [
  {
    title: "#",
    dataIndex: "index",
    render: (text, record, index) => <span>{index + 1}</span>,
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (text, record) => (
      <>
        <Box sx={{ display: "flex" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteSubjectiveQuestion(record._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <IconButton aria-label="edit">
            <EditIcon
              sx={{ color: "#1976d2" }}
              onClick={(e) => {
                setSelectedQuestion(record);
                setEditSubjectiveQuestionModal(true);
              }}
            />
          </IconButton>
        </Box>
      </>
    ),
  },
  {
    title: "Question",
    dataIndex: "name",
    render: (text) => (
      <span
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    ),
  },
  {
    title: "Question Number",
    dataIndex: "questionNumber",
  },

  {
    title: "Reference Answer",
    dataIndex: "referenceAnswer",
    render: (text) => (
      <span
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    ),
  },
];


  return (
    <div>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search by Question Name or Reference Answer"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <IconButton>
              <SearchIcon sx={{ color: "#1976d2" }} />
            </IconButton>
          ),
        }}
        sx={{
          mb: 3,
          bgcolor: "white",
          borderRadius: "20px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
            "&:hover fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
          },
        }}
      />
      <Box>
        <QuestionPaperPDF id={id} />
      </Box>
      <Container component="form" className="form_style border-style" maxWidth>
        <Table
          id="questions-table"
          columns={questionsColumns}
          dataSource={questions}
          pagination={{ pageSize: 10 }}
          rowKey={(record) => record._id}
          style={{
            width: "100%",
            overflowY: "hidden",
            overflowX: "scroll",
          }}
        />
        {editSubjectiveQuestionModal && (
          <EditSubjectiveQuestionModal
            setEditSubjectiveQuestionModal={setEditSubjectiveQuestionModal}
            editSubjectiveQuestionModal={editSubjectiveQuestionModal}
            examId={id}
            refreshData={getAllExams}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
          />
        )}
      </Container>
    </div>
  );
};

export default EditSubjectiveQuestion;
