import React, { useState } from "react";
import { Modal, message } from "antd";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Container, TextField, Button } from "@mui/material";
import axios from "../../axiosInstance";

const EditRole = ({ refreshData, roles, roleName, roleValue }) => {
  const [role_name, setRole_Name] = useState(roleName);
  const [role_value, setRole_Value] = useState(roleValue);
  // const [refreshDataRole, setRefreshDataRole] = useState(refreshData)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    // window.location.reload();
  };

  // Edit Role
  const { confirm } = Modal;

  const handleSubmit = async (e) => {
    e.preventDefault();
    confirm({
      title: "Are you sure you want to Edit this Role?",
      content: "Confirm to apply the changes made to this role.",
      okText: "Yes, Edit",
      cancelText: "No, Cancel",
      onOk: async () => {
        const addRole = {
          role_name,
          value: role_value,
          id: roles,
        };
        try {
          const { data } = await axios.put(
            "/api/v1/admin/admin-update-role",
            addRole
          );
          if (data.success === true) {
            message.success(data.message);
            refreshData();
            setIsModalOpen(false);
          } else {
            message.error(data?.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Error updating the role."
          );
        }
      },
      onCancel() {
        console.log("Edit role action canceled");
      },
    });
  };

  return (
    <div>
      <>
        <IconButton aria-label="edit" onClick={showModal}>
          <EditIcon sx={{ color: "#1976d2" }} />
        </IconButton>
        <Modal
          title="Edit Role"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={false}
        >
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="role_name"
              label="Role Name"
              name="role_name"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Role Name"
              value={role_name}
              onChange={(e) => setRole_Name(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="value"
              label="Value"
              name="value"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="For example role value user O admin 1 sub-admin 2 other roles 3"
              value={role_value}
              onChange={(e) => setRole_Value(e.target.value)}
            />

            <Button variant="contained" type="submit">
              Edit Role
            </Button>
          </Container>
        </Modal>
      </>
    </div>
  );
};

export default EditRole;
