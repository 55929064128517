import React from "react";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import { Box, Typography, Paper, Divider } from "@mui/material";
import Footer from "../Components/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <PageTitle title="Privacy Policy | Test Series" />
      <Box sx={{ mt: { xs: 16, sm: 16, md: 16, lg: 18 }, mb: 8, mx: { md: 12, xs: 2 }, minHeight: "100vh", backgroundColor: '#f9f9f9' }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: "15px", backgroundColor: '#ffffff' }}>
          <Typography
            variant="h3"
            align="center"
            // className="courses_title"
            gutterBottom
            sx={{
              letterSpacing: "3px",
              lineHeight: 1.8,
              fontFamily: "Poppins, sans-serif",  
              fontWeight: "bold",
              color: "#2C3E50",
            }}  
          >
            Privacy Policy
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#000",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            1. Introduction
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt:2,
              mb:5,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              // textAlign: "center",
              letterSpacing: "1.8px",
            }}
          >
            At GatikAI Technologies, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose your personal information when you use our website and services. 
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#000",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            2. Information We Collect
          </Typography>
          <Typography
             gutterBottom
             variant="h6"
             component="div"
             color="text.secondary"
             sx={{
               mt:2,
               mb:5,
               fontFamily: "Poppins, sans-serif",
               lineHeight: 1.8,
               // textAlign: "center",
               letterSpacing: "1.8px",
             }}
          >
            We may collect personal information that you provide directly to us, such as your name, email address, phone number, and other details when you register for our services or contact us. We may also automatically collect certain information about your device and usage of our website, including your IP address, browser type, and pages visited.
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#000",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            3. How We Use Your Information
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt: 2,
              mb:5,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              // textAlign: "center",
              letterSpacing: "1.8px",
            }}
          >
            We may use your information to:
            <ul style={{marginTop:"12px"}}>
              <li>Provide and maintain our services</li>
              <li>Communicate with you regarding your account or services</li>
              <li>Send you updates, newsletters, and marketing materials</li>
              <li>Analyze usage and trends to improve our services</li>
            </ul>
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#000",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            4. Information Sharing and Disclosure
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt: 2,
              mb:5,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              // textAlign: "center",
              letterSpacing: "1.8px",
            }}
          >
            We do not sell or rent your personal information to third parties. We may share your information with third-party service providers to help us provide and improve our services, as well as to comply with legal obligations or protect our rights.
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#000",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            5. Security of Your Information
          </Typography>
          <Typography
             gutterBottom
             variant="h6"
             component="div"
             color="text.secondary"
             sx={{
               mt:2,
               mb:5,
               fontFamily: "Poppins, sans-serif",
               lineHeight: 1.8,
               // textAlign: "center",
               letterSpacing: "1.8px",
             }}
          >
            We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, please be aware that no security measures are perfect or impenetrable.
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#000",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            6. Your Rights
          </Typography>
          <Typography
           gutterBottom
           variant="h6"
           component="div"
           color="text.secondary"
           sx={{
             mt:2,
             mb:5,
             fontFamily: "Poppins, sans-serif",
             lineHeight: 1.8,
             // textAlign: "center",
             letterSpacing: "1.8px",
           }}
          >
            Depending on your location, you may have certain rights regarding your personal information, including the right to access, correct, or delete your information. If you wish to exercise these rights, please contact us using the information below.
          </Typography>

          <Typography
             variant="h5"
             gutterBottom
             sx={{
               fontFamily: "Poppins, sans-serif",
               color: "#000",
               letterSpacing: "2.5px",
               lineHeight: 1.8,
             }}
          >
            7. Changes to This Privacy Policy
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt:2,
              mb:5,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              // textAlign: "center",
              letterSpacing: "1.8px",
            }}
          >
            We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new Privacy Policy on our website. Your continued use of our services after such changes indicates your acceptance of the new Privacy Policy.
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#000",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            8. Contact Us
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt:2,
              mb:5,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              // textAlign: "center",
              letterSpacing: "1.8px",
            }}
          >
            If you have any questions or concerns regarding this Privacy Policy, please contact us at:
            <br />
            E-mail: testseries@gatiktech.com
            <br />
            Contact Number: +91-7891555777
          </Typography>

          <Divider sx={{ mb: 3 }} />
        </Paper>
      </Box>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
