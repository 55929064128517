// import React, { useState } from "react";
// import {
//   Grid,
//   Card,
//   CardContent,
//   Typography,
//   Button,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Box,
// } from "@mui/material";
// import CheckIcon from "@mui/icons-material/Check";
// import StarIcon from "@mui/icons-material/Star";
// import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
// import WhatshotIcon from "@mui/icons-material/Whatshot";
// import DemoNavbar from "./DemoNavbar";
// import { useNavigate } from "react-router-dom";
// import PageTitle from "../PageTitle/PageTitle";
// import DemoFooter from "./DemoFooter";

// const plans = [
//   {
//     Allfeatures: "All features",
//     icon: <StarIcon sx={{ color: "orange", fontSize: "35px", mr: 1 }} />,
//     title: "Standard",
//     paid: "499",
//     description:
//       "Streamline Essentials; Manage core functionalities, collaboration with parents, and manage student data.",
//     features: [
//       "2 MCQ Exams -> 1 Practice Test , 1 Live Test ",
//       "2 Subjective Exam -> 1 Practice Test , 1 Live Test",
//       "3 PDF Notes Upload",
//       "Video Lecture upload (2 Videos via YT)",
//       "Student Enquiry Notifications",
//       "No Reports",
//       "1 Primary Account",
//       "Student Signup - Unlimited",
//       "Test Series Branding",
//     ],
//   },
//   {
//     Allfeatures: "All features (Pro+)",
//     icon: (
//       <BusinessCenterIcon sx={{ color: "orange", fontSize: "35px", mr: 1 }} />
//     ),
//     title: "Pro",
//     paid: "799",
//     description:
//       "Empower for Growth; Unlock essential tools for academics and financial management, and more.",
//     features: [
//       "10 MCQ Exams -> 5 Practice Tests , 5 Live Tests ",
//       "10 Subjective Exams -> 5 Practice Tests , 5 Live Tests",
//       "15 PDF Notes Upload",
//       "Video Lecture upload (10 Videos via YT)",
//       "Proprietary Video Lectures Upload 4 files (1 gb Total)",
//       "Student Enquiry Notifications",
//       "view Reports",
//       "1 Primary Account 2 Teacher Accounts",
//       "Student Signup - Unlimited",
//       "Test Series Branding",
//     ],
//   },
//   {
//     Allfeatures: "All features (Elite+)",
//     icon: <WhatshotIcon sx={{ color: "orange", fontSize: "35px", mr: 1 }} />,
//     title: "Elite",
//     paid: "999",
//     description:
//       "Drive Efficiency; Unleash the power of integrations, from devices & institutions, to HRMS, and beyond.",
//     features: [
//       "50 MCQ Exams -> 5 Practice Tests , 5 Live Tests ",
//       "50 Subjective Exams -> 5 Practice Tests , 5 Live Tests",
//       "20 PDF Notes Upload",
//       "Video Lecture upload (25 Videos via YT)",
//       "Proprietary Video Lectures Upload 10 files (2 gb Total)",
//       "Student Enquiry Notifications",
//       "Download Reports",
//       "2 Primary Accounts 5 Teacher Accounts",
//       "Student Signup - Unlimited",
//       "Institute Branding",
//     ],
//   },
// ];

// const DemoPlan = () => {
//   const [selectedPlan, setSelectedPlan] = useState();
//   const navigate = useNavigate();

//   const handleClick = (planName) => {
//     setSelectedPlan(planName);
//     // Navigate to BookADemo and pass state
//     navigate("/book-a-demo", { state: { planName } });
//   };

//   return (
//     <>
//       <DemoNavbar />
//       <PageTitle title="Test Series Plans | Test Series" />
//       <Box
//         sx={{
//           mt: { xs: 16, sm: 16, md: 16, lg: 18 },
//           mb: 8,
//           mx: { md: 12, xs: 2 },
//           minHeight: "100vh",
//         }}
//       >
//         <Typography
//           variant="h4"
//           align="center"
//           // className="courses_title"
//           gutterBottom
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             letterSpacing: "3px",
//             lineHeight: 1.8,
//             fontFamily: "Poppins, sans-serif",
//             fontWeight: "bold",
//             color: "#2C3E50",
//           }}
//         >
//           Affordable Solutions for Your Educational Success
//         </Typography>
//         <Typography
//           variant="h5"
//           align="center"
//           gutterBottom
//           sx={{
//             fontFamily: "Poppins, sans-serif",
//             color: "#555",
//             letterSpacing: "2.5px",
//             lineHeight: 1.8,
//           }}
//         >
//           Flexible and comprehensive plans tailored to meet all your academic
//           needs.
//         </Typography>
//         <Grid container rowSpacing={0} columnSpacing={4}>
//           {plans.map((plan, index) => (
//             <Grid item xs={12} md={4} key={index}>
//               <Card
//                 variant="outlined"
//                 sx={{
//                   mt: 5,
//                   mb: 3,
//                   minHeight: "100vh",
//                   borderRadius: "25px",
//                   boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
//                   wordSpacing: "2px",
//                   letterSpacing: "1.5px",
//                 }}
//               >
//                 <CardContent>
//                   <Typography
//                     variant="h5"
//                     component="div"
//                     gutterBottom
//                     sx={{
//                       mt: 3,
//                       mb: 3,
//                       fontWeight: "bold",
//                       wordSpacing: "2px",
//                       letterSpacing: "1.5px",
//                       display: "flex",
//                       alignItems: "center",
//                     }}
//                   >
//                     {plan.icon} {plan.title}
//                   </Typography>

//                   <hr />
// <Typography
//   variant="body2"
//   // color="text.secondary"
//   gutterBottom
//   sx={{
//     mt: 3,
//     height: {
//       xs: "100px",
//       md: "90px",
//     },
//     wordSpacing: "2px",
//     letterSpacing: "1.5px",
//     color: "#757575",
//   }}
// >
//   {plan.description}
// </Typography>
//                   <Typography
//                     variant="body2"
//                     gutterBottom
//                     textAlign={"center"}
//                     sx={{
//                       mt: -5,
//                       wordSpacing: "2px",
//                       letterSpacing: "1.5px",
//                       color: "orange",
//                       fontSize: "35px",
//                     }}
//                   >
//                      {'\u20B9'}{plan.paid}
//                   </Typography>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     fullWidth
//                     sx={{ mb: 3 }}
//                     onClick={() => handleClick(plan.title)}
//                   >
//                     Get Plan
//                   </Button>
//                   <Typography
//                     variant="h6"
//                     component="div"
//                     gutterBottom
//                     sx={{
//                       mt: 1,
//                       fontWeight: "bold",
//                       color: "#000",
//                       wordSpacing: "2px",
//                       letterSpacing: "1.5px",
//                     }}
//                   >
//                     {plan.Allfeatures}
//                   </Typography>
//                   <List dense>
//                     {plan.features.map((feature, i) => (
//                       <ListItem key={i}>
//                         <ListItemIcon>
//                           <CheckIcon style={{ color: "orange" }} />
//                         </ListItemIcon>
//                         <ListItemText primary={feature} />
//                       </ListItem>
//                     ))}
//                   </List>
//                 </CardContent>
//               </Card>
//             </Grid>
//           ))}
//         </Grid>
//       </Box>
//       {/* <Box sx={{ mt: 5 }}>
//         <DemoFooter />
//       </Box> */}
//       <DemoFooter />
//     </>
//   );
// };

// export default DemoPlan;

import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import StarIcon from "@mui/icons-material/Star";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import DemoNavbar from "./DemoNavbar";
import { useNavigate } from "react-router-dom";
import PageTitle from "../PageTitle/PageTitle";
import DemoFooter from "./DemoFooter";

const plans = [
  {
    Allfeatures: "All features",
    icon: <StarIcon sx={{ color: "#f39c12", fontSize: "30px", mr: 1 }} />,
    title: "Standard",
    paid: "499",
    description:
      "Streamline Essentials; Manage core functionalities, collaboration with parents, and manage student data.",
    features: [
      "2 MCQ Exams -> 1 Practice Test , 1 Live Test ",
      "2 Subjective Exam -> 1 Practice Test , 1 Live Test",
      "3 PDF Notes Upload",
      "Video Lecture upload (2 Videos via YT)",
      "Student Enquiry Notifications",
      "No Reports",
      "1 Primary Account",
      "Student Signup - Unlimited",
      "Test Series Branding",
    ],
  },
  {
    Allfeatures: "All features (Pro+)",
    icon: (
      <BusinessCenterIcon sx={{ color: "#f39c12", fontSize: "30px", mr: 1 }} />
    ),
    title: "Pro",
    paid: "799",
    description:
      "Empower for Growth; Unlock essential tools for academics and financial management, and more.",
    features: [
      "10 MCQ Exams -> 5 Practice Tests , 5 Live Tests ",
      "10 Subjective Exams -> 5 Practice Tests , 5 Live Tests",
      "15 PDF Notes Upload",
      "Video Lecture upload (10 Videos via YT)",
      "Proprietary Video Lectures Upload 4 files (1 gb Total)",
      "Student Enquiry Notifications",
      "View Reports",
      "1 Primary Account 2 Teacher Accounts",
      "Student Signup - Unlimited",
      "Test Series Branding",
    ],
  },
  {
    Allfeatures: "All features (Elite+)",
    icon: <WhatshotIcon sx={{ color: "#f39c12", fontSize: "30px", mr: 1 }} />,
    title: "Elite",
    paid: "999",
    description:
      "Drive Efficiency; Unleash the power of integrations, from devices & institutions, to HRMS, and beyond.",
    features: [
      "50 MCQ Exams -> 5 Practice Tests , 5 Live Tests ",
      "50 Subjective Exams -> 5 Practice Tests , 5 Live Tests",
      "20 PDF Notes Upload",
      "Video Lecture upload (25 Videos via YT)",
      "Proprietary Video Lectures Upload 10 files (2 gb Total)",
      "Student Enquiry Notifications",
      "Download Reports",
      "2 Primary Accounts 5 Teacher Accounts",
      "Student Signup - Unlimited",
      "Institute Branding",
    ],
  },
];

const DemoPlan = () => {
  const [selectedPlan, setSelectedPlan] = useState();
  const navigate = useNavigate();

  const handleClick = (planName) => {
    setSelectedPlan(planName);
    navigate("/book-a-demo", { state: { planName } });
  };

  return (
    <>
      <DemoNavbar />
      <PageTitle title="Test Series Plans | Test Series" />
      <Box
        sx={{
          mt: { xs: 12, sm: 14, md: 16 },
          mb: 8,
          mx: { md: 10, xs: 2 },
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "bold",
            color: "#2C3E50",
            mb: 2,
          }}
        >
          Affordable Solutions for Your Educational Success
        </Typography>
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          sx={{ fontFamily: "Poppins, sans-serif", color: "#7f8c8d", mb: 4 }}
        >
          Flexible and comprehensive plans tailored to meet all your academic
          needs.
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {plans.map((plan, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                variant="outlined"
                sx={{
                  borderRadius: "25px",
                  boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                  height: "100%",
                  backgroundColor: plan.title === "Pro" ? "#fff8e1" : "white",
                  // border: plan.title === "Pro" ? "2px solid #f39c12" : "none",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      color: "#34495e",
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    {plan.icon} {plan.title}
                  </Typography>
                  <hr />
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{
                      mt: 3,
                      height: {
                        xs: "100px",
                        md: "90px",
                      },
                      wordSpacing: "2px",
                      letterSpacing: "1.5px",
                      color: "#757575",
                    }}
                  >
                    {plan.description}
                  </Typography>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{ color: "#f39c12", fontWeight: "bold", mb: 3, mt: -5 }}
                  >
                    {"\u20B9"}
                    {plan.paid}
                  </Typography>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: "#f39c12",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#d35400",
                      },
                      mb: 3,
                    }}
                    onClick={() => handleClick(plan.title)}
                  >
                    Get Plan
                  </Button>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "#2C3E50", mb: 2 }}
                  >
                    {plan.Allfeatures}
                  </Typography>
                  <List dense>
                    {plan.features.map((feature, i) => (
                      <ListItem key={i} sx={{ mb: 0.5 }}>
                        <ListItemIcon>
                          <CheckIcon sx={{ color: "#f39c12" }} />
                        </ListItemIcon>
                        <ListItemText primary={feature} />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <DemoFooter />
    </>
  );
};

export default DemoPlan;
