import React, { useEffect, useState } from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  Breadcrumbs, Link as MUILink,
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Link, useParams } from "react-router-dom";
import { message } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import axios from "../../axiosInstance";

const EvaluationStudentsList = () => {
  let { id } = useParams();
  const [userExamList, setUserExamList] = useState([]);
  const [filteredUserExamList, setFilteredUserExamList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Get All Exam Data
  const getAllSubjectiveExams = async () => {
    try {
      const response = await axios.get(
        `/api/v1/reports/subjective/admin-get-all-subjectiveReport`
      );
      if (response?.data?.success === true) {
        const exams = response?.data?.data;
        const userExamList = exams
          .filter((exam) => exam.examId === id)
          .flatMap((exam) =>
            exam.usersId.map((user) => ({
              userName: user.name,
              userEmail: user.email,
              examName: user?.examName,
              category: user?.category,
              totalMarks: user?.totalMarks,
              totalMarksObtained: user?.totalMarksObtained,
              examId: exam.examId,
              reportId: user.reportId,
              answersData: user.answersData,
              attemptId: `${exam.examId}-${user.reportId}`, 
            }))
          );

        setUserExamList(userExamList);
        setFilteredUserExamList(userExamList);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllSubjectiveExams();
  }, [id]);

  // Filter user exams based on search query
  useEffect(() => {
    let filtered = userExamList;

    if (searchQuery) {
      filtered = filtered.filter(
        (userExam) =>
          userExam.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          userExam.userEmail.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredUserExamList(filtered);
  }, [searchQuery, userExamList]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const columns = [
    {
      field: "viewDetails",
      headerName: "View Details",
      width: 200,
      renderCell: (params) => {
        const hasMarks =
          params.row.answersData &&
          params.row.answersData.some((answer) => answer.marks === undefined);

        return (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {hasMarks ? (
              <>
                <Link
                  to={`/admin/subjective/edit-exam-details/${params.row.reportId}`}
                >
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "green", color: "#fff", mr: 1 }}
                  >
                    Checked
                  </Button>
                </Link>
              </>
            ) : (
              <Link
                to={`/admin/subjective/exam-details/${params.row.reportId}`}
              >
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "red", color: "#fff" }}
                >
                  Evaluate
                </Button>
              </Link>
            )}
          </Box>
        );
      },
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 200,
      editable: false,
    },
    {
      field: "userEmail",
      headerName: "User Email",
      width: 200,
      editable: false,
    },
    {
      field: "examName",
      headerName: "Exam Name",
      width: 200,
      editable: false,
    },
    {
      field: "category",
      headerName: "Exam Category",
      width: 200,
      editable: false,
    },

    {
      field: "totalMarks",
      headerName: "Total Marks",
      width: 200,
      editable: false,
    },

    {
      field: "totalMarksObtained",
      headerName: "Obtained Marks",
      width: 200,
      editable: false,
    },
  ];

  return (
    <div>
      <PageTitle title="Admin Subjective Exams Copy List | Test Series" />
      <Box>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Subjective Exams Students List
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="/admin/subjective/exams"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Subjective Exams 
          </MUILink>
          <MUILink
            component={Link}
            to="/admin/subjective/evaluation"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Evaluation
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>Students</Typography>
        </Breadcrumbs>
        <Box
          style={{
            width: "auto",
            overflowY: "hidden",
            overflowX: "scroll",
          }}
        >
          {/* Search Input */}
          <Box sx={{ mb: 5, mt: 3 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search by Student Name And Email"
              value={searchQuery}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon sx={{ color: "#1976d2" }} />
                  </IconButton>
                ),
              }}
              sx={{
                mb: 3,
                bgcolor: "white",
                borderRadius: "20px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
            />
          </Box>
          <Paper sx={{ bgcolor: "white" }}>
            <Box sx={{ height: 500, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row.attemptId} 
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                  // Style for header cells
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                    // fontSize: "13px",
                  },
                  // Style for checkbox in header
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                rows={filteredUserExamList}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                // checkboxSelection
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </div>
  );
};

export default EvaluationStudentsList;
