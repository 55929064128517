import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  INSTITUTE_LOGIN_REQUEST,
  INSTITUTE_LOGIN_SUCCESS,
  INSTITUTE_LOGIN_FAIL,
  INSTITUTE_RELOAD_USER_REQUEST,
  INSTITUTE_RELOAD_USER_SUCCESS,
  INSTITUTE_RELOAD_USER_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  RELOAD_USER_REQUEST,
  RELOAD_USER_SUCCESS,
  RELOAD_USER_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_RESET,
  UPDATE_PROFILE_FAIL,
  INSTITUTE_UPDATE_PROFILE_REQUEST,
  INSTITUTE_UPDATE_PROFILE_SUCCESS,
  INSTITUTE_UPDATE_PROFILE_RESET,
  INSTITUTE_UPDATE_PROFILE_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_RESET,
  UPDATE_PASSWORD_FAIL,
  INSTITUTE_UPDATE_PASSWORD_REQUEST,
  INSTITUTE_UPDATE_PASSWORD_SUCCESS,
  INSTITUTE_UPDATE_PASSWORD_RESET,
  INSTITUTE_UPDATE_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  CONTACT_USER_REQUEST,
  CONTACT_USER_SUCCESS,
  CONTACT_USER_FAIL,
  ADMIN_USER_REQUEST,
  ADMIN_USER_SUCCESS,
  ADMIN_USER_FAIL,
  ADMIN_COURSES_REQUEST,
  ADMIN_COURSES_SUCCESS,
  ADMIN_COURSES_FAIL,
  GET_INSTITUTE_INFO_REQUEST,
  GET_INSTITUTE_INFO_SUCCESS,
  GET_INSTITUTE_INFO_FAILURE,
  CLEAR_ERRORS,
} from "../constants/userConstants";



export const userReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case INSTITUTE_LOGIN_REQUEST:
    case RELOAD_USER_REQUEST:
    case INSTITUTE_RELOAD_USER_REQUEST:
      case REGISTER_USER_REQUEST:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
      };

    case LOGIN_SUCCESS:
    case INSTITUTE_LOGIN_SUCCESS:
    case RELOAD_USER_SUCCESS:
    case INSTITUTE_RELOAD_USER_SUCCESS:
      case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.payload,
      };

    case LOGOUT_SUCCESS:
      return {
        loading: false,
        user: null,
        isAuthenticated: false,
      };

    case LOGIN_FAIL:
    case INSTITUTE_LOGIN_FAIL:
      case REGISTER_USER_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null,
        error: action.payload,
      };

    case RELOAD_USER_FAIL:
    case INSTITUTE_RELOAD_USER_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null,
        error: action.payload,
      };

    case LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const profileReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_REQUEST:
    case INSTITUTE_UPDATE_PROFILE_REQUEST:
    case UPDATE_PASSWORD_REQUEST:
      case INSTITUTE_UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PROFILE_SUCCESS:
    case INSTITUTE_UPDATE_PROFILE_SUCCESS:
    case UPDATE_PASSWORD_SUCCESS:
      case INSTITUTE_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case UPDATE_PROFILE_FAIL:
    case INSTITUTE_UPDATE_PROFILE_FAIL:
    case UPDATE_PASSWORD_FAIL:
      case INSTITUTE_UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_PROFILE_RESET:
      case INSTITUTE_UPDATE_PROFILE_RESET:
    case UPDATE_PASSWORD_RESET:
      case INSTITUTE_UPDATE_PASSWORD_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const forgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case FORGOT_PASSWORD_FAIL:
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const contactReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case CONTACT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case CONTACT_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const adminUsersReducer = (state = { users: {} }, action) => {
  switch (action.type) {
    case ADMIN_USER_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
      };

    case ADMIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        users: action.payload,
      };

    case ADMIN_USER_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        users: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const coursesReducer = (state = { courses: [] }, action) => {
  switch (action.type) {
    case ADMIN_COURSES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ADMIN_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: action.payload, // Payload is the courses array
      };

    case ADMIN_COURSES_FAIL:
      return {
        ...state,
        loading: false,
        courses: [],
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

const initialState = {
  info: [],
  loading: false,
  error: null,
};

export const instituteReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSTITUTE_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_INSTITUTE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload, // Store the fetched data
        };
    case GET_INSTITUTE_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
