import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { message } from "antd";
import { styled } from "@mui/system";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';
import axios from "../../axiosInstance";
import DemoNavbar from "./DemoNavbar";
import DemoFooter from "./DemoFooter";
import PageTitle from "../PageTitle/PageTitle";
import InstitutesCourseDetails from "./InstitutesCourseDetails";
import InstituteFacilities from "./InstituteFacilities";

const BannerContainer = styled(Box)({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: "600px",
});

const BannerImage = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#000",
});

const InstitutesInformation = () => {
  const [image_Url, setImage_Url] = useState([]);
  const [about, setAbout] = useState({});
  const [instituteCourses, setInstituteCourses] = useState([]);

  const location = useLocation();
  const { _id, instituteId } = location.state || {};

  const settings = {
    dots: false,
    infinite: image_Url.length > 1, // infinite only if there are multiple images
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

   // Get all Banner Image by InstituteId
   const getInstituteBannerImage = async () => {
    try {
      const response = await axios.post(`/api/v1/demo/get-institute-banner-images-by-instituteId`, {
        instituteId,
      });
      setImage_Url(response?.data?.instituteBannerImages);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };


  // Fetch Institute Information
  const getInstituteInfo = async () => {
    try {
      const response = await axios.post(`/api/v1/demo/get-institute-info-by-id`, {
        _id,
      });
      setAbout(response?.data?.institute);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  // Fetch Institute Course Details
  const getInstituteCoursesByInstituteId = async () => {
    try {
      const response = await axios.post(
        `/api/v1/demo/get-institute-course-details-by-instituteId`,
        {
          instituteId,
        }
      );
      setInstituteCourses(response?.data?.courses);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (instituteId)getInstituteBannerImage()
    if (_id) getInstituteInfo();
    if (instituteId) getInstituteCoursesByInstituteId();
  }, [_id, instituteId]);


  return (
    <div>
         <DemoNavbar />
         <PageTitle title="Test Series" />
        <BannerContainer sx={{ mt: { xs: 8, sm: 8, md: 8, lg: 8 } }}>
        <Slider {...settings}>
          {image_Url?.map((url, index) => (
            <BannerImage
              key={index}
              sx={{
                height: {
                  xs: "300px",
                  sm: "400px",
                  md: "400px",
                  lg: "550px",
                },
              }}
            >
              <img
                src={url?.url}
                alt={`Slide ${index + 1}`}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </BannerImage>
          ))}
        </Slider>
      </BannerContainer>

      {about && Object.keys(about).length > 0 ? (
        <Box sx={{background: "#f7fafd",  p: { md: 3, xs: 1 }, mt: 3 }}>
          <Box sx={{ mr: { md: 3, xs: 1 }, ml: { md: 3, xs: 1 } }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{
                margin: "0 auto",
                textAlign: "center",
                padding: "20px",
                backgroundColor: "#fff",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
              }}
            >
              <Typography
                style={{
                  textTransform: "capitalize",
                  fontWeight: "700",
                  fontSize: "28px",
                  marginBottom: "10px",
                  color: "#2C3E50",
                  fontFamily: "Poppins, sans-serif",
                  letterSpacing: ".1rem",
                }}
              >
                Why Choose{" "}
                <span style={{ color: "#3f51b5" }}>
                  {about?.instituteName}
                </span>
                ?
              </Typography>

              <Typography
                className="vendor_about_description"
                variant="body2"
                color="text.secondary"
                sx={{
                  mt: 1,
                  fontSize: "15px",
                  lineHeight: 1.6,
                  color: "#7F8C8D",
                  letterSpacing: ".1rem",
                  fontFamily: "Poppins, sans-serif",
                }}
                dangerouslySetInnerHTML={{
                  __html:about?.description,
                }}
              >
                {/* {about?.description} */}
              </Typography>
            </Box>
            <Typography
              style={{
                fontWeight: "600px",
                fontSize: "22px",
                textAlign: "center",
                
              }}
              sx={{ color: "black", pb: 3, mt: 5 }}
            >
              <b>Institute All Courses</b>
            </Typography>
            <Grid container spacing={3} >
              {instituteCourses?.map((course, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      transition: "transform 0.3s, box-shadow 0.3s",
                      "&:hover": {
                        transform: "translateY(-8px)",
                        boxShadow:
                          "0 8px 16px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
                      },
                      borderRadius: "15px",
                      boxShadow:
                        "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                      padding: "16px",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                     
                    }}
                  >
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        className="courses_title"
                        sx={{
                          letterSpacing: ".1rem",
                          fontSize: { xs: "18px", sm: "20px" },
                          marginBottom: "12px",
                          fontWeight: "bold",
                          fontFamily: "Poppins, sans-serif",
                          color: "#2C3E50",
                        }}
                      >
                        {course?.courseName}
                      </Typography>
                      <Typography
                        className="courses_desc"
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          mt: 1,
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          overflow: "hidden",
                          fontSize: "15px",
                          letterSpacing: ".1rem",
                          fontFamily: "Poppins, sans-serif",
                        }}
                        dangerouslySetInnerHTML={{ __html: course?.courseDescription }}
                      >
                        {/* {course?.courseDescription} */}
                      </Typography>

                      <Typography
                        className="courses_desc"
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          mt: 2,
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#34495E",
                          letterSpacing: ".1rem",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <strong>Course Start Date: </strong>
                        {new Date(course?.startDate)
                          .toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })
                          .replace(/ /g, "-")}
                      </Typography>
                      <Box mt={2}>
                        <InstitutesCourseDetails instituteId={course.instituteId} courseId={course?._id} courseName={course?.courseName} courseDesc={course?.courseDescription} courseDate={course?.startDate} courseFees={course?.courseFees} courseEndDate={course?.endDate}/>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box sx={{mb:5}}>
          <InstituteFacilities />
          </Box>
        </Box>
      ) : null}
      <DemoFooter />
    </div>
  );
};

export default InstitutesInformation;
