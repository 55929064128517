// useInstituteUser.js
import { useEffect, useState } from "react";
import { message } from "antd";

const useInstituteUser = () => {
  const [studentCourse, setStudentCourse] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("instituteUser");

    if (storedUser) {
      const userObject = JSON.parse(storedUser);
      setStudentCourse(userObject?.studentCourse);
    } else {
      message.error("No user data found.");
    }
  }, []);

  return studentCourse;
};

export default useInstituteUser;


// import { useEffect, useState } from "react";
// import { message } from "antd";

// const useInstituteUser = () => {
//   const [studentCourse, setStudentCourse] = useState([]);

//   // Fixed ID for "General Course"
//   const generalCourseId = "81af3e57846a42aab058dc4d";

//   // Load user data from local storage on component mount
//   useEffect(() => {
//     const storedUser = localStorage.getItem("instituteUser");

//     if (storedUser) {
//       const userObject = JSON.parse(storedUser);

//       // Extract common values from the existing courses
//       const instituteId = userObject?.studentCourse?.[0]?.instituteId || "";
//       const vendorId = userObject?.studentCourse?.[0]?.vendorId || "";

//       // Define the "General" course object
//       const generalCourse = {
//         studentCourseId: generalCourseId,
//         studentCourseName: "General",
//         courseFees: 0,
//         studentFees: 0,
//         batchId: "",
//         instituteId: instituteId,
//         vendorId: vendorId,
//         isActive: true,
//         teachersNotes: "",
//         createdAt: new Date().toISOString(),
//         _id: generalCourseId, // Using the same ID for _id as well
//       };

//       // Combine existing courses with the General course
//       const updatedStudentCourses = [
//         ...userObject?.studentCourse || [],
//         generalCourse,
//       ];

//       setStudentCourse(updatedStudentCourses);
//     } else {
//       message.error("No user data found.");
//     }
//   }, []);

//   // Only return studentCourse as requested
//   return studentCourse;
// };

// export default useInstituteUser;
