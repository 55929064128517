import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, contactForm } from "../redux/actions/userAction";
import Footer from "../Components/Footer";
import { message } from "antd";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const Contact = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { info } = useSelector((state) => state.institute);
  const { error, loading, success } = useSelector((state) => state.contact);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [messages, setMessages] = useState("");
  const [instituteInfoAddress, setInstituteInfoAddress] = useState("");
  const [instituteInfoPhone, setInstituteInfoPhone] = useState("");
  const [instituteInfoEmail, setInstituteInfoEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", messages);
    formData.append("instituteId", user?.instituteId);
    formData.append("vendorId", user?._id);
    dispatch(contactForm(formData));
  };

  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearErrors());
    }
    if (success) {
      message.success(
        "Thanks for contacting us. We'll get back to you as soon as possible."
      );
      setName("");
      setEmail("");
      setMessages("");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }, [dispatch, error, success]);

  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    const storedData = localStorage.getItem("instituteInfo");
    if (storedData) {
      const instituteInfoData = JSON.parse(storedData);
      setInstituteInfoPhone(instituteInfoData[0]?.institutePhone || ""); 
      setInstituteInfoEmail(instituteInfoData[0]?.instituteEmail || "");
      setInstituteInfoAddress(instituteInfoData[0]?.instituteAddress || ""); 
    }
  }, []);

  return (
    <>
      <Navbar />
      <PageTitle title="Contact Us | Test Series" />
      <Container
        sx={{
          pt: 3,
          minHeight: "100vh",
          mt: { xs: 8, sm: 8, md: 8, lg: 10 },
          mb: 8,
        }}
      >
        <Typography
          gutterBottom
          variant="h3"
          component="div"
          color="text.secondary"
          sx={{
            mt: 5,
            // mb: 5,
            textAlign: "center",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
            fontSize: "30px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "bold",
            color: "#555",
          }}
        >
          Get in Touch With Us!
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: { xs: "column", md: "row" },
            pt: 5,
            mt: 3,
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -50 }}
                animate={inView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.5 }}
              >
                <Card
                  sx={{
                    transition: "0.3s !important",
                    "&:hover": { transform: "scale(1.05)" },
                    borderRadius: "15px",
                    boxShadow:
                      "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                    padding: "16px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <CardContent>
                    <Typography
                      align="center"
                      mb={7}
                      gutterBottom
                      variant="h4"
                      component="div"
                      color="text.secondary"
                      sx={{
                        mb: 3,
                        fontFamily: "Poppins, sans-serif",
                        lineHeight: 1.8,
                        letterSpacing: "1.8px",
                      }}
                    >
                      Contact Us
                    </Typography>
                    <form onSubmit={handleSubmit}>
                      <TextField
                        fullWidth
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        required
                      />
                      <TextField
                        fullWidth
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        required
                      />
                      <TextField
                        fullWidth
                        label="Message"
                        value={messages}
                        onChange={(e) => setMessages(e.target.value)}
                        margin="normal"
                        multiline
                        rows={4}
                        variant="outlined"
                        required
                      />
                      <Button
                        disabled={loading}
                        type="submit"
                        fullWidth
                        gutterBottom
                        variant="outlined"
                        color="primary"
                        sx={{
                          mt: 1,
                          p: 1,
                          borderRadius: "50px",
                          textTransform: "none",
                          fontFamily: "Poppins, sans-serif",
                          letterSpacing: ".1rem",
                          fontSize: "16px",
                        }}
                      >
                        {loading ? "Loading..." : "Submit"}
                      </Button>
                    </form>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: 50 }}
                animate={inView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.5 }}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        transition: "0.3s !important",
                        "&:hover": { transform: "scale(1.05)" },
                        borderRadius: "15px",
                        boxShadow:
                          "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                        padding: "8px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardContent sx={{ textAlign: "center" }}>
                        <LocationOnIcon fontSize="large" color="primary" />
                        <Typography
                          gutterBottom
                          variant="h6"
                          sx={{
                            fontFamily: "Poppins, sans-serif",
                            lineHeight: 1.8,
                            letterSpacing: "1.8px",
                          }}
                        >
                          Address
                        </Typography>
                        <Typography
                          color="text.secondary"
                          sx={{
                            mb: -2,
                            fontFamily: "Poppins, sans-serif",
                            lineHeight: 1.8,
                            letterSpacing: "1.8px",
                          }}
                        >
                          {instituteInfoAddress}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        transition: "0.3s !important",
                        "&:hover": { transform: "scale(1.05)" },
                        borderRadius: "15px",
                        boxShadow:
                          "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                        padding: "8px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardContent sx={{ textAlign: "center" }}>
                        <PhoneIcon fontSize="large" color="primary" />
                        <Typography
                          gutterBottom
                          variant="h6"
                          sx={{
                            fontFamily: "Poppins, sans-serif",
                            lineHeight: 1.8,
                            letterSpacing: "1.8px",
                          }}
                        >
                          Phone
                        </Typography>
                        <Typography
                          color="text.secondary"
                          sx={{
                            mb: -2,
                            fontFamily: "Poppins, sans-serif",
                            lineHeight: 1.8,
                            letterSpacing: "1.8px",
                          }}
                        >
                          {instituteInfoPhone}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        transition: "0.3s !important",
                        "&:hover": { transform: "scale(1.05)" },
                        borderRadius: "15px",
                        boxShadow:
                          "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                        padding: "8px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardContent sx={{ textAlign: "center" }}>
                        <MailIcon fontSize="large" color="primary" />
                        <Typography
                          gutterBottom
                          variant="h6"
                          sx={{
                            fontFamily: "Poppins, sans-serif",
                            lineHeight: 1.8,
                            letterSpacing: "1.8px",
                          }}
                        >
                          Email
                        </Typography>
                        <Typography
                          color="text.secondary"
                          sx={{
                            mb: -2,
                            fontFamily: "Poppins, sans-serif",
                            lineHeight: 1.8,
                            letterSpacing: "1.8px",
                          }}
                        >
                          {instituteInfoEmail}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </motion.div>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Contact;
