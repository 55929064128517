import React, { useState } from "react";
import { message } from "antd";
import { CircularProgress } from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DemoNavbar from "./DemoNavbar";
import PageTitle from "../PageTitle/PageTitle";
import axios from "../../axiosInstance";
import { Card } from "@mui/material";
import DemoFooter from "./DemoFooter";

const DemoContact = () => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [messages, setMessages] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");

  const [loading, setLoading] = useState(false);

  // Book A demo
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    const demoContact = {
      name,
      phoneNumber,
      email,
      city,
      messages,
    };
    try {
      const { data } = await axios.post("/api/v1/demo/contact", demoContact);

      if (data.success === true) {
        message.success(data.message);
        // Clear form fields
        setName("");
        setPhoneNumber("");
        setEmail("");
        setCity("");
        setMessages("");
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Box>
      <DemoNavbar />
      <PageTitle title="Contact Us | Test Series" />
      <Box
        sx={{ mt: { xs: 15 , md: 15 }, pl:{xs: 3, md:12},  pr:{xs: 3,md:12}, minHeight: "100vh" }}

      >
        <Typography
          align="center"
          variant="h4"
          sx={{ mt: 3, wordSpacing: "2px", letterSpacing: " 1px" }}
        >
          Get in Touch{" "}
        </Typography>
        <Typography
          align="center"
          // variant="body1"
          sx={{
            mt: 3,
            fontSize:"20px",
            wordSpacing: "2px",
            letterSpacing: " 1px",
            color: "#757575",
          }}
        >
          {" "}
          Have questions, suggestions, or need assistance? We're here to support
          you at every step.{" "}
        </Typography>
        {/* Book A Demo */}

        <Card
          variant="outlined"
          sx={{
            mt: 5,
            mb: 3,
            p: 3,
            pl:5,
            // minHeight: "100vh",
            borderRadius: "25px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            wordSpacing: "2px",
            letterSpacing: "1.5px",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              mt: 3, 
              mb:3,
              padding: { xs: 1, sm: 3, md: 0 },
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                background: "#F3F7FE",
                borderRadius: { xs: "25px", md: "25px 0 0 25px" }, 
                p: { xs: 2, md: 4 }, 
                boxSizing: "border-box", 
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 500,
                  mb: 2,
                  lineHeight: 1.5,
                  mr: { xs: 1, md: 4 },
                  ml: { xs: 1, md: 4 },
                  wordSpacing: "2px",
                  letterSpacing: " 1px",
                  textAlign: { xs: "center", md: "left" }, 
                }}
              >
                Let’s Connect
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: 2,
                  lineHeight: 2,
                  mr: { xs: 2, md: 4 },
                  ml: { xs: 2, md: 4 },
                  mt: 5,
                  wordSpacing: "2px",
                  letterSpacing: " 1px",
                  textAlign: { xs: "center", md: "left" }, 
                }}
              >
                Submit the form, and our team will respond within 24 hours.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: 2,
                  mr: { xs: 2, md: 4 },
                  ml: { xs: 2, md: 4 },
                  display: "flex",
                  alignItems: "center",
                  mt: 5,
                  wordSpacing: "2px",
                  letterSpacing: { xs: 0, sm: 1, md: 1 },
                  textAlign: { xs: "center", md: "left" },
                  flexDirection: { xs: "column", sm: "row" }, 
                  maxWidth: "100%", 
                  overflowWrap: "break-word", 
                }}
              >
                <PhoneIcon sx={{ mr: 2, color: "#1976d2" }} />
                Contact Number: +91-7891555777, +91-8442025283 
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: 2,
                  mr: { xs: 2, md: 4 },
                  ml: { xs: 2, md: 4 },
                  mt: 5,
                  display: "flex",
                  alignItems: "center",
                  wordSpacing: "2px",
                  letterSpacing: { xs: 0, sm: 1, md: 1 },
                  textAlign: { xs: "center", md: "left" },
                  flexDirection: { xs: "column", sm: "row" }, 
                  maxWidth: "100%", 
                  overflowWrap: "break-word",
                }}
              >
                <EmailIcon sx={{ mr: 2, color: "#1976d2" }} />
                E-mail: testseries@gatiktech.com
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  mb: 2,
                  mr: { xs: 2, md: 4 },
                  ml: { xs: 2, md: 4 },
                  mt: 5,
                  display: "flex",
                  alignItems: "center",
                  wordSpacing: "2px",
                  letterSpacing: { xs: 0, sm: 1, md: 1 },
                  textAlign: { xs: "center", md: "left" }, 
                  flexDirection: { xs: "column", sm: "row" }, 
                  maxWidth: "100%", 
                  overflowWrap: "break-word", 
                }}
              >
                <LocationOnIcon sx={{ mr: 2, color: "#1976d2" }} />
                Arg group, north avenue, 306A, third floor, road no 9, Sikar Rd,
                Jaipur, Rajasthan 302013
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box onSubmit={handleSubmit} component="form" maxWidth>
                <TextField
                  sx={{
                    mb: 3,
                    mt: {xs:2, sm:2, md:0, xl:0},
                    "& .MuiInputBase-root": {
                      color: "text.secondary",
                    },
                    fieldset: { borderColor: "rgb(231, 235, 240)" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  id="name"
                  label="Enter your full name"
                  name="name"
                  type="name"
                  placeholder="GatikAI Technologies "
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />

                <TextField
                  sx={{
                    mb: 3,
                    "& .MuiInputBase-root": {
                      color: "text.secondary",
                    },
                    "& input[type='number']": {
                      MozAppearance: "textfield",
                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                    },
                    fieldset: { borderColor: "rgb(231, 235, 240)" },
                  }}
                  fullWidth
                  id="phoneNumber"
                  label="Enter your contact number"
                  name="phoneNumber"
                  type="number"
                  placeholder="i.e. +911234567890"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />

                <TextField
                  sx={{
                    mb: 3,
                    mt: 2,
                    "& .MuiInputBase-root": {
                      color: "text.secondary",
                    },
                    fieldset: { borderColor: "rgb(231, 235, 240)" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  id="email"
                  label="Enter your email"
                  name="email"
                  type="email"
                  placeholder="i.e. xyz@domain.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <TextField
                  sx={{
                    mb: 3,
                    mt: 2,
                    "& .MuiInputBase-root": {
                      color: "text.secondary",
                    },
                    fieldset: { borderColor: "rgb(231, 235, 240)" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  id="city"
                  label="Located City"
                  name="city"
                  placeholder="Jaipur"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />

                <TextField
                  sx={{
                    mb: 3,
                    mt: 2,
                    "& .MuiInputBase-root": {
                      color: "text.secondary",
                    },
                    fieldset: { borderColor: "rgb(231, 235, 240)" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  id="messages"
                  label="Your Message"
                  name="messages"
                  multiline
                  rows={3}
                  placeholder="Type your message here"
                  value={messages}
                  onChange={(e) => setMessages(e.target.value)}
                />

                <Button
                  sx={{ mt: 3, p:1.5, wordSpacing: "2px", letterSpacing: " 1px" }}
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Let’s Connect"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Box sx={{ mt: 5 }}>
        <DemoFooter />
      </Box>
    </Box>
  );
};

export default DemoContact;
