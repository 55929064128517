import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  InputAdornment,
  Chip,
  Box,
  CircularProgress,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LinkIcon from "@mui/icons-material/Link";
import CloseIcon from "@mui/icons-material/Close"; // Import close icon
import { message } from "antd";
import axios from "../../axiosInstance";

const ShareExam = ({ examId, instituteId }) => {
  const [open, setOpen] = useState(false);
  const [linkCreated, setLinkCreated] = useState(false);
  const [link, setLink] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);

  // Open the modal
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Close the modal
  const handleClose = () => {
    setOpen(false);
    setLinkCreated(false);
    setLink("");
    setEmails([]);
    setEmailInput("");
  };

  // Create link function
  const handleCreateLink = () => {
    const generatedLink =
      `https://testseries.gatiktech.com/access-public-exam/${examId}/${instituteId}`;  //  Create link function Add the backend Todo
    setLink(generatedLink);
    setLinkCreated(true);
  };

  // Copy link to clipboard
  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    message.success("Link copied to clipboard!");
  };

  // Handle email input change
  const handleEmailInputChange = (event) => {
    setEmailInput(event.target.value);
  };

  // Add email to the list when user presses Enter or Comma
  const handleAddEmail = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      const newEmails = emailInput
        .split(",")
        .map((email) => email.trim())
        .filter((email) => email);

      const alreadyAddedEmails = newEmails.filter((email) =>
        emails.includes(email)
      );

      if (alreadyAddedEmails.length > 0) {
        message.warning("This email is already added. Please enter a new one.");
      } else {
        setEmails((prevEmails) => [...prevEmails, ...newEmails]);
      }

      setEmailInput("");
    }
  };

  // Remove email from the list
  const handleDeleteEmail = (emailToDelete) => {
    setEmails((prevEmails) =>
      prevEmails.filter((email) => email !== emailToDelete)
    );
  };

  // Send email by share exam link
  const handleShare = async () => {
    if (emails.length === 0) {
      message.error("Please add at least one email.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        "/api/v1/exams/admin-share-public-exams",
        {
          emails: emails,
          examId,
          instituteId,
        }
      );
      if (response?.data?.success === true) {
        message.success(response.data.message);
      } else {
        message.error(response?.data?.message || "Error sending emails");
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Error sending emails");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div>
      {/* Share Exam Button */}
      <IconButton color="primary" onClick={handleClickOpen}>
        <ShareIcon />
      </IconButton>

      {/* Modal for Sharing Exam */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="share-exam-dialog"
        sx={{
          borderRadius: "20px",
        }}
      >
        <DialogTitle
          id="share-exam-dialog"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <AssignmentIcon
              color="primary"
              style={{ marginRight: "8px", verticalAlign: "middle" }}
            />{" "}
            Share Exam
          </div>
          {/* Close Button */}
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ color: "gray" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the email addresses of recipients to share the exam link.
          </DialogContentText>

          {/* TextField with Create/Copy Link Button */}
          <TextField
            sx={{ mt: 2 }}
            margin="dense"
            id="exam-link"
            label="Create Exam Link"
            placeholder="Create Exam Link"
            type="text"
            fullWidth
            variant="outlined"
            value={link}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  {!linkCreated ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCreateLink}
                      startIcon={<LinkIcon />}
                      style={{ marginLeft: "10px" }}
                      sx={{
                        justifyContent: "space-between",
                        borderRadius: "5px",
                        textTransform: "none",
                        fontFamily: "Poppins, sans-serif",
                        letterSpacing: ".1rem",
                      }}
                    >
                      Create Link
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCopyLink}
                      startIcon={<ContentCopyIcon />}
                      style={{ marginLeft: "10px" }}
                      sx={{
                        justifyContent: "space-between",
                        borderRadius: "5px",
                        textTransform: "none",
                        fontFamily: "Poppins, sans-serif",
                        letterSpacing: ".1rem",
                      }}
                    >
                      Copy Link
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Recipient's Email"
            placeholder="Enter emails separated by commas"
            type="email"
            fullWidth
            variant="outlined"
            value={emailInput}
            onChange={handleEmailInputChange}
            onKeyDown={handleAddEmail}
          />
          {/* Display added emails as chips */}
          <Box mt={2} sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {emails.map((email) => (
              <Chip
                key={email}
                label={email}
                onDelete={() => handleDeleteEmail(email)}
                color="primary"
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <Button
            variant="contained"
            disabled={loading}
            color="primary"
            onClick={handleShare}
            sx={{
              marginLeft: "16px",
              borderRadius: "5px",
              textTransform: "none",
              fontFamily: "Poppins, sans-serif",
              letterSpacing: ".1rem",
              mb: 3,
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Share Exam Link"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ShareExam;
