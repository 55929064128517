import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Paper,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "../../axiosInstance";
import { message, Modal } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditRole  from "./EditRole";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EditJobNotification from "./EditJobNotification";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function JobNotification() {
  const [value, setValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  const [latestPostName, setLatestPostName] = useState("");
  const [latestLastDate, setLatestLastDate] = useState(null);
  const [latestNotificationDetails, setLatestNotificationDetails] =
    useState("");
  const [urlJobNotification, setUrlJobNotification] = useState("");
  const [jobPost, setJobPost] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(startDate);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleJobPostStartDateChange = (date) => {
    setLatestLastDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const jobPostStartDate = moment(startDate).format("MM/DD/YYYY");
  const jobPostEndDate = moment(endDate).format("MM/DD/YYYY");
  const jobPostDate = moment(latestLastDate).format("MM/DD/YYYY");

  // Get Job Post Data
  const getAllJobPost = async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/admin-get-job-notification`
      );
      if (response?.data?.success === true) {
        setJobPost(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllJobPost();
    return () => clearInterval(intervalId);
  }, []);

  // Delete Job Post Notification Details
  const { confirm } = Modal;
  const deleteJobPost = async (e, id) => {
    // Show Ant Design confirmation modal
    confirm({
      title: "Are you sure you want to delete this Job Notification?",
      content:
        "This action cannot be undone. Please confirm if you want to proceed.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      okType: "danger",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/admin/admin-delete-job-notification",
            {
              data: { id },
            }
          );
          if (data?.success === true) {
            message.success(data?.message);
            getAllJobPost();
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Error deleting job notification."
          );
        }
      },
      onCancel() {
        console.log("Deletion action canceled");
      },
    });
  };

  const columns = [
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteJobPost(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditJobNotification
            refreshData={getAllJobPost}
            latestPostId={value?.row?._id}
            latestPostName={value?.row?.latestPostName}
            latestNotificationDetails={value?.row?.latestNotificationDetails}
            urlJobNotification={value?.row?.urlJobNotification}
            latestLastDate={value?.row?.latestLastDate}
            startDate={value?.row?.startDate}
            endDate={value?.row?.endDate}
            instituteId={value?.row?.instituteId}
            vendorId={value?.row?.vendorId}
          />
        </Box>
      ),
    },
    // {
    //   field: "instituteId",
    //   headerName: "institute",
    //   width: 180,
    //   editable: true,
    // },
    {
      field: "latestPostName",
      headerName: "Post Name",
      width: 200,
      editable: true,
    },
    {
      field: "latestLastDate",
      headerName: "Post Last Date",
      width: 180,
      editable: true,
    },
    {
      field: "latestNotificationDetails",
      headerName: "Notification Details",
      width: 200,
      editable: true,
    },

    {
      field: "urlJobNotification",
      headerName: "Notification URL",
      width: 160,
      editable: true,
      renderCell: (params) => {
        const url = params.value;
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              sx={{
                borderRadius: "50px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              {" "}
              View Details{" "}
            </Button>
          </a>
        );
      },
    },

    {
      field: "startDate",
      headerName: "Start Date",
      width: 180,
      editable: true,
    },

    {
      field: "endDate",
      headerName: "End Date",
      width: 180,
      editable: true,
    },
  ];
  // Conditionally add the instituteId column if user.role === 1
  if (user && user.role === 1) {
    columns.splice(1, 0, {
      field: "instituteId",
      headerName: "Institute Id",
      width: 180,
      editable: true,
    });
  }

  // Add Job Notification Details
  const handleSubmit = async (e) => {
    e.preventDefault();
    const addJopPost = {
      latestPostName,
      latestLastDate: jobPostDate,
      latestNotificationDetails,
      urlJobNotification,
      vendorId: user?._id,
      instituteId: user?.instituteId,
      startDate: jobPostStartDate,
      endDate: jobPostEndDate,
    };

    try {
      const { data } = await axios.post(
        "/api/v1/admin/create-job-notification",
        addJopPost
      );
      if (data.success === true) {
        message.success(data.message);
        // Clear form fields
        setLatestPostName("");
        setLatestNotificationDetails("");
        setLatestLastDate(null);
        setStartDate(null);
        setEndDate(null);
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <PageTitle title="Admin Job Notification | Test Series" />
      <Box>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Institute Notifications
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 3,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="/admin/institute/info"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Institute Information
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>
            Institute Notifications
          </Typography>
        </Breadcrumbs>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Notification List" {...TabPanel(0)} />
            <Tab label="Add Notification" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/*  Notification Details */}
        <CustomTabPanel value={value} index={0}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Notification Details
          </Typography>
          <Paper sx={{ bgcolor: "white" }}>
            <Box sx={{ height: 500, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row?._id}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                  // Style for header cells
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                    // fontSize: "13px",
                  },
                  // Style for checkbox in header
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                rows={jobPost}
                columns={columns}
                pageSize={3}
                rowsPerPageOptions={[3]}
                // checkboxSelection
              />
            </Box>
          </Paper>
        </CustomTabPanel>

        {/*  Create a new Job Notification */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Create a new Notification
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="latestPostName"
              label="Latest Post Name"
              name="latestPostName"
              InputLabelProps={{
                shrink: true,
              }}
              required
              placeholder="Latest Post Name"
              value={latestPostName}
              onChange={(e) => setLatestPostName(e.target.value)}
            />

            <Box style={{ display: "flex" }} className="Date">
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  fullWidth
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Notification Last Date"
                  selected={latestLastDate}
                  value={latestLastDate}
                  onChange={handleJobPostStartDateChange}
                  minDate={(new Date() && latestLastDate) || new Date()}
                  maxDate={latestLastDate}
                  required
                />
              </Box>
            </Box>

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="latestNotificationDetails"
              label="Latest Notification Details"
              name="latestNotificationDetails"
              InputLabelProps={{
                shrink: true,
              }}
              required
              multiline
              placeholder="Latest Notification Details"
              value={latestNotificationDetails}
              onChange={(e) => setLatestNotificationDetails(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="urlJobNotification"
              label="Notification URL"
              name="urlJobNotification"
              InputLabelProps={{
                shrink: true,
              }}
              required
              placeholder="Notification URL"
              value={urlJobNotification}
              onChange={(e) => setUrlJobNotification(e.target.value)}
            />

            <Box style={{ display: "flex" }} className="Date">
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Start Date"
                  selected={startDate}
                  value={startDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
              <Box>
                <DatePicker
                  required
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="End Date"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  value={endDate}
                  // eslint-disable-next-line no-mixed-operators
                  minDate={(new Date() && startDate) || new Date()}
                  maxDate={endDate}
                />
              </Box>
            </Box>

            <Button
              variant="contained"
              type="submit"
              color="primary"
              className="courses_desc"
              sx={{
                mt: 3,
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              Add Notification
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
}
