import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Select,
  CircularProgress,
  MenuItem,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../axiosInstance";
import { message } from "antd";
import moment from "moment";

const StudentGroupReportShare = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [userReports, setUserReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [instituteInfo, setInstituteInfo] = useState([]);
  const [instituteUserInfo, setInstituteUserInfo] = useState([]);

  const [reportData, setReportData] = useState({
    ...state?.report,
    academicSession: "2024-25",
    courseName: "",
    classSignature: "",
    principalSignature: "",
    addComments: "",
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setReportData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const userEmail = reportData.userEmail;
  const instituteId = reportData?.instituteId;

  // Fetch all reports based on the selected userEmail
  const fetchUserReports = async () => {
    try {
      const response = await axios.post(
        `/api/v1/reports/admin-user-email-group-reports`,
        { userEmail }
      );
      setUserReports(response?.data?.data);
    } catch (error) {
      message.error("Failed to fetch user reports.");
    }
  };

  useEffect(() => {
    fetchUserReports();
  }, [reportData.userEmail]);

  useEffect(() => {
    const fetchCourseName = async () => {
      if (reportData?.instituteCourseId) {
        // Check if the instituteCourseId matches the specific ID
        if (reportData.instituteCourseId === "81af3e57846a42aab058dc4d") {
          setReportData((prev) => ({
            ...prev,
            courseName: "General",
          }));
        } else {
          try {
            const response = await axios.post(
              "/api/v1/vendor/admin/get-course-details-by-id-institute",
              { instituteCourseId: reportData?.instituteCourseId }
            );

            setReportData((prev) => ({
              ...prev,
              courseName: response.data?.data?.courseName || "",
            }));
          } catch (error) {
            console.error("Error fetching course name:", error);
          }
        }
      }
    };

    fetchCourseName();
  }, [reportData?.instituteCourseId]);
  
  useEffect(() => {
    const getAllInstituteInfo = async () => {
      try {
        // const response = await axios.get(
        //   `/api/v1/vendor/admin-get-about-institute`
        // );
        const response = await axios.post(
          `/api/v1/reports/get-institute-info-by-report-instituteId`
        , {instituteId});
        if (response?.data?.success === true) {
          setInstituteInfo(response?.data?.data);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    };
    getAllInstituteInfo();
  }, []);

  useEffect(() => {
    const fetchUserInfoByUserId = async () => {
      if (reportData?.userId) {
        try {
          const response = await axios.post(
            "/api/v1/batch/admin-get-institute-user-info-by-id-batch",
            { userId: reportData?.userId }
          );
          if (response?.data?.success === true) {
            setInstituteUserInfo(response?.data?.user);
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      }
    };
    fetchUserInfoByUserId();
  }, [reportData?.userId]);

  useEffect(() => {
    sessionStorage.setItem("reportData", JSON.stringify(reportData));
  }, [reportData]);

  const getTotalGrade = (percentage) => {
    if (percentage >= 90) return "A+";
    if (percentage >= 80) return "A";
    if (percentage >= 70) return "B+";
    if (percentage >= 60) return "B";
    return "C";
  };
  const currentDate = moment().format('MMMM Do YYYY')

  // Handle Student Group Report Share
  const handleShareReport = async () => {
    setLoading(true);
    // Extract repeating data as an array of report objects
    const reportDataArray = userReports.map((report) => {
      const totalMarks = report?.result?.totalMarks;
      const marksObtained = report?.result?.obtainedMarks;
      const percentage = (marksObtained / totalMarks) * 100;
      const rank = percentage >= 85 ? "1st" : percentage >= 75 ? "2nd" : "3rd";

      return {
        courseName: reportData.courseName,
        subject: report.examName,
        totalMarks,
        marksObtained,
        percentage,
        rank,
        grade: getTotalGrade(percentage),
        result: report?.result?.verdict,
      };
    });
    
    // Define the common data to pass once
    const commonData = {
      logo: instituteInfo[0]?.instituteLogo,
      instituteName: instituteInfo[0]?.instituteName,
      instituteAddress: instituteInfo[0]?.instituteAddress,
      institutePhone: instituteInfo[0]?.institutePhone,
      instituteEmail: instituteInfo[0]?.instituteEmail,
      // courseClass: reportData.courseClass,
      academicSession: reportData?.academicSession,
      userName: reportData.userName,
      userEmail: reportData.userEmail,
      parentName: instituteUserInfo?.parentName,
      parentEmail: instituteUserInfo?.parentEmail,
      comments: reportData?.addComments,
      reportDate: currentDate,
      classTeacher: reportData?.classSignature,
      principalSignature: reportData?.principalSignature,
    };
    // Send common data once, with report data array
    try {
        const response = await axios.post("/api/v1/reports/admin-group-share-reports", {
            commonData,
            reportDataArray,
        });
        message.success(response.data.message || "Reports shared successfully!");
        sessionStorage.removeItem("reportData");
        navigate("/admin/student/share/all/report");
    } catch (error) {
        message.error("Failed to share reports.");
    } finally {
        setLoading(false);
    }
  };
  
  // Handle Show Report Preview
  const handleShowPreview = () => {
    const commonData = {
      logo: instituteInfo[0]?.instituteLogo,
      instituteName: instituteInfo[0]?.instituteName,
      instituteAddress: instituteInfo[0]?.instituteAddress,
      institutePhone: instituteInfo[0]?.institutePhone,
      instituteEmail: instituteInfo[0]?.instituteEmail,
      // courseClass: reportData.courseClass,
      academicSession: reportData?.academicSession,
      userName: reportData.userName,
      userEmail: reportData.userEmail,
      parentName: instituteUserInfo?.parentName,
      parentEmail: instituteUserInfo?.parentEmail,
      comments: reportData?.addComments,
      reportDate: currentDate,
      classTeacher: reportData?.classSignature,
      principalSignature: reportData?.principalSignature,
    };
    const reportDataArray = userReports.map((report) => {
      const totalMarks = report?.result?.totalMarks;
      const marksObtained = report?.result?.obtainedMarks;
      const percentage = (marksObtained / totalMarks) * 100;
      const rank = percentage >= 85 ? "1st" : percentage >= 75 ? "2nd" : "3rd";

      return {
        courseName: reportData.courseName,
        subject: report.examName,
        totalMarks,
        marksObtained,
        percentage,
        rank,
        grade: getTotalGrade(percentage),
        result: report?.result?.verdict,
      };
    });

    sessionStorage.setItem("reportData", JSON.stringify(reportData));
    navigate("/admin/student/share/group/report/preview", {
      state: { commonData, reportDataArray },
    });
  };

  return (
    <div>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h5" sx={{ mb: 3 }} align="center" gutterBottom>
          Student Group Report Details
        </Typography>

        {/* Breadcrumbs and Paper UI */}
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="/admin/student/share/all/report"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Reports
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>
            {" "}
            Student Group Report{" "}
          </Typography>
        </Breadcrumbs>
        <Paper sx={{ padding: 3, margin: "0 auto", borderRadius: "10px" }}>
          <TextField
            fullWidth
            label="Student Email"
            name="userEmail"
            value={reportData.userEmail || ""}
            onChange={handleChange}
            margin="normal"
            disabled
          />
          <TextField
            fullWidth
            label="Academic Session"
            name="academicSession"
            value={reportData.academicSession}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Class Teacher Name"
            name="classSignature"
            value={reportData.classSignature}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Principal Name"
            name="principalSignature"
            value={reportData.principalSignature}
            onChange={handleChange}
            margin="normal"
            required
          />

          <TextField
            fullWidth
            label="Add Comments"
            name="addComments"
            placeholder="Great performance! Keep up the good work!"
            value={reportData.addComments}
            onChange={handleChange}
            margin="normal"
            // required
          />

          <Button
            disabled={loading}
            type="submit"
            onClick={handleShareReport}
            variant="contained"
            color="primary"
            className="courses_desc"
            sx={{
              mt: 3,
              borderRadius: "5px",
              textTransform: "none",
              fontFamily: "Poppins, sans-serif",
              letterSpacing: ".1rem",
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Share Report"}
          </Button>
          <Button
            onClick={handleShowPreview}
            variant="outlined"
            sx={{ ml: 2, mt: 3 }}
          >
            Show Preview
          </Button>
        </Paper>
      </Box>
    </div>
  );
};

export default StudentGroupReportShare;
