import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Container,
  Avatar,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { message, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SchoolIcon from "@mui/icons-material/School";
import ArticleIcon from "@mui/icons-material/Article";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { logout } from "../redux/actions/userAction";


const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isActive, setIsActive] = useState(true);
  const [bottomNavValue, setBottomNavValue] = useState(-1);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

// Update bottomNavValue based on current location
useEffect(() => {
  const currentPath = window.location.pathname;
  switch (currentPath) {
    case "/exams":
      setBottomNavValue(0);
      break;
    case "/subjective/exams":
      setBottomNavValue(1);
      break;
    case "/notes":
      setBottomNavValue(2);
      break;
    case "/courses":
      setBottomNavValue(3);
      break;
    default:
      setBottomNavValue(-1); // Reset to default if no match
      break;
  }
}, [window.location.pathname]);

// Handle bottom navigation change
const handleBottomNavChange = (event, newValue) => {
  setBottomNavValue(newValue);
  switch (newValue) {
    case 0:
      navigate("/exams");
      break;
    case 1:
      navigate("/subjective/exams");
      break;
    case 2:
      navigate("/notes");
      break;
    case 3:
      navigate("/courses");
      break;
    default:
      break;
  }
};

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  // Handle Logout api call
  const { confirm } = Modal;
  const handleLogout = () => {
    confirm({
      title: "Are you sure you want to Log out?",
      icon: <ExclamationCircleOutlined />,
      content: "Your current session will be terminated.",
      onOk() {
        dispatch(logout());
        localStorage.clear(); 
        setIsActive(false);    
        message.success("Logout Successfully");
        window.location.reload(); 
      },
      // onCancel() {
      //   message.info("Logout cancelled");
      // }
    });
  };

  return (
    <div>
      <AppBar
        sx={{
          backgroundColor: "#FFF",
          // backgroundColor: "#f0f4f8",
          color: "#000",
          fontWeight: "600",
          position: "absolute",
          p: 1.5,
          width: "100%",
          position: "fixed",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontWeight: 600,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              TEST SERIES
            </Typography>

            {/* Menu Icon for Mobile */}
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              {/* Menu for Mobile */}
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <ul style={{ padding: "2px" }}>
                    <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/"
                      >
                        Home
                      </Link>
                    </li>
                    <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/exams"
                      >
                        Exams
                      </Link>
                    </li>
                    <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/subjective/exams"
                      >
                        Subjective Exams
                      </Link>
                    </li>

                    <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/notes"
                      >
                        Notes
                      </Link>
                    </li>
                    <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/courses"
                      >
                        Courses
                      </Link>
                    </li>
                    <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/contact"
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </MenuItem>
              </Menu>
            </Box>

            {/* Logo */}
            {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
            <Typography
              variant="h5"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontWeight: 600,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              TEST SERIES
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                }}
                to="/"
              >
                Home
              </Link>

              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                }}
                to="/exams"
              >
                Exams
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                }}
                to="/subjective/exams"
              >
                Subjective Exams
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                }}
                to="/notes"
              >
                Notes
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                }}
                to="/courses"
              >
                Courses
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                }}
                to="/contact"
              >
                Contact
              </Link>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  {/* <Avatar alt={user?.name}  src={user?.profileImage} /> */}
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                    color={isActive ? "success" : "error"}
                    sx={{
                      "& .MuiBadge-dot": {
                        height: "12px",
                        width: "12px",
                        borderRadius: "50%",
                        backgroundColor: isActive ? "green" : "red",
                        border: "2px solid white",
                      },
                    }}
                  >
                    <Avatar
                      sx={{ width: 50, height: 50, cursor: "pointer" }}
                      alt={user?.studentName}
                      src={user?.profileImage}
                    />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleCloseUserMenu}>
                  <>
                    <ul style={{ padding: "2px" }}>
                      <li style={{ textDecoration: "none", listStyle: "none" }}>
                        <Link
                          style={{ textDecoration: "none", color: "inherit" }}
                          to="/student/dashboard"
                        >
                          Dashboard
                        </Link>
                      </li>

                      {/* <li
                        style={{
                          textDecoration: "none",
                          listStyle: "none",
                          marginTop: "8px",
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                            marginTop: "12px",
                          }}
                          to="/profile"
                        >
                          Profile
                        </Link>
                      </li> */}

                      <li
                        style={{
                          textDecoration: "none",
                          listStyle: "none",
                          marginTop: "8px",
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                            marginTop: "12px",
                          }}
                          to="/reports"
                        >
                          Reports
                        </Link>
                      </li>

                      <li
                        style={{
                          textDecoration: "none",
                          listStyle: "none",
                          marginTop: "8px",
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                            marginTop: "12px",
                          }}
                          to="/reports/top-rank"
                        >
                          TopRank
                        </Link>
                      </li>
                      <hr />
                      {isAuthenticated ? (
                        <li
                          style={{
                            textDecoration: "none",
                            listStyle: "none",
                            marginTop: "12px",
                          }}
                        >
                          {isAuthenticated && (
                            <li onClick={handleLogout}>
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                  marginTop: "12px",
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                                to="#"
                              >
                                {isAuthenticated && "Logout"}
                              </Link>
                            </li>
                          )}
                        </li>
                      ) : (
                        <>
                          <li
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                              marginTop: "12px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                              textAlign="center"
                              to="/login"
                            >
                              Login
                            </Link>
                          </li>
                        </>
                      )}
                    </ul>
                  </>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {/* Bottom Navigation for Mobile */}
      {isMobile && (
        <BottomNavigation
          value={bottomNavValue}
          onChange={handleBottomNavChange}
          showLabels
          sx={{ position: "fixed", bottom: 0, width: "100%", zIndex: 1300 }}
        >
          <BottomNavigationAction label="Exams" icon={<SchoolIcon />} />
          <BottomNavigationAction label="Subjective Exams" icon={<MenuBookIcon />} />
          <BottomNavigationAction label="Notes" icon={<LibraryBooksIcon />} />
          <BottomNavigationAction label="Courses" icon={<ArticleIcon />} />
        </BottomNavigation>
      )}
    </div>
  );
};

export default Navbar;


