import React from "react";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import { Box, Typography, Paper, Divider } from "@mui/material";
import Footer from "../Components/Footer";

const TermsOfService = () => {
  return (
    <>
      <Navbar />
      <PageTitle title="Terms and Conditions | Test Series" />
      <Box sx={{ mt: { xs: 16, sm: 16, md: 16, lg: 18 }, mb: 8, mx: { md: 12, xs: 2 }, minHeight: "100vh", backgroundColor: '#f9f9f9' }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: "15px", backgroundColor: '#ffffff' }}>
          <Typography
            variant="h3"
            align="center"
            // className="courses_title"
            gutterBottom
            sx={{
              letterSpacing: "3px",
              lineHeight: 1.8,
              fontFamily: "Poppins, sans-serif",  
              fontWeight: "bold",
              color: "#2C3E50",
            }}  
          >
            Terms and Conditions
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#000",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            1. Introduction
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt:2,
              mb:5,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              // textAlign: "center",
              letterSpacing: "1.8px",
            }}
          >
            Welcome to Test Series! These Terms and Conditions govern your use of our website and services. By accessing or using our services, you agree to comply with these Terms. If you do not agree, please do not use our services.
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#000",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            2. Services Provided
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt:2,
              mb:5,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              // textAlign: "center",
              letterSpacing: "1.8px",
            }}
          >
            GatikAI Technologies provides a multi-vendor platform for educational institutes to offer various services, including online exams, live assessments, practice tests, video lectures, and downloadable reports. The specific services provided may vary and are subject to change.
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#000",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            3. User Responsibilities
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt: 2,
              mb:5,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              // textAlign: "center",
              letterSpacing: "1.8px",
            }}
          >
            Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account. Users agree to use the services only for lawful purposes and not to engage in any conduct that restricts or inhibits anyone’s use or enjoyment of the services.
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#000",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            4. Intellectual Property
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt: 2,
              mb:5,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              // textAlign: "center",
              letterSpacing: "1.8px",
            }}
          >
            All content, trademarks, and other intellectual property associated with GatikAI Technologies and its services are owned by GatikAI Technologies or its licensors. Users are granted a limited, non-exclusive, and non-transferable license to access and use the services for personal and educational purposes only.
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#000",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            5. Limitation of Liability
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt:2,
              mb:5,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              // textAlign: "center",
              letterSpacing: "1.8px",
            }}
          >
            In no event shall GatikAI Technologies be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from or related to your use of the services. Users acknowledge that they use the services at their own risk.
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#000",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            6. Amendments
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt:2,
              mb:5,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              // textAlign: "center",
              letterSpacing: "1.8px",
            }}
          >
            GatikAI Technologies reserves the right to modify these Terms and Conditions at any time. Users will be notified of any significant changes, and continued use of the services after such changes constitutes acceptance of the revised Terms.
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#000",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            7. Governing Law
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt:2,
              mb:5,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              // textAlign: "center",
              letterSpacing: "1.8px",
            }}
          >
            These Terms and Conditions shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles.
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#000",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            8. Contact Information
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt:2,
              mb:5,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              // textAlign: "center",
              letterSpacing: "1.8px",
            }}
          >
            For any questions regarding these Terms and Conditions, please contact us at:
            <br />
            E-mail: testseries@gatiktech.com
            <br />
            Contact Number: +91-7891555777
          </Typography>

          <Divider sx={{ mb: 3 }} />
        </Paper>
      </Box>
      <Footer />
    </>
  );
};

export default TermsOfService;

