import { 
    EXAM_REQUEST,
    EXAM_SUCCESS,
    EXAM_FAIL,
    CLEAR_ERRORS,
 } from "../constants/examConstants";


export const examReducer = (state = {exam:{}}, action) => {
    switch (action.type) {
        case EXAM_REQUEST:
            return {
                loading: true,
                isAuthenticated: false,
            };

        case EXAM_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                exam: action.payload,
            };

        case EXAM_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                exam: null,
                error: action.payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }

};
