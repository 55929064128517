import React, { useState } from "react";
import { Modal } from "antd";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Container, TextField, Button, Chip, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

const SubViewCategoryPermissions = ({
  permissionName,
  institute,
  userId,
  permissionID,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [permissionsName, setPermissionsName] = useState(
    permissionName[0]?.permission_name
  );

  // eslint-disable-next-line no-unused-vars
  const [permissionValue, setPermissionValue] = useState(
    permissionName[0]?.permission_value
  );

  const [isModalOpen, setIsModalOpen] = useState(false);


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    // window.location.reload();
  };

  const getMenuItemLabel = (value) => {
    switch (value) {
      case 0:
        return "Create";
      case 1:
        return "Read";
      case 2:
        return "Update";
      case 3:
        return "Delete";
      default:
        return "";
    }
  };

  return (
    <>
      <IconButton aria-label="edit" onClick={showModal}>
        <VisibilityIcon sx={{ color: "#1976d2" }} />
      </IconButton>
      <Modal
        title="View Permissions"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
       >
        <Container
          // onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
          maxWidth
        >
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="permissionsId"
            label="Permission Name"
            name="permissionsId"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Permission Name"
            value={permissionsName}
          />

          <Box sx={{ width: "100%", mb: 3 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {" "}
                Permissions{" "}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={permissionValue}
                label="Permissions"
                required
                multiple
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <Chip
                        sizes="medium"
                        key={value}
                        label={getMenuItemLabel(value)}
                      />
                    ))}
                  </div>
                )}
              >
                <MenuItem value={0}>Create</MenuItem>
                <MenuItem value={1}>Read</MenuItem>
                <MenuItem value={2}>Update</MenuItem>
                <MenuItem value={3}>Delete</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Button variant="outlined" onClick={handleCancel}>
            Close
          </Button>
        </Container>
      </Modal>
    </>
  );
};

export default SubViewCategoryPermissions;
