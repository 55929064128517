import React from "react";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import {Modal} from "antd";

const DownloadCSV = () => {

  const downloadCSV = () => {
  Modal.confirm({
    title: "Download Sample CSV",
    content: "Would you like to download the sample CSV file?",
    okText: "Yes, Download",
    cancelText: "No, Cancel",
    onOk: () => {
      // Path to the CSV file in the public directory
      const csvFilePath = "/subjective_sample.csv";

      fetch(csvFilePath)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "subjective_sample.csv";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error fetching the file:", error);
        });
    },
    onCancel() {
      console.log("Download canceled.");
    },
  });
};

  return (
    <Button
      variant="contained"
      onClick={downloadCSV}
      color="primary"
      startIcon={<DownloadIcon />}
      className="courses_desc"
      sx={{
        mb: 2,
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "5px",
        textTransform: "none",
        fontFamily: "Poppins, sans-serif",
        letterSpacing: ".1rem",
      }}
    >
      Download Sample CSV
    </Button>
  );
};

export default DownloadCSV;
