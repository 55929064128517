import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
// dashboard imports
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  IconButton,
  Badge,
  useMediaQuery,
  TextField,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Divider,
  Select,
} from "@mui/material";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import SchoolIcon from "@mui/icons-material/School";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import StarIcon from "@mui/icons-material/Star";
// Use for achievements medals
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { message, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import AllInstitutes from "../Components/TestSeriesInformation/AllInstitutes";
import axios from "../axiosInstance";
import { logout } from "../redux/actions/userAction";
import Footer from "../Components/Footer";
import PageTitle from "../Components/PageTitle/PageTitle";
import TestSeriesLogo from "../assets/TS_Logo.png";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: "20px",
    height: "auto",
  },
  card: {
    borderRadius: "20px !important",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1) !important",
    marginTop: "20px",
    marginBottom: "20px",
    transition: "0.3s !important",
    "&:hover": { transform: "scale(1.05)" },
  },
  cardHeader: {
    height: 160,
    width: 200,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    justifyItems: "center",
    backgroundSize: "cover",
    WebkitBackgroundOrigin: "border-box",
  },
  nextClass: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    background: "#1976d2 !important",
    color: "white !important",
    border: "none",
    borderRadius: "24px !important",
    padding: "10px 24px",
    fontSize: 16,
    cursor: "pointer",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    transition: "background 0.3s ease-in-out, transform 0.2s ease-in-out",
  },
  nextClassHover: {
    background: "linear-gradient(90deg, #1976d2 0%, #000 100%)",
    transform: "scale(1.05)",
  },
});

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const StudentDashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.user);
  const [showMore, setShowMore] = useState(false);
  const [showMoreCourses, setShowMoreCourses] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [allStudentInstitute, setAllStudentInstitute] = useState([]);
  const [allReportByStudent, setAllReportByStudent] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Objective Exams",
        data: [],
        fill: true,
        backgroundColor: "rgba(125, 92, 192, 0.2)",
        borderColor: "rgba(125, 92, 192, 1)",
        pointBorderColor: "rgba(125, 92, 192, 1)",
        pointBackgroundColor: "rgba(255, 255, 255, 1)",
        tension: 0.4,
      },
      {
        label: "Subjective Exams",
        data: [],
        fill: true,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        pointBorderColor: "rgba(75, 192, 192, 1)",
        pointBackgroundColor: "rgba(255, 255, 255, 1)",
        tension: 0.4,
      },
    ],
  });
  const [loading, setLoading] = useState(true);
  const userId = user?._id;
  const [topReport, setTopReport] = useState([]);
  const [topSubjectiveReport, setTopSubjectiveReport] = useState([]);
  const [topReportData, setTopReportData] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredInstitutes, setFilteredInstitutes] =
    useState(allStudentInstitute);
  const [filteredCourses, setFilteredCourses] = useState(
    user?.studentCourse || []
  );
  const [notificationEl, setNotificationEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [studentEnrollCourses, setStudentEnrollCourses] = useState([]);
  const [requestCanceled, setRequestCanceled] = useState([]);
  const [seenRequests, setSeenRequests] = useState(new Set());
  const [showCourses, setShowCourses] = useState(false);
  const activeCourses = studentEnrollCourses || [];
  const [getInstitute, setGetInstitute] = useState([]);
const [activeRequestCanceledCourses, setActiveRequestCanceledCourses] = useState([])

  const handleNotificationClick = (event) => {
    setNotificationEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationEl(null);
  };

  // Handle opening the menu when clicking the avatar
  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowCourses = () => {
    setShowCourses((prev) => !prev);
  };
  
  // Show more cards on button onclick
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleShowMoreCourses = () => {
    setShowMoreCourses(!showMoreCourses);
  };

  // Media queries to detect screen sizes
  const isExtraSmall = useMediaQuery("(max-width:600px)");
  const isSmall = useMediaQuery("(min-width:600px) and (max-width:960px)");
  const isMedium = useMediaQuery("(min-width:960px) and (max-width:1280px)");
  const isLarge = useMediaQuery("(min-width:1280px)");
  // Set the number of items to show based on screen size
  let itemsToShow;
  if (isExtraSmall) {
    itemsToShow = 2;
  } else if (isSmall) {
    itemsToShow = 3;
  } else if (isMedium) {
    itemsToShow = 4;
  } else if (isLarge) {
    itemsToShow = 6;
  }

  // Get Student All Report Data
  const fetchReportData = async () => {
    try {
      const response = await axios.post(
        "/api/v1/batch/institute-student-exam-chart",
        { userId }
      );
      if (response?.data?.success) {
        const reportData = response?.data?.data;

        // Extracting data for chart
        const labels = reportData?.map((entry) => entry.month);
        const objectiveData = reportData?.map((entry) => entry.objectiveExams);
        const subjectiveData = reportData?.map(
          (entry) => entry.subjectiveExams
        );

        // Update chart data state
        setChartData({
          labels,
          datasets: [
            {
              label: "Objective Exams",
              data: objectiveData,
              fill: true,
              backgroundColor: "rgba(125, 92, 192, 0.2)",
              borderColor: "rgba(125, 92, 192, 1)",
              pointBorderColor: "rgba(125, 92, 192, 1)",
              pointBackgroundColor: "rgba(255, 255, 255, 1)",
              tension: 0.4,
            },
            {
              label: "Subjective Exams",
              data: subjectiveData,
              fill: true,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              pointBorderColor: "rgba(75, 192, 192, 1)",
              pointBackgroundColor: "rgba(255, 255, 255, 1)",
              tension: 0.4,
            },
          ],
        });
      }
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReportData();
  }, [userId]);

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: 15,
        title: {
          display: false,
          text: "Grade",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  // Get All Institute Student
  const getAllStudentInstitute = async () => {
    try {
      const response = await axios.get(
        `/api/v1/vendor/get-all-student-institute-info`
      );
      setAllStudentInstitute(response?.data?.data);
    } catch (error) {
      message.error(error.response?.data?.message);
    }
  };

  // Get All Reports
  const getAllReports = async () => {
    try {
      const response = await axios.get(
        `/api/v1/batch/institute-student-all-report`
      );
      setAllReportByStudent(response?.data?.data?.totalExams);
    } catch (error) {
      message.error(error.response?.data?.message);
    }
  };

  // // // Get All Top Reports
  // const getStudentTopReports = async () => {
  //   try {
  //     const response = await axios.get(
  //       `/api/v1/batch/institute-student-top-report`
  //     );
  //     setTopReport(response?.data?.maxCorrectAnswersPerExam);
  //     setTopReportData(response?.data?.totalMaxCorrectAnswers);
  //   } catch (error) {
  //     message.error(error.response?.data?.message);
  //   }
  // };

  // Function to get objective report data
  const getStudentTopReports = async () => {
    try {
      const response = await axios.get(`/api/v1/batch/institute-student-top-report`,);
      const objectiveData = response?.data?.maxCorrectAnswersPerExam
        ? Object.keys(response.data.maxCorrectAnswersPerExam).map((examId) => {
            const exam = response.data.maxCorrectAnswersPerExam[examId];
            return {
              ...exam,
              examType: "Objective",
              obtainedMarks: exam.result?.obtainedMarks,
              totalMarks: exam?.totalMarks,
              instituteCourseId: exam?.instituteCourseId,
            };
          })
        : [];
      
      setTopReport(objectiveData);
      setTopReportData(response?.data?.totalMaxCorrectAnswers);
      return objectiveData;
    } catch (error) {
      message.error(error?.response?.data?.message);
      return [];
    }
  };

  // // Get All Subjective Top Reports
  // const getStudentTopSubjectiveReports = async () => {
  //   try {
  //     const response = await axios.get(
  //       `/api/v1/batch/institute-student-top-subjective-report`
  //     );
  //     setTopSubjectiveReport(response?.data?.topRankData);
  //   } catch (error) {
  //     message.error(error.response?.data?.message);
  //   }
  // };

 // Function to get subjective report data
 const getStudentTopSubjectiveReports = async () => {
  try {
    const response = await axios.get(`/api/v1/batch/institute-student-top-subjective-report`);
    const subjectiveData = response?.data?.topRankData
      ? Object.keys(response.data.topRankData).map((examId) => {
          const exam = response.data.topRankData[examId];
          return {
            ...exam,
            examType: "Subjective",
            obtainedMarks: exam.totalMarksObtained,
            totalMarks: exam?.totalMarks,
            instituteCourseId: exam?.instituteCourseId,
          };
        })
      : [];

    setTopSubjectiveReport(subjectiveData);
    return subjectiveData;
  } catch (error) {
    message.error(error?.response?.data?.message);
    return [];
  }
};

  // Get All request courses institute student enroll courses
  const getAllInstituteStudentRequestCourses = async () => {
    try {
      const response = await axios.post(
        `/api/v1/public/user/get-institute-student-request-courses`,
        {
          userId,
          PublicUserId: user?.PublicUserId,
        }
      );
      if (response?.data.success) {
        setStudentEnrollCourses(response?.data.activeCourses || []);
        setRequestCanceled(response?.data.requestCanceled || []); 
        setActiveRequestCanceledCourses(response?.data?.activeCourses || [])
      } else {
        message.error(response?.data.message); // Show error message if not successful
      }
    } catch (error) {
      message.error(error.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllInstituteStudentRequestCourses();
  }, []);

  const handleNotificationItemClick = async (requestId) => {
    setSeenRequests((prev) => new Set(prev).add(requestId));
    try {
      await axios.post(
        "/api/v1/public/user/get-institute-student-request-courses-notifications-mark-seen",
        {
          userId: user._id,
          requestId: requestId,
          PublicUserId: user?.PublicUserId,
        }
      );
    } catch (error) {
      message.error("Failed to mark notification as seen");
    }
    setNotificationEl(null);
  };
  const unseenRequestCount = requestCanceled.filter(
    (request) => !seenRequests.has(request._id) && !request.seen
  ).length;

  useEffect(() => {
    getAllStudentInstitute();
    getAllReports();
    getStudentTopReports();
    getStudentTopSubjectiveReports();
    // getAllInstituteStudentRequestCourses();
    // fetchEnrolledCourses()
    // fetchAllCourses()
  }, []);
  // Function to handle instituteId click Get Batch Student Profile
  const handleInstituteId = async (instituteId) => {
    try {
      const addData = {
        userId: user?._id,
        instituteId,
      };
      const { data } = await axios.post(
        "/api/v1/batch/student-profile",
        addData
      );
      localStorage.setItem("instituteUser", JSON.stringify(data.user));
      if (data.success === true) {
        navigate("/");
        window.location.reload("/");
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Pass the InstituteId in getAllInstituteInfo
  useEffect(() => {
    const fetchInstituteInfo = async () => {
      if (activeRequestCanceledCourses.length > 0) {
        const instituteIds = activeRequestCanceledCourses.map(institute => institute.instituteId);
        await getAllInstituteInfo(instituteIds);
      }
    };
    fetchInstituteInfo();
  }, [activeRequestCanceledCourses]);


  // // Get All InstituteID base fatch the institute Names
  const getAllInstituteInfo = async (instituteIds) => {
    try {
      const response = await axios.post(
        `/api/v1/demo/get-institute-info-by-institute`,
        {
          instituteIds, 
        }
      );
  
      if (response?.data.success) {
        // Create a mapping of instituteId to instituteName
        const instituteMapping = response.data.institutes.reduce((acc, institute) => {
          acc[institute.instituteId] = institute.instituteName; 
          return acc;
        }, {});
        
        // Filter the instituteNames based on the passed instituteIds
        const filteredInstituteNames = instituteIds.map(id => ({
          instituteId: id,
          instituteName: instituteMapping[id] || null 
        }));
  
        setGetInstitute(filteredInstituteNames); 
      } else {
        message.error(response?.data.message || "error1");
      }
    } catch (error) {
      message.error(error.response?.data?.message || "error2");
    }
  };

  // Get Institute name by InstituteId
    const getInstituteNameById = (instituteId) => {
      // Find the institute by its ID
      const institute = getInstitute.find(
        (institute) => institute.instituteId === instituteId
      );
    
      // If the institute is found, return its name
      if (institute) {
        return institute.instituteName;
      }
    
      // If the institute is not found, check in allStudentInstitute
      const instituteNameData = allStudentInstitute.find(
        (institute) => institute.instituteId === instituteId
      );
    
      // Return the name from allStudentInstitute if found, otherwise return "Unknown Institute"
      return instituteNameData ? instituteNameData.instituteName : "Unknown Institute";
    };
    

  // User Logout
  const { confirm } = Modal;
  const handleLogout = () => {
    confirm({
      title: "Are you sure you want to Log out?",
      icon: <ExclamationCircleOutlined />,
      content: "Your current session will be terminated.",
      onOk() {
        dispatch(logout());
        localStorage.clear(); 
        message.success("Logout Successfully");
        window.location.reload(); 
        navigate("/");
      },
    });
  };
  
  useEffect(() => {
    // Filter institutes
    const filtered = allStudentInstitute.filter((institute) =>
      institute?.instituteName
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase())
    );
    setFilteredInstitutes(filtered);

    // Filter courses, excluding courses named "General"
    const filteredCourses = user?.studentCourse?.filter((course) => {
      const match = course?.studentCourseName
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase());

      // Exclude courses with the name "General"
      const isGeneral = course?.studentCourseName?.toLowerCase() === "general";

      return match && !isGeneral;
    });

    setFilteredCourses(filteredCourses);
  }, [searchTerm, allStudentInstitute, user?.studentCourse]);

  // Institute Student Card
  const StudentInstituteCard = ({
    instituteName,
    description,
    instituteLogo,
    instituteId,
  }) => {
    const classes = useStyles();
    return (
      <>
        <Card
          className={classes.card}
          sx={{
            width: { md: 300, sm: "100%", xs: "100%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 160,
              marginTop: 10,
              overflow: "hidden",
            }}
          >
            <img
              src={instituteLogo || TestSeriesLogo}
              alt="Institute Logo"
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
          <CardContent>
            <Typography
              variant="h6"
              sx={{
                letterSpacing: ".1rem",
                fontSize: "16px",
                marginBottom: "12px",
                fontWeight: "bold",
                fontFamily: "Poppins, sans-serif",
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
                color: "#2C3E50",
                alignItems: "center",
              }}
            >
              {instituteName}
            </Typography>
            <Typography
              className="courses_desc"
              variant="body2"
              color="text.secondary"
              sx={{
                mt: 1,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                fontSize: "15px",
                letterSpacing: ".1rem",
                fontFamily: "Poppins, sans-serif",
              }}
              dangerouslySetInnerHTML={{
                __html:description,
              }}
            >
              {/* {description} */}
            </Typography>

            <Button
              variant="outlined"
              color="primary"
              fullWidth
              sx={{
                mt: 2,
                borderRadius: "50px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
              onClick={() => handleInstituteId(instituteId)}
            >
              More Details
            </Button>
          </CardContent>
        </Card>
      </>
    );
  };

  // Institute Course Card
  const CourseCard = ({
    instituteId,
    courseFees,
    studentCourseName,
    studentFees,
    isActive,
  }) => {
    const classes = useStyles();
    return (
      <>
        <Card
          className={classes.card}
          sx={{ width: { md: 300, sm: "100%", xs: "100%" } }}
        >
          <CardContent>
            <Typography
              variant="h6"
              sx={{
                letterSpacing: ".1rem",
                fontSize: "16px",
                marginBottom: "12px",
                fontWeight: "bold",
                fontFamily: "Poppins, sans-serif",
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
                color: "#2C3E50",
                // color: "#1E88E5",
                alignItems: "center",
              }}
            >
              {getInstituteNameById(instituteId)}
            </Typography>
            <Typography
              className="courses_desc"
              variant="body2"
              color="text.secondary"
              sx={{
                mt: 1,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                fontSize: "15px",
                letterSpacing: ".1rem",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Course Name: {studentCourseName}
            </Typography>
            <Typography
              className="courses_desc"
              variant="body2"
              color="text.secondary"
              sx={{
                mt: 1,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                fontSize: "15px",
                letterSpacing: ".1rem",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Course Fees: {courseFees}
            </Typography>
            <Typography
              className="courses_desc"
              variant="body2"
              color="text.secondary"
              sx={{
                mt: 1,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                fontSize: "15px",
                letterSpacing: ".1rem",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Student Course Fees: {studentFees}
            </Typography>
            {courseFees - studentFees > 0 && (
              <Typography
                className="courses_desc"
                variant="body2"
                color="text.secondary"
                sx={{
                  mt: 1,
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  fontSize: "15px",
                  letterSpacing: ".1rem",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Student Pending Fees:{" "}
                <span style={{ color: "black" }}>
                  {courseFees - studentFees}
                </span>
              </Typography>
            )}
            <Typography
              className="courses_desc"
              variant="body2"
              color="text.secondary"
              sx={{
                mt: 1,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                fontSize: "15px",
                letterSpacing: ".1rem",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Course Status :{" "}
              <span style={{ color: isActive ? "green" : "red" }}>
                {isActive ? "Active" : "Inactive"}
              </span>
            </Typography>
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <>
      <PageTitle title="Test Series | Student Dashboard" />
      <Box
        sx={{
          borderRadius: "30px",
          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
          mt: 3,
          mb: 15,
        }}
      >
        {/* Student Dashboard Start */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            backgroundColor: "#f0f4f8",
            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
          }}
        >
          {/* Dashboard Header Left Section: User Profile and Welcome Message */}
          <Box display="flex" alignItems="center">
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
              color={isActive ? "success" : "error"}
              sx={{
                "& .MuiBadge-dot": {
                  height: "12px",
                  width: "12px",
                  borderRadius: "50%",
                  backgroundColor: isActive ? "green" : "red",
                  border: "2px solid white",
                },
              }}
            >
              <Avatar
                sx={{ width: 50, height: 50, cursor: "pointer" }}
                alt={user?.studentName}
                src={user?.profileImage}
                onClick={handleAvatarClick}
              />
            </Badge>
            <Box ml={2}>
              <Typography variant="subtitle2">Welcome</Typography>
              <Typography variant="h6" fontWeight="bold">
                {user?.studentName}
              </Typography>
            </Box>

            {/* Dropdown menu triggered by Avatar */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleClose}>
                <li
                  style={{
                    textDecoration: "none",
                    listStyle: "none",
                  }}
                >
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      marginTop: "12px",
                    }}
                    to="/profile"
                    state={{
                      totalInstitute: allStudentInstitute?.length,
                      totalCourses: filteredCourses?.length,
                      RequestedCourses: activeCourses?.length,
                    }}
                  >
                    My Profile
                  </Link>
                </li>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <li
                  style={{
                    textDecoration: "none",
                    listStyle: "none",
                  }}
                >
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      marginTop: "12px",
                    }}
                    to="#"
                  >
                    Logout
                  </Link>
                </li>
              </MenuItem>
            </Menu>
          </Box>
          {/* Header Middle Section: Search Bar */}
          <Box
            display="flex"
            alignItems="center"
            sx={{
              width: "70%",
              p: "5px 12px",
              ml: { md: 10, xs: 2 },
              mr: { md: 10, xs: 2 },
              display: { xs: "none", sm: "none", md: "flex", large: "flex" },
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search by Institute Name and Courses"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#1976d2" }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                bgcolor: "white",
                borderRadius: "20px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
            />
          </Box>
          {/* Header Right Section: Logout and Notificaion icon */}
          {/* Notifications and Logout */}
          <Box display="flex" alignItems="center">
            {/* Notification Icon */}
            <IconButton onClick={handleNotificationClick}>
              <Badge badgeContent={unseenRequestCount} color="secondary">
                <NotificationsIcon
                  sx={{
                    backgroundColor: "#1976d2",
                    color: "white",
                    fontSize: "35px",
                    padding: "8px",
                    borderRadius: "10px",
                  }}
                />
              </Badge>
            </IconButton>
            {/* Log Out Button */}
            <Box display="flex" alignItems="center">
              <IconButton>
                <LogoutIcon
                  sx={{
                    backgroundColor: "#1976d2",
                    color: "white",
                    fontSize: "35px",
                    padding: "8px",
                    borderRadius: "10px",
                  }}
                  onClick={handleLogout}
                />
              </IconButton>
            </Box>
            {/* Notification Dropdown */}
            <Menu
              anchorEl={notificationEl}
              open={Boolean(notificationEl)}
              onClose={handleNotificationClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {requestCanceled.length > 0 ? (
                <List sx={{ width: "250px" }}>
                  <Typography
                    color="text.secondary"
                    sx={{
                      ml: 2,
                      mr: 2,
                      pb: 2,
                      fontSize: "14px",
                      letterSpacing: ".1rem",
                      fontFamily: "Poppins, sans-serif",
                      wordWrap: "break-word",
                      maxWidth: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Canceled Coursers
                  </Typography>
                  <Divider />
                  {requestCanceled.map((request) => (
                    <div key={request._id}>
                      <MenuItem
                        onClick={() => handleNotificationItemClick(request._id)}
                      >
                        <Link
                          style={{ textDecoration: "none", color: "inherit" }}
                          to="#" // Or redirect to a specific route
                        >
                          <ListItemText
                            primary={request.courseName}
                            secondary={`Start on: ${request.startDate}`}
                            sx={{
                              fontWeight:
                                seenRequests.has(request._id) || request.seen
                                  ? "normal"
                                  : "bold",
                              color:
                                seenRequests.has(request._id) || request.seen
                                  ? "inherit"
                                  : "#55679C",
                            }}
                          />
                        </Link>
                      </MenuItem>
                      <Divider />
                    </div>
                  ))}
                </List>
              ) : (
                <MenuItem onClick={handleNotificationClose}>
                  <ListItemText primary="No new notifications" />
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Box>
        {/* Search Bar / only show in mobile devices */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{
              mt: { xs: 12, sm: 12 },
              ml: 2,
              mr: 2,
              mb: { xs: 5, sm: 5 },
              p: "5px 12px",
              width: "100%",
              display: { xs: "flex", sm: "flex", md: "none", large: "none" },
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search by Institute Name and Courses"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#1976d2" }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                bgcolor: "white",
                borderRadius: "20px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
            />
          </Box>
        </Box>
        {/* Student Dashboard End */}
        {/* Dashboard Course Cards */}
        <Box
          sx={{
            mt: { md: 12, lg: 12 },
            backgroundColor: "#f0f4f8",
            borderRadius: "30px",
            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
          }}
        >
          {/* Section for Your Institute */}
          <Box
            sx={{
              pl: 2,
              pr: 2,
              p: 1,
              pt:5,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                ml: 2,
                mr: 2,
                letterSpacing: ".1rem",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              <b>Your Institute</b>
            </Typography>
            <Button
              variant="outlined"
              onClick={handleShowMore}
              disabled={allStudentInstitute?.length <= itemsToShow}
              sx={{ borderRadius: "20px", ml: 2, mr: 2 }}
            >
              {showMore ? "See Less" : "See All"}
            </Button>
          </Box>
          <Box
            className={classes.root}
            sx={{
              mb: 2,
              mt: 2,
              ml: { md: 3, xs: 2, sm: 2 },
              mr: { md: 3, xs: 2, sm: 2 },
            }}
          >
            {filteredInstitutes?.length === 0 ? (
              <Typography
                variant="h6"
                align="center"
                sx={{
                  width: "100%",
                  color: "#2C3E50",
                  fontFamily: "Poppins, sans-serif",
                  mt: 2,
                  mb: 5,
                }}
              >
                No Institute found. "{searchTerm}"
              </Typography>
            ) : (
              filteredInstitutes
                .slice(0, showMore ? filteredInstitutes?.length : itemsToShow)
                .sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt))
                .map((course, i) => (
                  <StudentInstituteCard key={i} {...course} />
                ))
            )}
          </Box>
          {/* Section for Your Courses */}
          <Box
            sx={{
              pl: 2,
              pr: 2,
              p: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                ml: 2,
                mr: 2,
                letterSpacing: ".1rem",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              <b>Your Courses</b>
            </Typography>
            <Button
              variant="outlined"
              onClick={handleShowMoreCourses}
              disabled={user?.studentCourse?.length <= 5}
              sx={{ borderRadius: "20px", ml: 2, mr: 2 }}
            >
              {showMoreCourses ? "See Less" : "See All"}
            </Button>
          </Box>
          <Box
            className={classes.root}
            sx={{
              mb: 2,
              mt: 2,
              ml: { md: 3, xs: 2, sm: 2 },
              mr: { md: 3, xs: 2, sm: 2 },
            }}
          >
            {/* Displaying filtered courses */}
            {filteredCourses?.length === 0 ? (
              <Typography
                variant="h6"
                align="center"
                sx={{
                  width: "100%",
                  color: "#2C3E50",
                  fontFamily: "Poppins, sans-serif",
                  mt: 2,
                  mb: 5,
                }}
              >
                No Courses found. "{searchTerm}"
              </Typography>
            ) : (
              filteredCourses
                ?.slice(0, showMoreCourses ? filteredCourses?.length : 5)
                ?.sort(
                  (a, b) => new Date(a?.createdAt) - new Date(b?.createdAt)
                )
                ?.map((course, i) => <CourseCard key={i} {...course} />)
            )}
          </Box>
          {/* Active Courses List under Profile Information */}
          <Box sx={{ mt: 3, p: 2 }}>
            <Typography
              variant="h6"
              sx={{
                ml: 2,
                mr: 2,
                letterSpacing: ".1rem",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              <b>Your Requested Courses</b>
            </Typography>
            {/* Button to toggle active courses list */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleShowCourses}
              sx={{ mt: 2, mb: 3, ml: 2, mr: 2 }}
            >
              {showCourses ? "Hide Courses" : "Show Courses"}
            </Button>
            {/* Active Courses List, visible only when showCourses is true */}
            {showCourses && (
              <>
                <Box sx={{ mt: 2, ml: 2, mr: 2 }}>
                  <Typography variant="h6">
                    Your All Requested Courses:
                  </Typography>
                  <List>
                    {activeCourses.length > 0 ? (
                      activeCourses.map((course) => (
                        <React.Fragment key={course._id}>
                          <ListItem>
                            <ListItemText
                              primary={course.courseName}
                              secondary={`Institute: ${getInstituteNameById(
                                course.instituteId
                              )} | Start Date: ${course.startDate} | Fees: ₹${
                                course.courseFees
                              }`}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      ))
                    ) : (
                      <Typography variant="subtitle1">
                        No active courses
                      </Typography>
                    )}
                  </List>
                </Box>

                <Box sx={{ mt: 2, ml: 2, mr: 2 }}>
                  <Typography variant="h6">
                    Your All Canceled Courses:
                  </Typography>
                  <List>
                    {requestCanceled.length > 0 ? (
                      requestCanceled.map((course) => (
                        <React.Fragment key={course._id}>
                          <ListItem>
                            <ListItemText
                              primary={course.courseName}
                              secondary={`Institute: ${getInstituteNameById(
                                course.instituteId
                              )} | Start Date: ${course.startDate} | Fees: ₹${
                                course.courseFees
                              }`}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      ))
                    ) : (
                      <Typography variant="subtitle1">
                        No active courses
                      </Typography>
                    )}
                  </List>
                </Box>
              </>
            )}
          </Box>
        </Box>

        {/* Dashboard Cards ends here */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={6}>
            <Box
              sx={{
                padding: 4,
                backgroundColor: "white",
                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "20px",
                mt: 4,
                // mb:10
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                  padding: 2,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    letterSpacing: ".1rem",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Exam Grade Statistics
                </Typography>
                <Select
                  defaultValue="yearly"
                  sx={{ borderRadius: "20px" }}
                  variant="outlined"
                  size="small"
                >
                  <MenuItem value="yearly">Yearly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
              </Box>
              {loading ? (
                <Typography>Loading...</Typography>
              ) : (
                <Line data={chartData} options={options} />
              )}
            </Box>
          </Grid>
          {/* Acheivement ,Total Courses and etc.. /Right Side Grid */}
          <Grid item xs={12} md={8} lg={6}>
            <Box sx={{ padding: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Card
                    sx={{
                      padding: 3,
                      textAlign: "center",
                      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                      borderRadius: "20px",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      Total Institute
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mt={2}
                    >
                      <SchoolIcon sx={{ fontSize: 60, color: "orange" }} />

                      <Typography variant="h3" sx={{ marginLeft: 2 }}>
                        {allStudentInstitute?.length}
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card
                    sx={{
                      padding: 3,
                      textAlign: "center",
                      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                      borderRadius: "20px",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      Total Courses
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mt={2}
                    >
                      <MenuBookIcon sx={{ fontSize: 60, color: "purple" }} />
                      <Typography variant="h3" sx={{ marginLeft: 2 }}>
                        {filteredCourses?.length}
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
              <Box
                sx={{
                  marginTop: 4,
                  borderRadius: "20px",
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#f0f4f8",
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    bgcolor: "white",
                    borderRadius: "20px",
                    p: 2,
                    mb: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      letterSpacing: ".1rem",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Achievements
                  </Typography>
                  <Typography variant="h6" fontWeight="bold">
                    {topReportData + topSubjectiveReport.length}/
                    {allReportByStudent}
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  {/* Top Reports */}
                  <Grid item xs={12} sm={12} md={12}>
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        mt={1}
                        mb={1}
                      >
                        Top Objective Reports
                      </Typography>
                      <StarIcon
                        sx={{
                          fontSize: 40,
                          color: "bronze",
                          marginLeft: 1,
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 2,
                      }}
                    >
                      {topReport && Object.keys(topReport)?.length > 0 ? (
                        Object.values(topReport).map((item, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              borderRadius: "10px",
                              bgcolor: "white",
                              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                              p: 1,
                              width: "calc(50% - 8px)",
                            }}
                          >
                            <Typography variant="body1">
                              {item.examName}
                            </Typography>
                          </Box>
                        ))
                      ) : (
                        <Typography
                          textAlign="center"
                          width="100%"
                          variant="body2"
                          color="textSecondary"
                        >
                          No reports available
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  {/* Top Subjective Reports */}
                  <Grid item xs={12} sm={12} md={12}>
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        mt={1}
                        mb={1}
                      >
                        Top Subjective Reports
                      </Typography>
                      <StarIcon
                        sx={{
                          fontSize: 40,
                          color: "gold",
                          marginLeft: 1,
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 2,
                      }}
                    >
                      {topSubjectiveReport?.length > 0 ? (
                        topSubjectiveReport?.map((item, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              borderRadius: "10px",
                              bgcolor: "white",
                              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                              p: 1,
                              width: "calc(50% - 8px)",
                            }}
                          >
                            <Typography variant="body1">
                              {item.examName}
                            </Typography>
                          </Box>
                        ))
                      ) : (
                        <Typography
                          variant="body2"
                          textAlign="center"
                          color="textSecondary"
                        >
                          No data available
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ mt: 5 }}>
          <AllInstitutes />
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default StudentDashboard;
