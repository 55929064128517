import React, { useEffect, useState } from "react";
import PageTitle from "../Components/PageTitle/PageTitle";
import {
  Box,
  Paper,
  Typography,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { message, Modal } from "antd";
import axios from "../axiosInstance";

const Contact = () => {
  const [contact, setContact] = useState([]);

  // Get All Exam Data
  const getAllContact = async () => {
    try {
      const response = await axios.get(`/api/v1/contact/admin-get-contact`);
      if (response.data.success === true) {
        setContact(response.data.contactInfo);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getAllContact();
  }, []);

  // Delete Contact 
const { confirm } = Modal;
const deleteContact = async (e, id) => {
  confirm({
    title: "Are you sure you want to delete this Contact?",
    content: "Once deleted, this contact cannot be recovered.",
    okText: "Yes, Delete",
    cancelText: "No, Cancel",
    onOk: async () => {
      try {
        const { data } = await axios.delete("api/v1/contact/admin-delete-contact", {
          data: { id },
        });

        if (data?.success === true) {
          message.success(data?.message);
          getAllContact(); 
        } else {
          message.error(data?.message);
        }
      } catch (error) {
        message.error(error?.response?.data?.message || "Failed to delete contact");
      }
    },
    onCancel() {
      console.log("Contact deletion canceled");
    },
  });
};


  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 10,
      sortable: false,
    },
    {
      field: "Actions",
      width: 120,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteContact(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Full Name",
      width: 180,
      editable: true,
    },
    {
      field: "email",
      headerName: "E-mail",
      width: 250,
      editable: true,
    },
    {
      field: "message",
      headerName: "Message",
      width: 550,
      editable: true,
    },
  ];

  return (
    <div>
      <PageTitle title="Admin Contacts | Test Series" />
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          mb: 3,
          fontFamily: "Poppins, sans-serif",
          color: "#2C3E50",
          letterSpacing: "2.5px",
          lineHeight: 1.8,
        }}
      >
        Contacts
      </Typography>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          mb: 5,
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        <MUILink
          component={Link}
          to="/"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Dashboard
        </MUILink>
        <Typography sx={{ color: "primary.main" }}>Contacts</Typography>
      </Breadcrumbs>
      <Box
        style={{
          width: "auto",
          overflowY: "hidden",
          overflowX: "scroll",
        }}
      >
        <Box
          sx={{
            pb: 2,
            display: "flex",
            width: "auto",
            justifyContent: "right",
          }}
        ></Box>

        <Paper sx={{ bgcolor: "white" }}>
          <Box sx={{ height: 500, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row?._id}
              sx={{
                "& .MuiTablePagination-displayedRows": {
                  color: "black",
                },
                color: "black",
                [`& .${gridClasses?.row}`]: {
                  bgcolor: "white",
                },
                // Style for header cells
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "rgb(25, 118, 210)",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "white",
                  fontWeight: "bold",
                  // fontSize: "13px",
                },
                // Style for checkbox in header
                "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                  color: "#FFF",
                },
                "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white",
                },
              }}
              // rows={contact}
              rows={contact.map((row, index) => ({
                ...row,
                rowNumber: index + 1,
              }))}
              columns={columns}
              pageSize={3}
              rowsPerPageOptions={[3]}
              // checkboxSelection
            />
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default Contact;
