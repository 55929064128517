import React, { useState } from "react";
import { Modal, message } from "antd";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Container, TextField, Button } from "@mui/material";
import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import axios from "../../axiosInstance";

const EditPlan = ({
  refreshData,
  plans,
  name,
  examLimit,
  subjectiveExamLimit,
  PDFNotesLimit,
  courseVideoLimit,
  reportsLimit,
  primaryAccountLimit,
  teacherAccountLimit,
}) => {
  const [plan_Name, setPlan_Name] = useState(name);
  const [plan_McqExam, setPlan_McqExam] = useState(examLimit);
  const [plan_SubjectiveExam, setPlan_SubjectiveExam] =
    useState(subjectiveExamLimit);
  const [plan_PDFNotes, setPlan_PDFNotes] = useState(PDFNotesLimit);
  const [plan_CourseVideo, setPlan_CourseVideo] = useState(courseVideoLimit);
  const [plan_Reports, setPlan_Reports] = useState(reportsLimit);
  const [plan_PrimaryAccount, setPlan_PrimaryAccount] =
    useState(primaryAccountLimit);
  const [plan_TeacherAccount, setPlan_TeacherAccount] =
    useState(teacherAccountLimit);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    // window.location.reload();
  };

  // Edit Plan
  const { confirm } = Modal;
  const handleSubmit = async (e) => {
    e.preventDefault();
    confirm({
      title: "Are you sure you want to edit this Plan?",
      content: "Please confirm the changes before proceeding.",
      okText: "Yes, Edit",
      cancelText: "No, Cancel",
      onOk: async () => {
        const addPlan = {
          id: plans,
          name: plan_Name,
          examLimit: plan_McqExam,
          subjectiveExamLimit: plan_SubjectiveExam,
          PDFNotesLimit: plan_PDFNotes,
          courseVideoLimit: plan_CourseVideo,
          reportsLimit: plan_Reports,
          primaryAccountLimit: plan_PrimaryAccount,
          teacherAccountLimit: plan_TeacherAccount,
        };
 
        try {
          const { data } = await axios.put(
            "/api/v1/plan/admin-update-plan",
            addPlan
          );

          if (data.success === true) {
            message.success(data.message);
            refreshData();
            setIsModalOpen(false);
          } else {
            message.error(data.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to update plan"
          );
        }
      },
      onCancel() {
        console.log("Edit plan operation canceled");
      },
    });
  };

  return (
    <div>
      <>
        <IconButton aria-label="edit" onClick={showModal}>
          <EditIcon sx={{ color: "#1976d2" }} />
        </IconButton>
        <Modal
          title="Edit Plan"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={false}
        >
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="name"
              label="Plan Name *"
              name="name"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Plan Name (Standard, Pro, Elite)"
              value={plan_Name}
              onChange={(e) => setPlan_Name(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="mcqExam"
              label="MCQ Exam"
              name="mcqExam"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="MCQ Exam Limit"
              value={plan_McqExam}
              onChange={(e) => setPlan_McqExam(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="subjectiveExam"
              label="Subjective Exam"
              name="subjectiveExam"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="Subjective Exam Limit"
              value={plan_SubjectiveExam}
              onChange={(e) => setPlan_SubjectiveExam(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="PDFNotes"
              label="PDF Notes"
              name="PDFNotes"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="PDF Notes Limit"
              value={plan_PDFNotes}
              onChange={(e) => setPlan_PDFNotes(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="courseVideo"
              label="Course Video"
              name="courseVideo"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="Course Video Limit"
              value={plan_CourseVideo}
              onChange={(e) => setPlan_CourseVideo(e.target.value)}
            />
            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel shrink htmlFor="reports">
                User Reports
              </InputLabel>
              <Select
                label="User Reports"
                value={plan_Reports}
                onChange={(e) => setPlan_Reports(e.target.value)}
                displayEmpty
                id="reports"
                name="reports"
                placeholder="User Reports Limit"
                sx={{
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
              >
                <MenuItem value="" disabled>
                  Select Report Access
                </MenuItem>
                <MenuItem value="No Reports">No Reports</MenuItem>
                <MenuItem value="View Reports">View Reports</MenuItem>
                <MenuItem value="Download Reports">Download Reports</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="primaryAccount"
              label="Primary Account"
              name="primaryAccount"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="Primary Account Limit"
              value={plan_PrimaryAccount}
              onChange={(e) => setPlan_PrimaryAccount(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="teacherAccount"
              label="Teacher Account"
              name="teacherAccount"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              placeholder="Teacher Accounts Limit"
              value={plan_TeacherAccount}
              onChange={(e) => setPlan_TeacherAccount(e.target.value)}
            />
            <Button variant="contained" type="submit">
              Edit Plan
            </Button>
          </Container>
        </Modal>
      </>
    </div>
  );
};

export default EditPlan;
